import React from 'react';
import { Button } from '../../components/Button';
import { downloadURL } from '../../helpers/misc';

export function getTableConfig() {
  return [
    {
      title: 'Date (UTC)',
      field: 'created_at',
      style: { width: '15rem', flex: 'unset' },
      cell:  (data, row, col) => data,
    },
    {
      title: 'Amount',
      field: 'external_balance',
      style: { maxWidth: '11rem' },
      cell:  (data) => data,
    },
    {
      title: 'Description',
      field: 'description',
      cell:  (data) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    },
    {
      title: 'Status',
      field: 'status',
      cell:  (data) => data,
      style: { width: '20rem', flex: 'unset' },
    },
    {
      title:     'Report',
      noEllipse: true,
      style:     { maxWidth: '24rem' },
      cell:      (data, row, col, all) => (
        <>
          {all.payout_receipt_url && (
            <Button small white label="View" onClick={() => downloadURL(all.incentive_report_url)} />
          )}
          {all.kind === 'external_incentive' && !all.approved && (
            <>
              &nbsp;
              <Button label="Approve" small white onClick={this.approve.bind(this, all.incentive_report_id)} />
              &nbsp;
              <a href={`mailto:support@vanlo.com?subject=Something is wrong with the report from ${all.created_at}`}>
                <Button label="Dispute" small white />
              </a>
            </>
          )}
        </>
      ),
    },
  ];
}

export const getDetailView = (data) => {
  return {
    Id:                       data.id,
    Date:                     data.created_at,
    Description:              data.description,
    'Available value change': data.available_value_change,
    'Balance value change':   data.balance_value_change,
    'Incentive report url':   data.incentive_report_url,
    'Order id':               data.order_id,
    'Pending credit change':  data.pending_credit_change,
    'Pending refund change':  data.pending_refund_change,
    Status:                   data.status,
    'Receipt url':            data.payout_receipt_url ? <a href={data.receipt_url}>{data.payout_receipt_url}</a> : null,
  };
};
