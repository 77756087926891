import React from 'react';
import { camelize, decamelize, pascalize } from 'humps';
import { map, find, escape } from 'lodash';
import { DropdownList, Combobox, Multiselect, NumberPicker } from 'react-widgets';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Title, Button, Select, Grid, Grid1 } from '../../components';
import { IconAdd, IconDelete, IconClose } from '../../helpers/icons';

import {
  RuleList,
  RulePreviewCard,
  RuleExplanation,
  RuleControls,
  RuleEditorForm,
  LogicGroupsWrapper,
  LogicGroupBox,
  LogicUnitRow,
  LogicUnitCard,
} from './styles';

// import test from './styles';
//
// console.log(test)

export default RuleManager;

function RuleManager({ rules, page }) {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 100, tolerance: 50 } }));
  return (
    <RuleList>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={page.reorderRules}>
        <SortableContext items={rules} strategy={verticalListSortingStrategy}>
          {rules.map((rule) =>
            rule.editor ? (
              <RuleEditor {...rule} page={page} key={rule.id} />
            ) : (
              <RulePreview {...rule} page={page} key={rule.id} />
            )
          )}
        </SortableContext>
      </DndContext>
    </RuleList>
  );
}

function RulePreview({ id, name, explanation, page, ...data }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const maybeListeners                                               = find(page.state.rules, 'editor') ? {} : listeners;
  const style                                                        = { transition, transform: CSS.Transform.toString(transform), touchAction: 'none' };
  return (
    <RulePreviewCard ref={setNodeRef} style={style} {...attributes} {...maybeListeners}>
      <div>{name}</div>
      <RuleExplanation dangerouslySetInnerHTML={{ __html: escape(explanation).replace('\n', '<br>') }} />
      <RuleControls>
        <Button small white label="Edit" onClick={(e) => page.editRule(id)} />
        <DropdownList
          data={['manual', 'automatic', 'disabled']}
          value={pascalize(data.execType)}
          renderListItem={({ item }) => pascalize(item)}
          onChange={(value) => page.updateRule({ id, exec_type: value })}
          disabled={!!find(page.state.rules, 'editor')}
        />
      </RuleControls>
    </RulePreviewCard>
  );
}

function RuleEditor({ id, name, conditions, actions, page }) {
  return (
    <RuleEditorForm>
      <Textbox
        placeholder="Name"
        value={name}
        onChange={(e) => page.updateRuleProps({ id, name: e.target.value })}
        style={{ width: '20rem' }}
      />
      <LogicGroupsWrapper>
        <LogicGroup Component={Condition} group="conditions" units={conditions} {...{ id, page }} />
        <LogicGroup Component={Action} group="actions" units={actions} {...{ id, page }} />
      </LogicGroupsWrapper>
      <Button small white label="Save" onClick={() => page.saveRule(id)} />
      <Button small white label="Cancel" onClick={page.resetState} />
    </RuleEditorForm>
  );
}

// SortableContext isn't working here. If we really need this, should try MUI
function LogicGroup({ id, Component, group, units, page }) {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 200, tolerance: 50 } }));
  return (
    <LogicGroupBox group={group} style={units.length ? {} : { width: 'auto' }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(e) => page.reorderLogic(id, group, e)}
      >
        <SortableContext
          items={units.map((unit) => ({ ...unit, id: `${id}:${unit.key}` }))}
          strategy={verticalListSortingStrategy}
        >
          {units.map((unit, n) => (
            <LogicUnit {...{ id, Component, group, n, page, ...unit }} />
          ))}
          <Button white small label={`Add ${pascalize(group.slice(0, -1))}`} onClick={() => page.addLogic(id, group)} />
        </SortableContext>
      </DndContext>
    </LogicGroupBox>
  );
}

function LogicUnit({ id, Component, group, n, page, ...data }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: `${id}:${data.key}` });
  const style                                                        = { transition, transform: CSS.Transform.toString(transform), touchAction: 'none' };
  return (
    <LogicUnitRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {n > 0 && <b>AND</b>}
      {n === 0 && (group === 'conditions' ? <b>WHEN</b> : <b>DO</b>)}
      <Component {...{ id, n, page, ...data }}>
        <IconClose onClick={() => page.deleteLogic(id, group, n)} className="v-icon-button" />
      </Component>
    </LogicUnitRow>
  );
}

function selectOptions([name, id]) {
  return { name, id };
}

function Condition({ id, n, attribute, operator, reference, page, ...data }) {
  const update      = (name, value) => page.updateLogic(id, ['conditions', n, name], value.id || value);
  const dict        = page.props.automations.conditions;
  const properties  = attribute && dict.properties[camelize(attribute)];
  const numberRange = (typeof properties?.options === 'string' && properties.options.split('..')) || [];

  return (
    <LogicUnitCard type="condition">
      <DropdownList
        data={dict.attributes.map(selectOptions)}
        dataKey="id"
        textField="name"
        value={attribute}
        onChange={(value) => update('attribute', value)}
        style={{ width: '18rem' }}
      />
      {attribute && (
        <DropdownList
          data={dict.operators[properties.type]}
          value={operator}
          onChange={(value) => update('operator', value)}
          style={{ width: '11rem' }}
        />
      )}
      {properties?.type === 'enum' && (
        <DropdownList
          data={properties.options}
          value={reference}
          onChange={(value) => update('reference', value)}
          style={{ width: '15rem' }}
        />
      )}
      {properties?.type === 'number' && (
        <NumberPicker
          min={numberRange[0] ? parseInt(numberRange[0], 10) : null}
          max={numberRange[1] ? parseInt(numberRange[1], 10) : null}
          // format={properties.format} // should work but doesn't
          value={reference}
          onChange={(value) => update('reference', value)}
          style={{ width: '15rem' }}
        />
      )}
      {properties?.type === 'string' && (
        <Textbox value={reference} onChange={(e) => update('reference', e.target.value)} style={{ width: '15rem' }} />
      )}
      {data.children}
    </LogicUnitCard>
  );
}

function Action({ id, n, type, target, value, services, page, ...data }) {
  const update = (name, value) =>
    page.updateLogic(id, ['actions', n, name], value.map ? map(value, 'id') : value.id || value);
  const dict   = page.props.automations.actions;
  return (
    <LogicUnitCard type="action" actionType={type}>
      <DropdownList
        data={dict.types.map(selectOptions)}
        dataKey="id"
        textField="name"
        value={type}
        onChange={(value) => update('type', value)}
        style={{ width: '15rem' }}
      />
      {(type === 'set_value' || type === 'set_address') && (
        <Combobox
          data={dict[`${camelize(type)}Targets`].map(selectOptions)}
          dataKey="id"
          textField="name"
          filter="contains"
          value={target}
          onChange={(value) => update('target', value)}
          style={{ width: type === 'set_value' ? '25rem' : '10rem' }}
        />
      )}
      {(type === 'set_value' || type === 'add_weight') && (
        <Textbox value={value} onChange={(e) => update('value', e.target.value)} style={{ width: '25rem' }} />
      )}
      {type === 'set_address' && (
        <Combobox
          data={dict.setAddressValues.map(selectOptions)}
          dataKey="id"
          textField="name"
          filter="contains"
          value={value}
          onChange={(value) => update('value', value)}
          style={{ width: '40rem' }}
        />
      )}
      {type === 'buy_label' && (
        <Multiselect
          data={dict.buyLabelServices.map(selectOptions)}
          dataKey="id"
          textField="name"
          filter="contains"
          value={services}
          onChange={(values) => update('services', values)}
          style={{ width: '50rem' }}
        />
      )}
      {data.children}
    </LogicUnitCard>
  );
}

// React-widgets doesn't have a public basic input. This is how it would look like.
// This way it matches all the other inputs' look.
function Textbox(params) {
  return (
    <div className="rw-widget rw-widget-textbox" style={params.style}>
      <div className="rw-widget-picker" style={{ gridTemplate: 'none' }}>
        <input type="text" autoComplete="off" className="rw-widget-input rw-input" {...params} />
      </div>
    </div>
  );
}
