import React from 'react';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Button';
import { IconDelete } from '../../helpers/icons';
import { showModal } from '../../helpers/modal';
import DeleteUserModal from './DeleteUserModal';
import { PersonsResource } from '../../resources';
import { store } from '../../store';

const toggleState = (id) => () => {
  PersonsResource.toggleState(id).then((response) => {
    store.dispatch({
      type:    'SET_PERSON',
      payload: { response, id },
    });
  });
};

function renderToggleButton(id, enabled) {
  if (enabled) {
    return (
      <Button className="v-person-toggle" white small onClick={toggleState(id)}>
        Disable
      </Button>
    );
  }

  return (
    <Button small className="v-person-toggle" onClick={toggleState(id)}>
      Enable
    </Button>
  );
}

const showDeleteUserModal = (user) => () => {
  showModal({
    title:     'Delete User',
    component: DeleteUserModal,
    payload:   { user },
  });
};

export default function tableConfig() {
  return [
    {
      title:     'User',
      noEllipse: true,
      cell:      (value, row, col, data) => data.name,
    },
    {
      title:     'Billing Settings',
      className: 'v-permission-checkbox',
      style:     { width: '10rem', flex: 'unset' },
      noEllipse: true,
      cell:      (value, row, col, data) => (
        <Checkbox
          onChange={this.togglePermission.bind(this, data.id, 'billingSettings')}
          checked={this.permissionFor(data.id, 'billingSettings')}
          disabled={!data.enabled}
        />
      ),
    },
    {
      title:     'Billing Reporting',
      className: 'v-permission-checkbox',
      style:     { width: '10rem', flex: 'unset' },
      noEllipse: true,
      cell:      (value, row, col, data) => (
        <Checkbox
          onChange={this.togglePermission.bind(this, data.id, 'billingReporting')}
          checked={this.permissionFor(data.id, 'billingReporting')}
          disabled={!data.enabled}
        />
      ),
    },
    {
      title:     'Shipment Reporting',
      className: 'v-permission-checkbox',
      style:     { width: '10rem', flex: 'unset' },
      noEllipse: true,
      cell:      (value, row, col, data) => (
        <Checkbox
          onChange={this.togglePermission.bind(this, data.id, 'shipmentReporting')}
          checked={this.permissionFor(data.id, 'shipmentReporting')}
          disabled={!data.enabled}
        />
      ),
    },
    {
      title:     'API Keys',
      className: 'v-permission-checkbox',
      style:     { width: '10rem', flex: 'unset' },
      noEllipse: true,
      cell:      (value, row, col, data) => (
        <Checkbox
          onChange={this.togglePermission.bind(this, data.id, 'apiKeys')}
          checked={this.permissionFor(data.id, 'apiKeys')}
          disabled={!data.enabled}
        />
      ),
    },
    {
      title:     'Add Locations',
      className: 'v-permission-checkbox',
      style:     { width: '10rem', flex: 'unset' },
      noEllipse: true,
      cell:      (value, row, col, data) => (
        <Checkbox
          onChange={this.togglePermission.bind(this, data.id, 'addLocations')}
          checked={this.permissionFor(data.id, 'addLocations')}
          disabled={!data.enabled}
        />
      ),
    },
    {
      title:     'Actions',
      style:     { width: '15rem', flex: 'unset' },
      noEllipse: true,
      cell:      (value, row, col, data) => (
        <>
          {renderToggleButton(data.id, data.enabled)}
          <Button className="v-delete-person" white small icon={IconDelete} onClick={showDeleteUserModal(data)} />
        </>
      ),
    },
  ];
}
