import React from 'react';
import { formatCurrency } from '../../helpers/data';
import { Button } from '../../components/Button';
import { downloadURL } from '../../helpers/misc';

export const getDetailView = (data) => {
  return {
    Id:               data.id,
    Date:             data.created_at,
    Description:      data.description,
    'Tracking Code:': data.tracking_code ? (
      <span className="v-center-left">
        {data.tracking_code} &nbsp;{' '}
        <Button small onClick={() => downloadURL(data.tracking_url)}>
          Open Tracking URL
        </Button>{' '}
      </span>
    ) : (
      ''
    ),
    Amount:                 <div>{formatCurrency(data.amount, false, true)}</div>,
    'Authorized Amount':    <div>{formatCurrency(data.authorized_amount, false, true)}</div>,
    'Pending Amount':       <div>{formatCurrency(data.pending_amount, false, true)}</div>,
    // 'Available value change': <div>{formatCurrency(data.available_value_change, false, true)}</div>,
    Balance:                <div>{formatCurrency(data.available_value_change, false, true)}</div>,
    // 'Order id': data.order_id,
    // 'Pending credit change': <div>{formatCurrency(data.pending_credit_change, false, true)}</div>,
    'Pending Refund':       <div>{formatCurrency(data.pending_refund_change, false, true)}</div>,
    'Receipt URL':          data.receipt_url,
    'Incentive Report URL': data.incentive_report_url,
  };
};

const DisputeButton = (props) => {
  if (props.transaction.kind !== 'adjustment') {
    return null;
  }

  if (props.transaction.adjustment_disputed === true) {
    return (
      <Button small disabled>
        Disputed
      </Button>
    );
  }

  return (
    <Button small onClick={props.context.showDisputeModal(props.transaction)}>
      Dispute
    </Button>
  );
};

function ViewButton(props) {
  if (!props.receiptUrl) {
    return null;
  }

  return (
    <Button small onClick={() => downloadURL(props.receiptUrl)}>
      View
    </Button>
  );
}

export default function () {
  return [
    {
      title: 'Date (UTC)',
      field: 'created_at',
      style: { width: '14rem', flex: 'unset' },
      cell:  (data) => data,
    },
    {
      title: 'Amount',
      field: 'amount',
      cell:  (data) => (data === '0.00' ? '-' : data),
      style: { width: '10rem', flex: 'unset' },
    },
    {
      title: 'Auth Amt',
      field: 'authorized_amount',
      cell:  (data) => (data === '0.00' ? '-' : data),
      style: { width: '10rem', flex: 'unset' },
    },
    {
      title: 'Pending Amt',
      field: 'pending_amount',
      cell:  (data) => (data === '0.00' ? '-' : data),
      style: { width: '11rem', flex: 'unset' },
    },
    {
      title: 'Pending Refund',
      field: 'refund_amount',
      cell:  (data) => (data === '0.00' ? '-' : data),
      style: { width: '13rem', flex: 'unset' },
    },
    {
      title: 'Description',
      field: 'description',
      cell:  (data, row, col, all) => data,
      style: { fontSize: '1.2rem' },
    },
    {
      title:     'Actions',
      noEllipse: true,
      cell:      (data, row, col, all) => {
        const url = all.receipt_url || all.incentive_report_url || all.report_file_url;
        return (
          <div>
            <DisputeButton context={this} transaction={all} />
            {url && (
              <a href={url} target="_blank" rel="noreferrer">
                <Button small white>
                  View
                </Button>
              </a>
            )}
          </div>
        );
      },
    },
  ];
}
