import React from 'react';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { startCase, pickBy, isEqual } from 'lodash';
import { closeModal, showErrorMessage } from '../../helpers/modal';

import { ShipmentResource } from '../../resources';

import { Card } from '../../components/Card';
import { Grid, Grid1, Grid2 } from '../../components/Grids';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Button';
import { Textbox } from '../../components/Textbox';
import { Select } from '../../components/Select';

class ShipmentForm extends React.Component {
  // When the parent first adds to the DOM or restores this component
  // state must be assigned
  constructor(props) {
    super(props);
    this.defaultFields = {
      fromAddress:   { country: 'US' },
      returnAddress: {},
      toAddress:     { country: 'US' },
      parcel:        { predefinedPackage: 'Parcel', items: [] },
      customsInfo:   { customsItems: [] },
      options:       { labelFormat: props.settings.label_format },
    };
    this.state         = this.freshState();
  }

  // When the parent rerenders touching this component (but not re-add it)
  // setState must be called
  componentDidUpdate(prevProps, prevState) {
    isEqual(prevProps?.shipment, this.props?.shipment) || this.refreshState();
  }

  freshState() {
    return { ...this.defaultFields, ...pickBy(this.props.shipment, (v, k) => v) };
  }

  refreshState() {
    this.setState(this.freshState());
  }

  handleChange = (nameRoot, name) => (event) => {
    const { target } = event;

    if (Array.isArray(name)) {
      const root      = name[0];
      const index     = name[1];
      const attribute = name[2];

      return this.setState((prevState) => ({
        ...prevState,
        [nameRoot]: {
          ...prevState[nameRoot],
          [root]: [
            ...prevState[nameRoot][root].slice(0, index),
            {
              ...prevState[nameRoot][root][index],
              [attribute]: target.value,
            },
            ...prevState[nameRoot][root].slice(index + 1),
          ],
        },
      }));
    }

    this.setState((prevState) => ({
      ...prevState,
      [nameRoot]: {
        ...prevState[nameRoot],
        [name]: target.value,
      },
    }));
  };

  create = () => {
    const { page, order } = this.props;
    const data            = { shipment: { ...decamelizeKeys(this.state), shop_order_id: order.id } };

    ShipmentResource.create(data, 'Getting rates...', null, false).then((r) => {
      if (r.message) {
        if (typeof r.message === 'object') {
          if (Array.isArray(r.message)) {
            showErrorMessage(r.message.map((el) => el.message).join(','));
          } else {
            showErrorMessage(Object.values(r.message).join(', '));
          }
        } else {
          showErrorMessage(r.message);
        }
      } else {
        page.update(r);
      }
    });
  };

  addCustomsItem = () => {
    this.setState((prevState) => ({
      customsInfo: {
        ...prevState.customsInfo,
        customsItems: [...prevState.customsInfo.customsItems, {}],
      },
    }));
  };

  removeCustomsItem = (id) => () => {
    this.setState((prevState) => ({
      customsInfo: {
        ...prevState.customsInfo,
        customsItems: prevState.customsInfo.customsItems.filter((item, index) => index !== id),
      },
    }));
  };

  // this should be refactored anyway:

  addItem = () => {
    this.setState((prevState) => ({
      parcel: {
        ...prevState.parcel,
        items: [...prevState.parcel.items, {}],
      },
    }));
  };

  removeItem = (id) => () => {
    this.setState((prevState) => ({
      parcel: {
        ...prevState.parcel,
        items: prevState.parcel.items.filter((item, index) => index !== id),
      },
    }));
  };

  toggleReturnAddressFields = () => {
    const { editReturnAddress } = this.state;

    this.setState({ editReturnAddress: !editReturnAddress });
  };

  toggleCustomsCertify = () => {
    const {
      customsInfo: { customsCertify },
    } = this.state;

    this.setState((prevState) => ({
      ...prevState,
      customsInfo: {
        ...prevState.customsInfo,
        customsCertify: !customsCertify,
      },
    }));
  };

  renderTextbox(nameRoot, name, tabindex = 0) {
    let value, root, index, attribute;
    if (Array.isArray(name)) {
      [root, index, attribute] = name;
      value                    = this.state[nameRoot][root][index][attribute];
    } else {
      value = this.state[nameRoot][name];
    }

    return (
      <Textbox
        name={name}
        value={value}
        onChange={this.handleChange(nameRoot, name)}
        className="v-text"
        label={startCase(attribute || name)}
        placeholder={startCase(name)}
        tabindex={tabindex}
      />
    );
  }

  renderReturnAddressSection() {
    let returnAddressFields;
    const { editReturnAddress } = this.state;

    if (editReturnAddress) {
      returnAddressFields = (
        <Grid2>
          <Grid1>
            <Grid1 className="v-grid-column v-no-grid">
              {this.renderTextbox('returnAddress', 'name')}
              {this.renderTextbox('returnAddress', 'phone')}
              {this.renderTextbox('returnAddress', 'street1')}

              <Grid>
                <Grid1 className="v-padding-right-3">{this.renderTextbox('returnAddress', 'city')}</Grid1>
                <Grid1>{this.renderTextbox('returnAddress', 'country')}</Grid1>
              </Grid>
            </Grid1>

            <Grid1 className="v-grid-column v-padding-left-3 v-padding-right-3">
              {this.renderTextbox('returnAddress', 'company')}
              {this.renderTextbox('returnAddress', 'email')}
              {this.renderTextbox('returnAddress', 'street2')}

              <Grid>
                <Grid1 className="v-padding-right-3">{this.renderTextbox('returnAddress', 'state')}</Grid1>

                <Grid1>{this.renderTextbox('returnAddress', 'zip')}</Grid1>
              </Grid>
            </Grid1>
          </Grid1>
          <Grid1 />
        </Grid2>
      );
    }

    return (
      <>
        <Grid>
          <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
            <div className="v-headline-label-text v-margin-top-2 v-margin-bottom-2">Return Address</div>
          </Grid1>
        </Grid>

        <Checkbox
          checked={!editReturnAddress}
          onChange={this.toggleReturnAddressFields}
          label="Return address is the same as from address"
        />

        {returnAddressFields}
      </>
    );
  }

  renderCustomsInfoSection() {
    const {
      fromAddress: { country: fromCountry },
      toAddress: { country: toCountry, state: toState, city: toCity },
      customsInfo,
      parcel: { items },
    } = this.state;
    const customsItems      = customsInfo.customsItems || [];
    const customsCertify    = customsInfo.customsCertify || false;
    const contentsType      = customsInfo.contentsType || 'merchandise';
    const restrictionType   = customsInfo.restrictionType || 'none';
    const nonDeliveryOption = customsInfo.nonDeliveryOption || 'return';
    const eelPfc            = customsInfo.eelPfc || 'NOEEI 30.37(a)';

    const contentsTypes = ['documents', 'gift', 'merchandise', 'returned_goods', 'sample', 'other'].map((type) => ({
      label: startCase(type),
      value: type,
    }));

    const restrictionTypes = ['none', 'quarantine', 'sanitary_phytosanitary_inspection', 'other'].map((type) => ({
      label: startCase(type),
      value: type,
    }));

    const nonDeliveryOptions = ['return', 'abandon'].map((type) => ({
      label: startCase(type),
      value: type,
    }));

    const militaryCities = ['APO', 'FPO', 'DPO'];
    const militaryStates = ['AA', 'AE', 'AP'];

    const isMilitary      = militaryCities.includes(toCity) || militaryStates.includes(toState);
    const isInternational = toCountry && fromCountry && toCountry !== fromCountry;

    if (!isInternational && !isMilitary) return <></>;

    // If it's order-related, the items are managed in a separate section
    const customsItemsList = !this.props.order && customsItems.map((item, index) => (
      <div key={index}>
        {this.renderTextbox('customsInfo', ['customsItems', index, 'description'])}
        <Grid1>
          {this.renderTextbox('customsInfo', ['customsItems', index, 'quantity'])}
          {this.renderTextbox('customsInfo', ['customsItems', index, 'value'])}
          {this.renderTextbox('customsInfo', ['customsItems', index, 'weight'])}
        </Grid1>
        {this.renderTextbox('customsInfo', ['customsItems', index, 'hsTariffNumber'])}
        {this.renderTextbox('customsInfo', ['customsItems', index, 'code'])}
        {this.renderTextbox('customsInfo', ['customsItems', index, 'originCountry'])}

        <Button onClick={this.removeCustomsItem(index)} className="v-margin-bottom-2 v-button-white v-button-small">
          Remove this item
        </Button>
      </div>
    ));

    return (
      <Card className="v-padding-2">
        <Grid>
          <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
            <div className="v-headline-label-text v-margin-bottom-2">Customs Info</div>
          </Grid1>
        </Grid>
        <Grid2>
          <Grid1 className="v-grid-column v-no-grid">
            <Select
              label="Contents Type"
              value={contentsType}
              className="v-select-small-no-border  v-margin-bottom-3"
              items={contentsTypes}
              onChange={this.handleChange('customsInfo', 'contentsType')}
            />

            <Select
              label="Restriction"
              value={restrictionType}
              className="v-select-small-no-border v-full-width v-margin-bottom-3"
              items={restrictionTypes}
              onChange={this.handleChange('customsInfo', 'restrictionType')}
            />

            <Select
              label="Non-Delivery Action"
              value={nonDeliveryOption}
              className="v-select-small-no-border v-full-width"
              items={nonDeliveryOptions}
              onChange={this.handleChange('customsInfo', 'nonDeliveryOption')}
            />
          </Grid1>

          <Grid1 className="v-grid-column v-no-grid v-padding-left-2">
            {this.renderTextbox('customsInfo', 'contentsExplanation')}
            {this.renderTextbox('customsInfo', 'restrictionComments')}
            {this.renderTextbox('customsInfo', 'eelPfc')}
          </Grid1>
        </Grid2>
        {this.renderTextbox('customsInfo', 'customsSigner')}
        <Checkbox
          onChange={this.toggleCustomsCertify}
          checked={customsCertify}
          label="I certify that the information provided is accurate."
        />
        {customsItemsList && (
          <>
            <Grid>
              <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
                <div className="v-headline-label-text v-margin-bottom-2">Customs Items</div>
                {customsItemsList}
              </Grid1>
            </Grid>
            <Button className="v-margin-bottom-1" onClick={this.addCustomsItem}>
              Add Item
            </Button>
          </>
        )}
      </Card>
    );
  }

  render() {
    const {
      parcel: { predefinedPackage, items },
      toAddress: { country: toCountry },
      options: {
        labelFormat,
        deliveryConfirmation,
        // hazmat,
        // specialRatesEligibility,
        // endorsement,
      },
      id,
    } = this.state;
    const { order } = this.props;

    const deliveryConfirmations = [
      'NO_SIGNATURE',
      'SIGNATURE',
      'ADULT_SIGNATURE',
      'SIGNATURE_RESTRICTED',
      'ADULT_SIGNATURE_RESTRICTED',
    ].map((type) => ({
      label: startCase(type),
      value: type,
    }));

    /* const endorsements = [
      'ADDRESS_SERVICE_REQUESTED',
      'FORWARDING_SERVICE_REQUESTED',
      'CHANGE_SERVICE_REQUESTED',
      'RETURN_SERVICE_REQUESTED',
      'LEAVE_IF_NO_RESPONSE',
    ].map((type) => ({ label: startCase(type), value: type })); */

    const predefinedPackages = [
      'Parcel',
      'Flat',
      'Letter',
      'Card',
      'FlatRateEnvelope',
      'FlatRateLegalEnvelope',
      'FlatRatePaddedEnvelope',
      'SmallFlatRateBox',
      'MediumFlatRateBox',
      'LargeFlatRateBox',
      'LargeFlatRateBoxAPOFPO',
      'RegionalRateBoxA',
      'RegionalRateBoxB',
      'FedExEnvelope',
      'FedExBox',
      'FedExPak',
      'FedExTube',
      'FedEx10kgBox',
      'FedEx25kgBox',
      'FedExSmallBox',
      'FedExMediumBox',
      'FedExLargeBox',
      'FedExExtraLargeBox',
    ].map((type) => ({ label: startCase(type), value: type }));

    // This was intended to specify items for printing on the packing slip,
    // and, more important (as I realized later), what items the user is sending in an optional, additional shipment,
    // but we decided to not go too deep in the details right now.
    //
    // TODO
    // If we want to use this functionality to split items between parcels from Vanlo side:
    // 1. Store (read-only) line item ids along with other parcel.items data
    // 2. Bind the parcel.items to shipment creation in Shopify and BigCommerce
    // 3. Add a notice that newly created items won't show in the shop UI as they are not associated with the line items
    // If we also want to list the parcel.items instead of shop_order.items in the slip:
    // 4. Use StateManagementMixin and refactor the controls so we could expose deeper nested fields like taxes
    const itemsList = null;
    // Unless it's order-related, the items are managed only for intl shipments...
    // const itemsList = order && (items || []).map((item, index) => (
    //   <div key={index}>
    //     {this.renderTextbox('parcel', ['items', index, 'description'])}
    //     <Grid1>
    //       {this.renderTextbox('parcel', ['items', index, 'quantity'])}
    //       {this.renderTextbox('parcel', ['items', index, 'unitPrice'])}
    //       {this.renderTextbox('parcel', ['items', index, 'unitWeight'])}
    //     </Grid1>
    //     {toCountry !== 'US' && (
    //       <Grid1>
    //         {this.renderTextbox('parcel', ['items', index, 'originCountry'])}
    //         {this.renderTextbox('parcel', ['items', index, 'code'])}
    //         {this.renderTextbox('parcel', ['items', index, 'hsTariffNumber'])}
    //       </Grid1>
    //     )}
    //
    //     <Button onClick={this.removeItem(index)} className="v-margin-bottom-2 v-button-white v-button-small">
    //       Remove this item
    //     </Button>
    //   </div>
    // ));

    return (
      <div>
        <Card className="v-padding-2">
          <Grid>
            <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
              <div className="v-headline-label-text v-margin-bottom-2">From Address</div>
            </Grid1>
            <Grid1 className="v-grid-column v-padding-left-3 v-no-grid">
              <div className="v-headline-label-text v-margin-bottom-2">To Address</div>
            </Grid1>
          </Grid>
          <Grid2>
            <Grid1 className="v-grid-column v-no-grid">
              {this.renderTextbox('fromAddress', 'name', 1)}
              {this.renderTextbox('fromAddress', 'street1', 3)}
              {this.renderTextbox('fromAddress', 'city', 5)}
              <Grid>
                <Grid1 className="v-padding-right-3">{this.renderTextbox('fromAddress', 'state', 7)}</Grid1>

                <Grid1>{this.renderTextbox('fromAddress', 'country', 8)}</Grid1>
              </Grid>
            </Grid1>

            <Grid1 className="v-grid-column v-padding-left-3 v-padding-right-3">
              {this.renderTextbox('fromAddress', 'company', 2)}
              {this.renderTextbox('fromAddress', 'street2', 4)}
              {this.renderTextbox('fromAddress', 'zip', 6)}
              {this.renderTextbox('fromAddress', 'phone', 9)}
            </Grid1>

            <Grid1 className="v-grid-column v-padding-left-3 v-no-grid">
              {this.renderTextbox('toAddress', 'name', 21)}
              {this.renderTextbox('toAddress', 'street1', 23)}
              {this.renderTextbox('toAddress', 'city', 25)}
              <Grid>
                <Grid1 className="v-padding-right-3">{this.renderTextbox('toAddress', 'state', 27)}</Grid1>

                <Grid1>{this.renderTextbox('toAddress', 'country', 28)}</Grid1>
              </Grid>
            </Grid1>

            <Grid1 className="v-grid-column v-padding-left-3">
              {this.renderTextbox('toAddress', 'company', 22)}
              {this.renderTextbox('toAddress', 'street2', 24)}
              {this.renderTextbox('toAddress', 'zip', 26)}
              {this.renderTextbox('toAddress', 'phone', 29)}
            </Grid1>
          </Grid2>

          {this.renderReturnAddressSection()}
        </Card>

        <Grid2>
          <Grid1 className="v-grid-column">
            <Card className="v-padding-2">
              <Grid>
                <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
                  <div className="v-headline-label-text v-margin-bottom-2">Parcel (inches & ounces)</div>
                </Grid1>
              </Grid>
              <Grid2>
                <Grid1 className="v-grid-column v-no-grid">
                  <Grid1>
                    {this.renderTextbox('parcel', 'length', 41)}
                    {this.renderTextbox('parcel', 'width', 42)}
                    {this.renderTextbox('parcel', 'height', 43)}
                    {this.renderTextbox('parcel', 'weight', 44)}
                  </Grid1>

                  <Select
                    label="Package"
                    value={predefinedPackage}
                    className="v-select-small-no-border v-margin-bottom-3"
                    items={predefinedPackages}
                    onChange={this.handleChange('parcel', 'predefinedPackage')}
                  />
                </Grid1>
              </Grid2>
            </Card>

            <Card className="v-padding-2">
              <Grid>
                <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
                  <div className="v-headline-label-text v-margin-bottom-2">Options</div>
                </Grid1>
              </Grid>

              <Grid1 className="v-grid-column v-no-grid">
                {/* this.renderTextbox('shipment', 'reference') */}

                {/* this.renderTextbox('shipment', 'labelDate') */}
                {this.renderTextbox('options', 'printCustom1')}
                {this.renderTextbox('options', 'printCustom2')}
                {/* this.renderTextbox('options', 'printCustom3') */}

                <Select
                  label="Label Format"
                  value={labelFormat}
                  className="v-select-small-no-border v-margin-bottom-3"
                  items={[
                    { label: 'PDF', value: 'pdf' },
                    { label: 'PNG', value: 'png' },
                    { label: 'ZPL', value: 'zpl' },
                  ]}
                  onChange={this.handleChange('options', 'labelFormat')}
                />

                <Select
                  label="Delivery Confirmation"
                  value={deliveryConfirmation}
                  className="v-select-small-no-border v-margin-bottom-3 v-margin-top-2"
                  items={deliveryConfirmations}
                  onChange={this.handleChange('options', 'deliveryConfirmation')}
                />
              </Grid1>
            </Card>
            {itemsList && this.renderCustomsInfoSection()}
            <Button onClick={this.create}>{id ? 'Update' : 'Create'} and get rates</Button>
          </Grid1>

          <Grid1 className="v-padding-left-3">
            <div>
              {!itemsList && this.renderCustomsInfoSection()}
              {itemsList && (
                <Card className="v-padding-2">
                  <Grid>
                    <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
                      <div className="v-headline-label-text v-margin-bottom-2">Order Items To Ship</div>
                    </Grid1>
                  </Grid>
                  <Grid1 className="v-grid-column v-no-grid">
                    {itemsList}
                    <Button className="v-margin-bottom-1" onClick={this.addItem}>
                      Add Item
                    </Button>
                  </Grid1>
                </Card>
              )}
            </div>
          </Grid1>
        </Grid2>
      </div>
    );
  }
}

export default ShipmentForm;
