import React from 'react';
import { camelizeKeys } from 'humps';
import { minBy, flatten, map, each, isEqual, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { DropdownList, Combobox } from 'react-widgets';
import styled from 'styled-components/macro';
import moment from 'moment';

import { Card, Title, Table, Button, Pagination, TableFiltersComponent, Grid, Grid1, Grid2 } from '../../components';
import { ShipmentResource, ShopOrdersResource } from '../../resources';
import { showMessage, showErrorMessage, showModal, showTextMessage } from '../../helpers/modal';
import { tableConfig } from './tableConfig';
import { OrderModal } from './OrderModal';
import { _Shipments } from '../Shipments';

export const ThinDropdownList = styled(DropdownList)`
  width: 15rem;
  display: inline-flex;
  font-size: 90%;
  .rw-widget-picker {
    min-height: 2.5rem;
  }
`;
const ConnectionInfo = styled(Card)`
  box-shadow: 0 0 0 1px #e2e2e2;
  font-size: 90%;
  opacity: 70%;
  margin-bottom: 0;
  text-align: left;
  width: auto;
  .v-card-body {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 4rem;
    padding: 1rem;
    /* & > div {
      min-width: 25rem;
    } */
  }
`;

// TODO: inherit from BuyLabels,
// it looks like a half of the class only differs with the ShopOrdersResource reference
class ShopOrders extends _Shipments {
  propsWillUpdate = ['shopOrders']

  resource = ShopOrdersResource

  state = {
    selectedShipmentIds:  [],
    purchasedShipmentIds: [],
    selectedRates:        {},
    ...this.resource.filters,
    ...this.props.shopOrders,
  };

  componentDidUpdate(prevProps, prevState) {
    const reducer = this.props.shopOrders;
    if (reducer?.shipments && !isEqual(prevProps?.shopOrders, reducer)) {
      this.resetState();
    }
  }

  resetState = () => {
    const selectedShipmentIds = this.state.selectedShipmentIds.filter((id) =>
      this.props.shopOrders.shipments.find((s) => s.id === id && s.editable)
    );
    this.setState({ selectedShipmentIds, ...this.props.shopOrders });
  };

  onCheck = (shipmentId) => () => {
    const { selectedShipmentIds } = this.state;

    if (selectedShipmentIds.includes(shipmentId)) {
      selectedShipmentIds.splice(selectedShipmentIds.indexOf(shipmentId), 1);
    } else {
      selectedShipmentIds.push(shipmentId);
    }
    this.setState({ selectedShipmentIds: [...selectedShipmentIds] });
  };

  onCheckAll = () => {
    if (this.state.selectedShipmentIds.length) {
      this.setState({ selectedShipmentIds: [] });
    } else {
      this.setState({
        selectedShipmentIds: this.state.shipments
          .map((s) => s.id)
          .filter((id) => this.state.purchasedShipmentIds.indexOf(id) === -1),
      });
    }
  };

  changeRate = (shipmentId) => (event) => {
    this.setState({
      selectedRates: {
        ...this.state.selectedRates,
        [shipmentId]: event.target.value,
      },
    });
  };

  buyLabel = (shipment) => () => {
    this.setState({ loadingShipmentId: shipment.id });

    ShipmentResource.buy(shipment.public_id, this.selectedRatedId(shipment)).then((response) => {
      this.setState({ loadingShipmentId: null });
      if (response.tracking_code) {
        this.setState({
          purchasedShipmentIds: [...this.state.purchasedShipmentIds, shipment.id],
          selectedShipmentIds:  [this.state.selectedShipmentIds.filter((s) => s !== shipment.id)],
        });
      } else {
        showErrorMessage(response.message || 'Something went wrong');
      }
    });
  };

  buyLabels = () => {
    const ids = this.state.selectedShipmentIds;
    if (!window.confirm(`Are you sure you want to buy ${ids.length} label(s)?`)) return;

    ids.forEach((id) => {
      const shipment = this.props.shopOrders.shipments.find((shipment) => {
        return shipment.id === id;
      });

      this.buyLabel(shipment)();
    });
  };

  applyRule = async (rule) => {
    const ids = this.state.selectedShipmentIds;
    if (!window.confirm(`Are you sure you want to apply ${rule.name} to the ${ids.length} shipment(s)?`)) return;

    const { success, report, message } = await ShipmentResource.execute(ids, rule);
    if (report) {
      this.resource.list(false);
      showMessage(
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {map(report.applied_actions, (desc, ref) => (
            <div style={{ display: 'inline-flex', gap: '1rem' }} key={ref}>
              <div>{ref}</div>
              <div>{desc}</div>
            </div>
          ))}
        </div>,
        null,
        true
      );
    }
    message && showErrorMessage(message);
  };

  sync = () => {
    this.resource.post(`sync`).then(({ message }) => showTextMessage(message));
  }

  shipmentRowClass = (shipment) => {
    if (shipment.id === this.state.loadingShipmentId) {
      return 'shipment-loading';
    }

    if (this.isPurchased(shipment)) {
      return 'shipment-purchased';
    }

    if (!shipment.label_status) return 'shipment-with-rates';
  };

  selectedRatedId = (shipment) => {
    return (
      this.state.selectedRates[shipment.id] ||
      (shipment.service
        ? shipment.rates.find((rate) => rate.service === shipment.service)
        : minBy(shipment.rates, (rate) => Number(rate.rate))
      )?.public_id
    );
  };

  showOrder = ({ order, ...shipment }) => {
    showModal({
      title:     `Order ${order.name} — ${order.date}`,
      component: OrderModal,
      payload:   { order: { ...order, shipments: [shipment] }, page: this },
    });
  };

  editShipment = (shipment) => () => {
    this.props.history.push(`/shipment/${shipment.id || 'new'}`, { back: 'ShopOrders', order: shipment.order });
  };

  isPurchased = (shipment) => {
    return this.state.purchasedShipmentIds.indexOf(shipment.id) !== -1;
  };

  render() {
    const { page, per, shipments, connections, total_count, options, selectedShipmentIds } = this.state;

    return (
      <div className="v-awaiting-shipments v-page">
        <Title title="Shop Orders" subtitle="Manage Shipments automatically prepared for your eCommerce Orders">
          {connections.length ? (
            <Button className="v-title-button" label="Sync Shops" onClick={this.sync} />
          ) : (
            <a href="/shop-connections"><Button className="v-title-button">Connect a Shop</Button></a>
          )}
        </Title>

        <div
          className="v-center-right"
          style={{ gap: '3rem', alignItems: 'flex-end', justifyContent: 'space-between' }}
        >
          {connections.length ? (
            <ConnectionInfo>
              {connections.map((conn, n) => {
                const { shop, credentials: { email, domain }, internal: { synced_at, disabled_at, error } } = conn;

                const name = domain && domain.split('.')[0] || email || shop;
                const href = domain && `https://${domain}`;
                return (
                  <div key={n}>
                    <a href={href} title={shop} target="_blank" rel="noreferrer">{name}</a>:&nbsp;
                    {synced_at ? `synced ${moment(synced_at).fromNow()}` : 'never synced'}.
                    {disabled_at && (
                      <><br/>Auto-sync disabled {moment(disabled_at).calendar().toLowerCase()} due to {error}</>
                    )}
                  </div>
                )
              })}
            </ConnectionInfo>
          ) : ''}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button white onClick={this.buyLabels} disabled={!selectedShipmentIds.length}>
              Buy Selected Labels
            </Button>
            &nbsp;&nbsp;
            <DropdownList
              placeholder="Apply Rule"
              data={options.rules.map(([name, id]) => ({ name, id }))}
              dataKey="id"
              textField="name"
              onSelect={(value) => this.applyRule(value)}
              value={null}
              style={{ width: '20rem' }}
              disabled={!selectedShipmentIds.length}
            />
          </div>
        </div>
        <div className="v-center-left">
          <TableFiltersComponent
            display="filters search per"
            resource={this.resource}
            filters={[
              { name: 'shop_id', label: false, options: options.shops },
              { name: 'status', label: false, options: options.statuses, width: '15rem' },
              { name: 'sort_by', label: 'Order by', options: options.sort_by, width: '15rem' },
            ]}
            presets={options.presets}
            onChange={(p) => this.setState({ ...p })}
            searchPlaceholder="Search by shipment ID, order #, order value $, or reference"
          />
        </div>

        <Table
          noScroll
          data={shipments}
          context={this}
          config={tableConfig.call(this)}
          onClickRow={this.showOrder}
          tableClass="v-table-buy-labels"
          trClass={this.shipmentRowClass}
        />
        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage} />
      </div>
    );
  }
}

export default withRouter(connect(({ shopOrders }) => ({ shopOrders }))(ShopOrders));
