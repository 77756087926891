import { each } from 'lodash';

const initialState = {
  connections: [],
  orders:      [],
  shipments:   [],
  total_count: 0,
  options:     { shops: [], statuses: [], rules: [], sort_by: [['Updated ASC', 'updated_at asc']] },
  presets:     [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHOP_ORDERS':
      return action.payload;
    default:
      return state;
  }
};
