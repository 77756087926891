function getLocalStorage() {
  const raw = window.localStorage.getItem('VANLO-DASHBOARD');
  return raw ? JSON.parse(raw) : {};
}

export function readStorageData(key) {
  const storage = getLocalStorage() || {};
  return storage[key];
}

export function writeStorageData(key, value) {
  const storage = getLocalStorage() || {};
  storage[key]  = value;
  window.localStorage.setItem('VANLO-DASHBOARD', JSON.stringify(storage));
}
