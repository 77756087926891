import React from 'react';
import ReactPaginate from 'react-paginate';
import { classList } from '../helpers/classList';

export const Pagination = ({ currentPage, perPage, total_count, className, onChange }) => {
  const pageCount = Math.ceil((total_count || 0) / Math.max(perPage || 1, 1));
  if (pageCount <= 1) return null;
  return (
    <div className={classList('v-pagination v-center', className)}>
      <ReactPaginate
        onPageChange={(e) => onChange?.(e.selected + 1)}
        {...{
          forcePage:              currentPage - 1,
          disableInitialCallback: false,
          containerClassName:     'v-pagination-ul',
          pageClassName:          'v-pagination-page',
          pageCount,
          initialPage:            0,
          pageRangeDisplayed:     3,
          marginPagesDisplayed:   3,
        }}
      />
    </div>
  );
};
