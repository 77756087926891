import React from 'react';
import { connect } from 'react-redux';

import { Title } from '../../components/Title';
import { TableFiltersComponent } from '../../components/TableFiltersComponent';
import { Table } from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import { AdjustmentsResource } from '../../resources';
import tableConfig from './tableConfig';

const adjustmentFilters = [
  ['All', 'all'],
  ['Disputing', 'disputing'],
];

class Adjustments extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...AdjustmentsResource.filters };
  }

  setPage(page) {
    if (page !== this.state.page) {
      this.setState({ page });
      AdjustmentsResource.setFilter('page', page);
      AdjustmentsResource.list();
    }
  }

  render() {
    const { page, per } = this.state;
    const {
      adjustments: { transactions, total_count },
    } = this.props;

    return (
      <div className="v-page">
        <Title title="Adjustments" />

        <TableFiltersComponent
          resource={AdjustmentsResource}
          onChange={(p) => this.setState({ ...p })}
          filterTypesList={adjustmentFilters}
        />
        <div>
          <Table
            noScroll
            data={transactions}
            context={this}
            config={tableConfig.call(this)}
            tableClass="v-table-payment-log"
          />
        </div>
        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage.bind(this)} />
      </div>
    );
  }
}

export default connect(({ adjustments }) => ({ adjustments }))(Adjustments);
