import React from 'react';
import { connect } from 'react-redux';

const TitleComponent = ({ userName, person, title, subtitle, children }) => (
  <>
    <div className="v-title-bar">
      <div className="v-title">{title}</div>
      {children}
      <div className="v-username">{person ? `${userName} ${person.name}` : userName}</div>
    </div>
    {subtitle && <div className="v-subtitle v-no-padding v-center-left">{subtitle}</div>}
    <div className="v-hr v-hr-2" />
  </>
);

export const Title = connect(({ user: { name, person } }) => ({
  person,
  userName: name,
}))(TitleComponent);
