import React from 'react';
import { connect } from 'react-redux';
import { decamelize } from 'humps';
import { store } from '../../store';

import { Table } from '../../components/Table';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { PersonsResource } from '../../resources';
import { showModal } from '../../helpers/modal';
import AddUserModal from './AddUserModal';
import DeleteUserModal from './DeleteUserModal';
import tableConfig from './tableConfig';

class Permissions extends React.Component {
  togglePermission(id, permissionName) {
    PersonsResource.togglePermission(id, decamelize(permissionName)).then((response) => {
      store.dispatch({
        type:    'SET_PERSON',
        payload: { response, id },
      });
    });
  }

  permissionFor(id, permissionName) {
    const { persons } = this.props;

    return persons.find((user) => user.id === id).permissions[permissionName];
  }

  showAddUserModal() {
    showModal({
      title:     'Add User',
      component: AddUserModal,
    });
  }

  showDeleteUserModal = (user) => () => {
    showModal({
      title:     'Delete User',
      component: DeleteUserModal,
      payload:   { user },
    });
  };

  trClass(person) {
    if (!person.enabled) return 'v-table-tr-disabled';
  }

  render() {
    const { persons } = this.props;

    return (
      <div className="v-page">
        <Title title="Permissions" subtitle="Create sub-users with specific permissions.">
          <Button className="v-title-button" onClick={this.showAddUserModal}>
            Add User
          </Button>
        </Title>

        <Table
          noScroll
          data={persons}
          context={this}
          trClass={this.trClass}
          config={tableConfig.call(this)}
          tableClass="v-table-persons"
        />
      </div>
    );
  }
}

export default connect(({ persons }) => ({ persons }))(Permissions);
