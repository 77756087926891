import { writeStorageData, readStorageData } from '../helpers/localStorage';
import { store } from '../store';
import { tryTokenLogin } from '../helpers/tokens';

const initialState = readStorageData('SESSION_KEY') || null;

setTimeout(() => {
  const token = window.location.search.replace('?token=', '');
  if (token && token.length) {
    const hosts = Object.entries(store.getState().server.serverOptions);
    tryTokenLogin(hosts[0], hosts.slice(1), token).then(() => {
      window.location.href = '/shipments';
    });
  }
}, 800);

export const session = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SESSION': {
      writeStorageData('SESSION_KEY', action.payload);
      return action.payload;
    }
    default:
      return state;
  }
};
