import React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { startCase } from 'lodash';

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ headers }) => headers.map(() => 'auto').join(' ')};
  column-gap: 2rem;
  row-gap: 1rem;
  & > .header {
    font-weight: bolder;
    font-size: 80%;
    /* border-bottom: 1px solid #555; */
  }
`

const DataGrid = ({ headers, rows }) => {
  headers = headers || Object.keys(rows[0]);
  return (
    <Grid headers={headers}>
      {headers.map((key) => <div className="header" key={key}>{key.toUpperCase()}</div>)}
      {rows.map((row, n) => (
        headers.map((key) => <div key={key}>{String(row[key] ?? '')}</div>)
      ))}
    </Grid>
  )
};

export { DataGrid };
