import axios from 'axios';
import { getSession } from '../helpers/sessions';
import { store } from '../store';
import { showBusyMessage } from '../helpers/modal';

export const getVanloURL = (endpoint) => {
  const { url } = store.getState().server;
  return endpoint ? url + endpoint : url;
};

export const queryVanlo = async (endpoint, method, data = {}, params = null) => {
  const queryString = params
    ? `?${Object.entries(params).filter(([k, v]) => !!v).map(([k, v]) => `${k}=${v}`).join('&')}`
    : '';
  try {
    params  = {
      data,
      method:  method || 'GET',
      url:     getVanloURL(endpoint + queryString),
      headers: {
        Accept:         'application/json',
        'Content-Type': 'application/json',
        'X-API-Token':  getSession() || '',
      },
    }
    const r = await axios(params);
    data    = r?.data || { success: false, message: 'Empty response from the server' };
    /* eslint-disable-next-line no-console */
    data.error && console.error(data.error);
    return data;
  } catch (e) {
    // This won't happen when code is 200
    // JS spaghetti magic
    // The `showBusyMessage` chains a `then` on a `promise`.
    // That `then` receives a function to close the modal, and the function resolves right when the `resolve` is called.
    // This way, the alert modal will be closed in 3 seconds.
    // Or it will be immediately replaced with another error modal popped up from `then` callback.
    let resolve,
     message;
    const promise = new Promise((res) => (resolve = res));
    setTimeout(() => resolve(), 3000);

    if (e.response?.status === 401) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (e.response) {
      message = e.response.data?.message || e.response.statusText;
    } else {
      /* eslint-disable-next-line no-console */
      console.log(e);
      message = `Failed to make a request`;
    }
    showBusyMessage(`Request error: ${message}`, promise);

    return { success: false, message };
  }
};
