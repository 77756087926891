import React from 'react';
import { classList } from '../helpers/classList';
import { IconInvalid } from '../helpers/icons';

const applyConstraints = (value, { validChars, maxLength }) => {
  let retVal = value;
  if (validChars) {
    const regex = new RegExp(validChars);
    while (retVal.length > 0 && !regex.test(retVal)) {
      retVal = retVal.substring(0, retVal.length - 1);
    }
  }
  if (maxLength) {
    retVal = retVal.substring(0, maxLength);
  }
  return retVal;
};

const testValid = (value, { pattern }) => new RegExp(pattern || '').test(value);

const Textbox = ({
  labelLeft,
  styleLabelLeft,
  input,
  type,
  style,
  rows,
  value,
  disabled,
  icon: Icon,
  name,
  placeholder,
  hidePlaceholder,
  className,
  label,
  children,
  onChange,
  constraints = {},
  tabindex,
  ...rest
}) => {
  const isPassword   = type === 'password';
  const valid        = testValid(value, constraints);
  const handleChange = (e) => {
    if (!onChange) return;
    e.target.value = applyConstraints(e.target.value, constraints);
    onChange(e);
  };
  const props = {
    type:         type || 'text',
    autoComplete: 'off',
    className:    classList('v-text', isPassword && 'v-password', disabled && 'v-text-disabled', className),
    value:        value || '',
    name,
    style,
    disabled,
    rows,
    tabIndex:     tabindex || 0,
    ...input,
    ...rest,
    onChange:     handleChange,
  };

  return (
    <div className="v-input">
      {label && (
        <label htmlFor={name} className="v-text-label">
          {label}
        </label>
      )}
      <div
        className={classList('v-input-row', labelLeft ? 'v-select-inline' : 'v-full-width')}
        style={{ position: 'relative' }}
      >
        {labelLeft && (
          <label className="v-text-label-left" style={styleLabelLeft}>
            {labelLeft}
          </label>
        )}
        {rows && rows > 1 ? (
          <textarea placeholder={label || placeholder} {...props}>
            {value}
            {children}
          </textarea>
        ) : (
          <input placeholder={hidePlaceholder ? null : placeholder || label} {...props} />
        )}
        {Icon && <Icon />}
        {!valid && (
          <IconInvalid
            style={{
              width:    20,
              height:   20,
              position: 'absolute',
              right:    0,
              top:      15,
            }}
          />
        )}
      </div>
    </div>
  );
};

export { Textbox };
