import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { Title, Button, Table, TableFiltersComponent, Pagination } from '../../components';
import { showModal } from '../../helpers/modal';
import { jsonSyntaxHighlight } from '../../helpers/json';
import { NewEndpointModal } from './NewEndpointModal';
import { endpointsTableConfig, eventsTableConfig } from './config';
import { WebhooksResource } from '../../resources';

class Webhooks extends React.Component {
  resource = WebhooksResource;

  state = { ...this.resource.filters };

  setPage = (page) => {
    if (page !== this.state.page) {
      this.setState({ page });
      this.resource.setFilter('page', page);
      this.resource.list();
    }
  }

  toggleWebhookActivity(id, enabled) {
    WebhooksResource.update({ id, webhook_endpoint: { enabled } }, null);
  }

  deleteWebhook(id) {
    WebhooksResource.delete(id);
  }

  // getWebhookEvents(id) {
  //   WebhooksResource.getEvents(id).then((r) => {
  //     this.setState({ events: r });
  //   });
  // }

  sendSample(id) {
    WebhooksResource.sample(id);
  }

  showEvent(data) {
    showModal({
      title:     `${data.name} ${data.public_id}`,
      component: () => (
        <div>
          <pre
            style={{ whiteSpace: 'pre-wrap', maxWidth: '80vw', outline: 0 }}
            dangerouslySetInnerHTML={{ __html: jsonSyntaxHighlight(data.body) }}
          />
          {data.failure && <h4 style={{ marginTop: '4rem' }}>Failure at {data.failure.url}</h4>}
          {data.failure && (
            <pre
              style={{ whiteSpace: 'pre-wrap', maxWidth: '80vw', outline: 0 }}
              dangerouslySetInnerHTML={{ __html: jsonSyntaxHighlight(data.failure.details) }}
            />
          )}
        </div>
      ),
    });
  }

  isOnlyUrl = (url) => {
    return !this.props.webhooks.endpoints.find((x) => x.url !== url)
  }

  newEndpoint = () => {
    showModal({
      title:     `New Webhook Endpoint`,
      component: NewEndpointModal,
      payload:   { options: this.props.webhooks.options }
    });
  }

  render() {
    const { webhooks: { endpoints, events, total_count, options } } = this.props;
    const { per, page }                                             = this.state;

    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title title="Webhooks" subtitle="Manage your webhook endpoints and monitor events" />

        <div className="v-padding-bottom-2">
          <Button onClick={this.newEndpoint}>Add Webhook Endpoint</Button>
        </div>
        <Table data={endpoints} config={endpointsTableConfig.call(this)} />

        <TableFiltersComponent
          display="filters dates per"
          resource={this.resource}
          filters={[
            { name: 'api', label: false, options: options.apis, width: '15rem' },
            { name: 'name', label: false, options: options.names[this.resource.filters.api] || [] },
            { name: 'status', label: false, options: options.statuses, width: '15rem' }
          ]}
          onChange={(p) => this.setState({ ...p })}
        />
        <Table data={events} config={eventsTableConfig.call(this)} onClickRow={this.showEvent} />
        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage} />
      </div>
    );
  }
}

export default connect(({ webhooks }) => ({ webhooks }))(Webhooks);
