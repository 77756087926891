const initialState = {
  auto_approve_payouts: {
    value: false,
  },
  recharge_threshold: {
    options: [],
    value:   0,
  },
  replenishment_amount: {
    options: [],
    value:   0,
  },
  primary_topup_method_id: {
    options: [],
    value:   0,
  },
  secondary_topup_method_id: {
    options: [],
    value:   0,
  },
  invoice_autopay_method_id: {
    options: [],
  },
  printer_id: {
    options: [],
  },
  label_layout: {
    options: [],
  },
};

export const userSettings = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_USER_SETTINGS': {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
