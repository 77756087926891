import React from 'react';
import { Button } from '../../components/Button';
import { IconShopify, IconWoo, IconBigcommerce, IconEbay, IconShipstation } from '../../helpers/icons';

export const iconKey = {
  shopify:     IconShopify,
  woocommerce: IconWoo,
  bigcommerce: IconBigcommerce,
	ebay:        IconEbay,
	shipstation: IconShipstation,
};

export function getTableConfig() {
  return [
    {
      title: '',
      field: 'shop',
      style: {
        maxWidth:       '14rem',
        justifyContent: 'center',
      },
      cell: (data) => {
        const Icon = iconKey[data];
        return Icon && <Icon width={30} height={30} />;
      },
    },
    {
      title: '',
      field: 'credentials',
      cell:  ({ domain, email, key }) => domain || email || key,
    },
    {
      title: 'Imported',
      field: 'shop_orders_count',
      style: { maxWidth: '14rem' },
      cell:  (count) => `${count} orders`,
    },
    {
      title:     'Action',
      className: 'v-center',
      style:     { maxWidth: '30rem' },
      cell:      (data, row, col, all) => (
        <div className="v-center">
          {/* <Button label="Edit" small white onClick={this.editShopConnection.bind(this, all)} />&nbsp; */}
          <Button label="Delete" small white onClick={this.deleteShopConnection.bind(this, all.id)} />&nbsp;
          <Button small white
            label="Resync Orders"
            title="Use if Shop Orders still seem to miss something after the normal sync"
            onClick={this.resync.bind(this, all.id)}
          />
        </div>
      ),
    },
  ];
}

export const getDetailView = (data) => {
  return {
    Id:   data.id,
    Shop: data.shop,
  };
};
