import React from 'react';
import { getNumber, formatCurrency } from '../../helpers/data';
import { Card } from '../../components/Card';
import { Grid, Grid1 } from '../../components/Grids';
import { Select } from '../../components/Select';
import { UserSettingsResource } from '../../resources';

const topupOptions = (options, excluded) => {
  return options.filter((m) => m[1] !== excluded).map((m) => ({ value: m[1], label: m[0] }));
};

class RechargePanel extends React.Component {
  change({ target: { name, value } }) {
    if (value.match && value.match(/^\//)) return (window.location.href = value);
    const param = name.replace(/([A-Z])/g, '_$1'.toLowerCase);
    UserSettingsResource.update({ settings: { [param]: value } }, 'Updating payment method...');
  }

  isSelectedTopupMethodCC = (topMethodOptions) => {
    if (topMethodOptions.options.length > 0) {
      const selectedValue = topMethodOptions.options.filter((option) => {
        return option[1] === topMethodOptions.value;
      });
      if (selectedValue.length > 0) {
        const label = selectedValue[0][0];
        if (
          label.toLowerCase().startsWith('visa') ||
          label.toLowerCase().startsWith('mastercard') ||
          label.toLowerCase().startsWith('american express')
        ) {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const {
      userSettings: { recharge_threshold, replenishment_amount, primary_topup_method_id, secondary_topup_method_id },
    } = this.props;
    const isPrimaryTopupMethodCC   = this.isSelectedTopupMethodCC(primary_topup_method_id);
    const isSecondaryTopupMethodCC = this.isSelectedTopupMethodCC(secondary_topup_method_id);
    const change                   = this.change.bind(this);
    const noteStyle                = {
      marginTop: '55px',
      display:   isPrimaryTopupMethodCC || isSecondaryTopupMethodCC ? 'block' : 'none',
    };

    return (
      <Card style={{ padding: '1rem 1.5rem' }}>
        <Grid>
          <Grid1 className="v-margin-right-3rem">
            <Select
              value={recharge_threshold.value}
              name="recharge_threshold"
              items={recharge_threshold.options.map((option) => ({
                option,
                label: formatCurrency(option, false),
                value: getNumber(option),
              }))}
              label="Recharge threshold"
              className="v-select-underlined"
              onChange={change}
            />
          </Grid1>
          <Grid1>
            <Select
              value={replenishment_amount.value}
              name="replenishment_amount"
              items={replenishment_amount.options
                .filter((option) => getNumber(option) >= recharge_threshold.value)
                .map((option) => ({
                  option,
                  label: formatCurrency(option, false),
                  value: getNumber(option),
                }))}
              label="Replenishment amount"
              className="v-select-underlined"
              onChange={change}
            />
          </Grid1>
        </Grid>
        <br />
        <br />
        <Grid>
          <Grid1 className="v-margin-right-3rem">
            <Select
              value={primary_topup_method_id.value}
              name="primary_topup_method_id"
              items={topupOptions(primary_topup_method_id.options, secondary_topup_method_id.value)}
              label="1st topup method"
              className="v-select-underlined"
              onChange={change}
              allowAddPaymentOption
            />
          </Grid1>
          <Grid1>
            <Select
              disabled={!primary_topup_method_id.value}
              value={secondary_topup_method_id.value}
              name="secondary_topup_method_id"
              items={topupOptions(secondary_topup_method_id.options, primary_topup_method_id.value)}
              label="2nd topup method"
              className="v-select-underlined"
              onChange={change}
              allowAddPaymentOption
            />
          </Grid1>
        </Grid>
        <Grid>
          <Grid1>
            <div style={noteStyle}>Credit card fees may apply.</div>
          </Grid1>
        </Grid>
      </Card>
    );
  }
}

export { RechargePanel };
