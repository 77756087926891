import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../../components/Button';
import { Textbox } from '../../components/Textbox';
import { Grid, Grid1 } from '../../components/Grids';
import { Title } from '../../components/Title';
import { Table } from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import { Select } from '../../components/Select';
import { LeadSearchesResource } from '../../resources';
import tableConfig from './tableConfig';

class LeadSearches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lead_country: 'United States',
      ...LeadSearchesResource.filters,
    };
  }

  setPage = (page) => {
    if (page !== this.state.page) {
      this.setState({ page });
      LeadSearchesResource.setFilter('page', page);
      LeadSearchesResource.list();
    }
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  createLeadSearch = () => {
    const { username, lead_country } = this.state;

    LeadSearchesResource.create({ username, lead_country }).then((r) => {
      this.setState({ username: '', lead_country: 'United States' });
    });
  };

  render() {
    const {
      leadSearches: { lead_searches, total_count },
    } = this.props;
    const { page, per, username, lead_country } = this.state;

    return (
      <div className="v-page">
        <Title title="Lead Searches" />
        <Grid className="v-left">
          <Grid1 className="manifest-form">
            <div className="v-width-20">
              <Textbox
                placeholder="Username"
                name="username"
                value={username}
                onChange={this.handleChange}
                style={{ border: '1px solid #aaa', borderRadius: '4px' }}
              />
            </div>
            {/*
            <div className="v-width-20" style={{ margin: '-9px 0 0 8px' }}>
              <Select
                name="lead_country"
                value={lead_country}
                items={[
                  { label: 'United States', value: 'United States' },
                  { label: 'Australia', value: 'Australia' },
                  { label: 'Canada', value: 'Canada' },
                  { label: 'Columbia', value: 'Columbia' },
                  { label: 'Germany', value: 'Germany' },
                  { label: 'Mexico', value: 'Mexico' },
                  { label: 'Russia', value: 'Russia' },
                  { label: 'Spain', value: 'Spain' },
                  { label: 'United Kingdom', value: 'United Kingdom' },
                ]}
                onChange={this.handleChange}
              />
            </div>
            */}
            <Button onClick={this.createLeadSearch} disabled={!username}>
              Start Lead Search
            </Button>
            <div>
              <h5 className="manifest-subtitle">Submit an Instagram username to start a lead search.</h5>
            </div>
          </Grid1>
          <Grid1 className="v-right">
            <Button onClick={() => LeadSearchesResource.runAndReload('refresh')} white small>
              Refresh
            </Button>
          </Grid1>
        </Grid>
        <Table
          noScroll
          data={lead_searches}
          context={this}
          config={tableConfig.call(this)}
          tableClass="v-table-invoices"
          trClass={this.trClass}
        />

        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage} />
      </div>
    );
  }
}

export default connect(({ leadSearches }) => ({ leadSearches }))(LeadSearches);
