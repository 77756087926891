import React from 'react';
import { ImgVISA } from '../../helpers/images';
import { Button } from '../../components/Button';
import { classList } from '../../helpers/classList';
import { showModal, closeModal } from '../../helpers/modal';
import { RemoveModal } from './RemoveModal';
import { PaymentMethodResource } from '../../resources';

export class BankCard extends React.Component {
  state = {};

  performDelete() {
    const {
      card: { id },
      setProcessing,
    } = this.props;
    setProcessing();
    PaymentMethodResource.delete(id, 'Removing payment method...').then(() => {
      setProcessing();
      closeModal();
    });
  }

  deleteCard() {
    const { id, card_type, last4 } = this.props.card;
    showModal({
      title:   `Delete`,
      payload: {
        id,
        title:         card_type,
        message:       `Remove bank card ending in ${last4}?`,
        performDelete: this.performDelete.bind(this),
      },
      component: RemoveModal,
    });
  }

  renderRemoveBankCard() {
    const { processing, card: id, card_type, last4 } = this.props;
    const { removing, settingAsPrimary }             = this.state;

    if (removing) {
      return <span className="v-card-removing-tag">Removing...</span>;
    }
    if (settingAsPrimary) {
      return <span className="v-card-updating-tag">Updating...</span>;
    }

    return (
      <Button
        disabled={processing}
        onClick={this.deleteCard.bind(this, id, card_type, `Remove bank card ending in ${last4}?`)}
        small
        white
      >
        Delete
      </Button>
    );
  }

  render() {
    const { card_type, last4, expires, primary } = this.props.card;
    const { removing, settingAsPrimary }         = this.state;

    return (
      <div
        className={classList(
          'v-credit-card-container v-center-left',
          (removing || settingAsPrimary) && 'v-card-container-busy'
        )}
      >
        <ImgVISA className="v-credit-card-icon" />
        <div className="v-credit-card-info v-left">
          <div className="v-credit-card-info-type">{card_type}</div>
          <div className="v-credit-card-info-last4">
            <span>
              xxxx-xxxx-xxxx-{last4} |{' '}
              <span className="v-credit-card-info-expire">
                Expires: <b>{expires}</b>
              </span>
            </span>
            <div className="v-flex v-center">{primary && <span className="v-credit-card-primary">Primary</span>}</div>
          </div>
          {this.renderRemoveBankCard()}
        </div>
      </div>
    );
  }
}
