import React from 'react';
import { connect } from 'react-redux';
import { decamelizeKeys } from 'humps';
import { Checkbox } from '../../components/Checkbox';
import { classList } from '../../helpers/classList';
import { IconDownload } from '../../helpers/icons';
import { Hr } from '../../components/Hr';
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';
import { Grid } from '../../components/Grids';
import { closeModal } from '../../helpers/modal';
import { UserSettingsResource } from '../../resources';

class SettingsModalComponent extends React.Component {
  state = {
    printerId:           this.props.userSettings.printer_id.value,
    labelLayout:         this.props.userSettings.label_layout.value,
    labelFormat:         this.props.userSettings.label_format.value,
    downloadPackingSlip: this.props.userSettings.download_packing_slip.value,
  };

  change({ target: { name, value } }) {
    if (name === 'labelFormat') {
      return this.setState({ labelFormat: value ? 'zpl' : 'pdf' });
    }
    this.setState({ [name]: value });
  }

  update() {
    this.setState({ busy: true });
    UserSettingsResource.update({ settings: decamelizeKeys(this.state) }, null, {
      onError:   (m) => this.setState({ busy: false, error: m }),
      onSuccess: closeModal,
    });
  }

  render() {
    const { printerId, labelLayout, labelFormat, downloadPackingSlip, busy, error } = this.state;
    const { userSettings: { printer_id, label_layout } }                            = this.props;
    const change                                                                    = this.change.bind(this);
    return (
      <div className={classList('v-modal-form v-width-50', 'v-center-column v-center-left')}>
        {/*
          <Select
            name="printerId"
            labelLeft="Printer:"
            value={printerId}
            styleLabelLeft={{ width: '8rem' }}
            className="v-padding-right-3 v-select-small-no-border v-width-40"
            items={printer_id.options.map(([label, value]) => ({ label, value }))}
            onChange={change}
          />
          <div style={{ paddingLeft: '9rem', margin: '1rem 0' }}>
            To select a remote printer, you need to connect it via Vanlo Print
            which you can download by one of these links depending on your OS:
            <div
              style={{ padding: '1rem 0' }}
              className="v-center-column v-center-left"
            >
              <a
                className="v-link v-center-left v-link-no-line"
                style={{ width: 'unset', padding: '0.4rem 0' }}
                href="https://s3-us-west-2.amazonaws.com/cdn.vanlo.com/print-client/VanloPrint-4.24.0.exe"
              >
                <IconDownload className="v-icon-18 v-padding-right-5" /> Download
                for Windows
              </a>
              <a
                className="v-link v-center-left v-link-no-line"
                style={{ width: 'unset', padding: '0.4rem 0' }}
                href="https://s3-us-west-2.amazonaws.com/cdn.vanlo.com/print-client/VanloPrint-4.24.0.dmg"
              >
                <IconDownload className="v-icon-18 v-padding-right-5" /> Download
                for OS X
              </a>
            </div>
          </div>
        */}
        <Grid>
          <Select
            name="labelLayout"
            onChange={change}
            value={labelLayout}
            labelLeft="Label layout:"
            items={label_layout.options.map((value) => ({
              label: value,
              value,
            }))}
            styleLabelLeft={{ width: '8rem' }}
            className="v-padding-right-2 v-select-small-no-border v-width-10"
          />
          <Checkbox
            disabled={labelLayout !== '4X6'}
            checked={labelLayout === '4X6' && labelFormat === 'zpl'}
            onChange={change}
            name="labelFormat"
            label="Use ZPL format"
          />
          <Checkbox
            onChange={change}
            checked={downloadPackingSlip}
            name="downloadPackingSlip"
            label="Add packing slip"
          />
        </Grid>
        {error && <div className="v-error-message v-padding-top-2 v-padding-bottom-1 v-text-red v-center">{error}</div>}
        <Hr h2 />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button label="Update" disabled={busy} onClick={this.update.bind(this)} />
        </div>
      </div>
    );
  }
}

export const SettingsModal = connect(({ userSettings }) => ({ userSettings }))(SettingsModalComponent);
