import { resource } from './resource';
import { queryVanlo } from './query';
import { store } from '../store';

const route      = '/user';
const storageKey = 'SET_USER';

function settings(params) {
  return queryVanlo(`/dashboard/user/settings`, 'PATCH', params).then((result) => {
    store.dispatch({ type: 'SET_USER_SETTINGS', payload: result.settings });
  });
}

export const UserResource = resource({ route, storageKey, settings });
