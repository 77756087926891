import { resource } from './resource';
import { queryVanlo } from './query';
import { store } from '../store';
import { tryTokenLogin } from '../helpers/tokens';
import { showBusyMessage, showErrorMessage } from '../helpers/modal';

const route      = '/api_keys';
const storageKey = 'SET_API_KEYS';
function setDefault(id) {
  const pr = queryVanlo(`/dashboard/api_keys/${id}/default`, 'PATCH', { default: true });
  return this.runAndReload(pr, '');
}

function switchServers(serverName) {
  let resolve;
  const promise = new Promise((res) => (resolve = res));
  showBusyMessage('Switching servers...', promise);
  queryVanlo(`/dashboard/api_keys/token`, 'GET').then((r) => {
    if (r.token) {
      const serverURL = store.getState().server.serverOptions[serverName];
      tryTokenLogin([serverName, serverURL], [], r.token)
        .then(resolve)
        .catch(() => {
          showErrorMessage('Failed to authenticate, account may not exist');
        });
    }
  });
  return promise;
}

export const ApiKeyResource = resource({
  route,
  storageKey,
  setDefault,
  switchServers,
});
