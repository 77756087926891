import { resource } from './resource';
import { getFormattedDate, getPastDays } from '../components/Calendar';

export const ShipmentUploadsResource = resource({
  route:      '/shipment_uploads',
  storageKey: 'SET_SHIPMENT_UPLOADS',
  filters:    {
    per:       50,
    page:      1,
    date_from: getFormattedDate(getPastDays(7)),
    date_to:   getFormattedDate(getPastDays(-1)),
  },
});
