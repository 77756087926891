import React from 'react';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Hr } from '../../components/Hr';
import { closeModal } from '../../helpers/modal';
import { WarehouseAddressesResource } from '../../resources';

export class DeleteWarehouseContainer extends React.Component {
  state = {};

  deleteWarehouse() {
    this.setState({ busy: true });
    WarehouseAddressesResource.delete(this.props.warehouse.id).then(() => closeModal());
  }

  render() {
    const { warehouse }   = this.props;
    const { busy, error } = this.state;
    return (
      <div className={classList('v-modal-form v-width-50 v-padding-left-1', 'v-center-column v-center-left')}>
        <label>
          Address:
          <div className="v-remove-warehouse-address">{warehouse.fullAddress}</div>
        </label>
        <Hr />
        <div className="v-error-message">{error}</div>
        <br />
        <div className="v-center-right">
          <Button disabled={busy} className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button
            disabled={busy}
            label={busy ? 'Processing...' : 'Delete Warehouse'}
            onClick={this.deleteWarehouse.bind(this)}
          />
        </div>
      </div>
    );
  }
}
