const initialState = {
  reports: [],
  options: { report_types: [] },
};

export const reports = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_REPORTS':
      return { ...state, ...payload };
    default:
      return state;
  }
};
