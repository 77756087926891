import React from 'react';
import { Button } from '../../components/Button';
import { formatDate } from '../../helpers/data';

export default function () {
  const deleteTitle = 'Delete the file and shipments imported from it that have no label';
  return [
    {
      title: 'Date (UTC)',
      field: 'created_at',
      cell:  (data) => formatDate(data),
    },
    {
      title: 'File',
      cell:  (...[, , , upload]) => upload.file_url && <a href={upload.file_url}>{upload.filename}</a>,
    },
    {
      title: 'Mapping',
      field: 'mapping_name',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Rows / New / Labels',
      cell:  (...[, , , upload]) => `${upload.rows_count} / ${upload.shipments_count} / ${upload.labels_count}`,
    },
    /* eslint-disable no-else-return */
    {
      title: 'Actions',
      style: { minWidth: '17rem' },
      cell:  (...[, , , upload]) => {
        if (upload.status === 'imported') {
          if (upload.shipments_count !== upload.labels_count) {
            return (
              <>
                <Button white small onClick={this.rateShop.bind(this, upload.id)}>
                  Rate Shop
                </Button>
                <Button white small title={deleteTitle} onClick={this.delete.bind(this, upload.id)}>
                  Delete
                </Button>
              </>
            );
          } else {
            return (
              <>
                <Button white small onClick={this.viewShipments.bind(this, upload.filename)}>
                  View Shipments
                </Button>
              </>
            );
          }
        }
      },
    },
    /* eslint-enable no-else-return */
  ];
}
