import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { Card } from '../../components/Card';
import { Grid, Grid1 } from '../../components/Grids';
import { Textbox } from '../../components/Textbox';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Button';
import { UserResource } from '../../resources';
import { isValidEmail } from '../../helpers/data';
import { Title } from '../../components/Title';

class BillingInformation extends React.Component {
  state = { user: {}, contact_address: {}, billing_address: {} };

  submit() {
    const { contact_address, billing_address, user } = this.state;
    const error                                      = isValidEmail(user.email || this.props.user.email) ? false : 'Email is not valid!';
    if (error) return this.setState({ error });
    this.setState({ isBusy: true, error: false });
    UserResource.update({
      user: {
        ...user,
        contact_address_attributes: contact_address,
        billing_address_attributes: billing_address,
      },
    }).then(() => this.setState({ isBusy: false }));
  }

  change({ target: { name, value } }) {
    const [section, field] = name.split('--');
    this.setState({ [section]: { ...this.state[section], [field]: value } });
  }

  render() {
    const { addresses, user }                                                    = this.props;
    const { isBusy, contact_address, billing_address, user: userChanges, error } = this.state;
    const values                                                                 = {
      user:            { ...user, ...userChanges },
      contact_address: { ...addresses.contact_address, ...contact_address },
      billing_address: { ...addresses.billing_address, ...billing_address },
    };
    const change                                                                 = this.change.bind(this);
    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title title="Billing Information" subtitle="Please keep your billing and contact details updated." />
        <Card className="v-padding-2">
          <Grid>
            <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
              <div className="v-headline-label-text v-margin-bottom-2">Contact Information</div>
              <Textbox
                disabled={isBusy}
                onChange={change}
                name="user--name"
                label="Fullname"
                value={values.user.name}
              />
              <Textbox disabled={isBusy} onChange={change} name="user--email" label="Email" value={values.user.email} />
              <Textbox disabled={isBusy} onChange={change} name="user--phone" label="Phone" value={values.user.phone} />
            </Grid1>
            <Grid1 className="v-grid-column v-padding-left-3">
              <div className="v-margin-bottom-2 v-headline-label-text">Contact address</div>
              <Textbox
                disabled={isBusy}
                onChange={change}
                name="contact_address--street_name"
                label="Street name"
                value={values.contact_address.street_name}
              />
              <Textbox
                disabled={isBusy}
                onChange={change}
                name="contact_address--city"
                label="City"
                value={values.contact_address.city}
              />
              <Grid>
                <Grid1 className="v-padding-right-3">
                  <Textbox
                    disabled={isBusy}
                    onChange={change}
                    name="contact_address--state"
                    label="state"
                    value={values.contact_address.state}
                  />
                </Grid1>
                <Grid1>
                  <Textbox
                    disabled={isBusy}
                    onChange={change}
                    name="contact_address--zip"
                    label="zip"
                    constraints={{
                      pattern:    '^[0-9]{5}(-[0-9]{4})?$',
                      validChars: '^[\\d-]*$',
                      maxLength:  10,
                    }}
                    value={values.contact_address.zip}
                  />
                </Grid1>
              </Grid>
              <Checkbox
                style={{ padding: '2.5rem 0' }}
                onChange={change}
                checked={values.user.billing_eq_contact}
                name="user--billing_eq_contact"
                label="Billing address is the same as contact address"
              />

              <div
                style={{
                  display: values.user.billing_eq_contact ? 'none' : '',
                }}
              >
                <div className="v-margin-bottom-2 v-headline-label-text">Billing Address</div>
                <div>
                  <Textbox
                    disabled={isBusy}
                    onChange={change}
                    name="billing_address--street_name"
                    label="Street name"
                    value={values.billing_address.street_name}
                  />
                  <Textbox
                    disabled={isBusy}
                    onChange={change}
                    name="billing_address--city"
                    label="City"
                    value={values.billing_address.city}
                  />
                  <Grid>
                    <Grid1 className="v-padding-right-3">
                      <Textbox
                        disabled={isBusy}
                        onChange={change}
                        name="billing_address--state"
                        label="state"
                        value={values.billing_address.state}
                      />
                    </Grid1>
                    <Grid1>
                      <Textbox
                        disabled={isBusy}
                        onChange={change}
                        name="billing_address--zip"
                        label="zip"
                        constraints={{
                          pattern:    '^[0-9]{5}(-[0-9]{4})?$',
                          validChars: '^[\\d-]*$',
                          maxLength:  10,
                        }}
                        value={values.billing_address.zip}
                      />
                    </Grid1>
                  </Grid>
                </div>
              </div>
            </Grid1>
          </Grid>
          {/* <div className='v-hr v-hr-3' /> */}
          <br />
          <ul
            className={classList(
              'v-no-margin v-no-padding v-padding-left-2 vv-padding-top-1',
              error && 'v-padding-bottom-2'
            )}
          >
            {error && (
              <li key={error} className="v-error-message">
                {error}
              </li>
            )}
          </ul>

          <Grid>
            <Grid1 className="v-padding-right-3">
              <Button disabled={isBusy} onClick={this.submit.bind(this)}>
                {isBusy ? 'UPDATING DATA...' : 'SAVE CHANGES'}
              </Button>
            </Grid1>
            <Grid1 className="v-padding-left-3" />
          </Grid>
        </Card>
      </div>
    );
  }
}

export default connect(({ addresses, user }) => ({ addresses, user }))(BillingInformation);
