import { getFormattedDate, getPastDays } from '../components/Calendar';

const initialState = {
  shipment_uploads: [],
  total_count:      0,
};

export const shipmentUploads = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_SHIPMENT_UPLOADS':
      return payload;

    default:
      return state;
  }
};
