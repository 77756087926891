import { camelizeKeys } from 'humps';

export const persons = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_PERSONS':
      return camelizeKeys(payload);
    case 'SET_PERSON': {
      const index = state.findIndex((user) => user.id === payload.id);

      return [...state.slice(0, index), camelizeKeys(payload.response), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
};
