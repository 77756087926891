import { resource } from './resource';
import { queryVanlo } from './query';
import { closeModal, showBusyMessage, showErrorMessage } from '../helpers/modal';

function test(id) {
  const promise = queryVanlo(`${this.namespace}${this.route}/${id}/test`, 'POST');
  showBusyMessage('Sending sample...', promise);
  promise.then(({ success, message }) => {
    success ? closeModal() : showErrorMessage(message);
  });
  return promise;
}

export default resource({
  route:      '/report_schedules',
  storageKey: 'SET_REPORT_SCHEDULES',
  filters:    {
    report_type: 'all',
    period:      'all',
  },
  test,
});
