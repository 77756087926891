import React from 'react';
import { minBy } from 'lodash';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';

export function tableConfig() {
  return [
    {
      title:     <Checkbox checked={this.state.selectedShipmentIds.length > 0} onChange={this.onCheckAll} />,
      className: 'v-table-checkbox',
      cell:      (...[, , , shipment]) => (
        <Checkbox
          checked={this.state.selectedShipmentIds.includes(shipment.id)}
          onChange={this.onCheck(shipment.id)}
          disabled={this.state.purchasedShipmentIds.indexOf(shipment.id) !== -1}
        />
      ),
    },
    {
      title:     'Date (UTC)',
      field:     'date',
      className: 'date',
    },
    {
      title:     '#',
      field:     'custom_id',
      className: 'order-id',
    },
    {
      title:     'To',
      field:     'to_name',
      className: 'to',
      style:     { minWidth: '10rem' },
    },
    {
      title:     'Rate',
      field:     'rates',
      className: 'rates',
      onClick:   () => false,
      style:     { minWidth: '35rem' },
      cell:      (...[rates, , , shipment]) => {
        if (this.state.purchasedShipmentIds.indexOf(shipment.id) !== -1) {
          return <div className="purchased">Label purchased successfully!</div>;
        }

        return (
          <Select
            value={this.selectedRatedId(shipment)}
            items={rates.map((rate) => ({
              label: `${rate.service} — ${rate.rate}`,
              value: rate.public_id,
            }))}
            onChange={this.changeRate(shipment.id)}
            style={{ minWidth: '31rem' }}
          />
        );
      },
    },
    {
      title:     'Actions',
      className: 'actions',
      cell:      (...[, , , shipment]) => (
        <Button
          small
          white
          onClick={this.buyLabel(shipment)}
          disabled={this.state.purchasedShipmentIds.indexOf(shipment.id) !== -1}
        >
          Buy Label
        </Button>
      ),
    },
  ];
}
