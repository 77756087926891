import React from 'react';

import { Link } from 'react-router-dom';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { Grid, Grid1 } from '../../components/Grids';

const Summary = ({ data }) => (
  <div>
    <Grid>
      <Grid1>
        <Card className="v-padding-2 v-width-60 v-margin-bottom-2 v-padding-right-2">
          <div className="v-headline-label-text v-margin-bottom-2">Success! Tracking: {data.tracking_code}</div>
          <h2>{data.id}</h2>

          <pre style={{ overflow: 'scroll' }}>{JSON.stringify(data, null, 2)}</pre>
        </Card>
      </Grid1>
      <Grid1>
        <Card className="v-padding-2 v-margin-bottom-2">
          <p>
            <strong>From</strong>
            <br />
            {data.from_address.name}
            <br />
            {data.from_address.street1} {data.from_address.street2}
            <br />
            {data.from_address.city}
            <br />
            {data.from_address.zip} {data.from_address.country}
          </p>
          <p>
            <strong>To</strong>
            <br />
            {data.to_address.name}
            <br />
            {data.to_address.street1} {data.to_address.street2}
            <br />
            {data.to_address.city}
            <br />
            {data.to_address.zip} {data.to_address.country}
          </p>
          <p>
            <strong>Parcel</strong>
            <br />
            {data.parcel.length} x {data.parcel.width} x {data.parcel.height} (in)
            <br />
            {data.parcel.weight} (oz)
          </p>

          <Grid className="v-grid-column">
            <a target="_blank" rel="noopener noreferrer" href={data.tracker.public_url}>
              <Button className="v-button-white v-full-width v-margin-bottom-1">Track</Button>
            </a>
            <a target="_blank" rel="noopener noreferrer" href={data.postage_label.label_url}>
              <Button className="v-button-white v-full-width">Label</Button>
            </a>
          </Grid>
        </Card>
      </Grid1>
    </Grid>

    <Link to="/shipments/new">
      <Button>New Shipment</Button>
    </Link>
  </div>
);

export default Summary;
