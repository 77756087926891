const address = {
  street_name: '',
  city:        '',
  state:       '',
  zip:         '',
};

const initialState = {
  contact_address: { ...address },
  billing_address: { ...address },
};

export const addresses = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADDRESSES':
      return action.payload;
    default:
      return state;
  }
};
