const initialState = {
  invoices: [],
  totals:   {},
  filters:  {},
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_INVOICES': {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
