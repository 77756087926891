import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../helpers/classList';

const MenuComponent = ({
  renderToggler,
  className,
  side,
  label,
  value,
  disabled,
  items,
  menuName,
  stateValue,
  toggleOpenClose,
  dispatch,
  ...rest
}) => {
  const currentOpenSelect = stateValue['v-current-open-list'];
  const isOpen            = currentOpenSelect && currentOpenSelect.value === menuName;
  const toggleOpenStatus  = () => !disabled && toggleOpenClose(isOpen ? '' : menuName);
  const menuItemSelected  = (label) => toggleOpenClose('');
  const list              = !disabled &&
    items.map((item, key) => {
      if (item) {
        const localProps = {
          key,
          menuItemSelected,
          ...item,
        };
        return <Option {...localProps} />;
      }
      return <Separator key={key} />;
    });
  return (
    <div onMouseUp={(e) => e.stopPropagation()} className={classList('v-menu', isOpen && 'v-open')}>
      {label && <label className="v-text-label">{label}</label>}
      {renderToggler({ onClick: toggleOpenStatus })}
      <div className={classList('v-menu-list-container', className)}>
        <div className={classList('v-menu-list', side && `v-menu-${side}`)}>{list}</div>
      </div>
    </div>
  );
};

const Separator = () => <div className="v-menu-separator" />;

const Option = ({ label, onClick, menuItemSelected }) => (
  <div
    className={classList('v-menu-option')}
    onClick={() => {
      menuItemSelected(label);
      onClick && onClick(label);
    }}
  >
    {label}
  </div>
);

const mapStateToProps = ({ stateValue }) => ({ stateValue });

const mapDispatchToProps = (dispatch) => {
  const toggleOpenClose = (value) =>
    dispatch({
      type:    'SELECTION_STATE',
      payload: { key: 'v-current-open-list', value },
    });
  return { dispatch, toggleOpenClose };
};

const Menu = connect(mapStateToProps, mapDispatchToProps)(MenuComponent);

export { Menu };
