import React from 'react';
import { startCase } from 'lodash';
import { IconDownload } from '../../helpers/icons';
import { Button } from '../../components/Button';
import { formatDate } from '../../helpers/data';

export default function tableConfig() {
  return [
    {
      title:     'Сreated',
      field:     'created_at',
      cell:      (data) => formatDate(data),
      className: 'v-center',
      style:     { maxWidth: '15rem' },
    },
    {
      title:     'Start Date',
      field:     'start_date',
      cell:      (data) => formatDate(data),
      className: 'v-center',
      style:     { maxWidth: '15rem' },
    },
    {
      title:     'End Date',
      field:     'end_date',
      cell:      (data) => formatDate(data),
      className: 'v-center',
      style:     { maxWidth: '15rem' },
    },
    {
      title:     'Type',
      field:     'humanized_type',
      className: 'v-center',
      cell:      (data) => startCase(data),
      style:     { maxWidth: '15rem' },
    },
    {
      title:     'Filename',
      field:     'filename',
      className: 'v-center',
      cell:      (data) => <span title={data}>{data}</span>,
    },
    {
      title:     'Actions',
      field:     'download_url',
      className: 'v-center',
      style:     { maxWidth: '15rem' },
      cell:      (data, _row, _col, object) =>
        object.filename ? (
          <a href={data}>
            <Button white small icon={IconDownload}>
              Download
            </Button>
          </a>
        ) : (
          <Button disabled small>
            Generating
          </Button>
        ),
    },
  ];
}
