import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { App } from './App';
import { setupEscAndKeyListener } from './helpers/escape';

Sentry.init({
  dsn:              process.env.REACT_APP_SENTRY_DSN,
  integrations:     [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

setupEscAndKeyListener();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
