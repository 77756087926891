import React, { useState } from 'react';

const getFormattedDate = (date) => {
  const [month, day, year] = date.toLocaleDateString('en-US').split('/');
  return `${[month, day].map((p) => `0${p}`.substr(-2, 2)).join('/')}/${year}`;
};

export const DateSelect = ({ date: initialDate, onFinish }) => {
  const [date, setDate] = useState(getFormattedDate(initialDate));
  const onFocus         = ({ target }) => {
    setDate('__/__/20__');
    setTimeout(() => {
      target.selectionStart = 0;
      target.selectionEnd   = 1;
    }, 20);
  };

  const onChange = ({ target }) => {
    let { selectionStart } = target;
    const { value }        = target;
    const pattern          = [/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', '2', '0', /\d/, /\d/];
    const val              = pattern
      .map((p, index) => {
        if (typeof p === 'string') return p;
        return p.test(value[index]) ? value[index] : '_';
      })
      .join('');
    setDate(val);
    while (typeof pattern[selectionStart] === 'string') selectionStart++;
    setTimeout(() => {
      target.selectionStart = selectionStart;
      target.selectionEnd   = selectionStart + 1;
    }, 20);
  };

  const onKeyDown = (event) => (event.key === 'Enter' || event.keyCode === 13) && onFinishEdit(event);

  const onFinishEdit = ({ target }) => {
    const ts = Date.parse(date);
    ts ? onFinish(new Date(ts)) : setDate(getFormattedDate(initialDate));
    target.blur();
  };

  return (
    <input
      value={date}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onFinishEdit}
      onKeyDown={onKeyDown}
      name="from"
      className="v-calendar-range"
    />
  );
};
