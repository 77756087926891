import React from 'react';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Hr } from '../../components/Hr';
import { Select } from '../../components/Select';
import { Textbox } from '../../components/Textbox';
import { closeModal } from '../../helpers/modal';
import { ShopConnectionsResource } from '../../resources';
import { Grid, Grid1, Grid2, Grid4 } from '../../components/Grids';
import { Checkbox } from '../../components/Checkbox';
import { store } from '../../store';
import { queryVanlo } from '../../resources/query';

const capitalize = (string) => {
  if (!string) return '';
  return `${string[0].toUpperCase()}${string.substr(1, string.length)}`;
};

const titleize = (string) => {
  return string
    .replace('_', ' ')
    .split(' ')
    .map((s) => capitalize(s))
    .join(' ');
};

const credentialList = {
  shopify:     [{ name: 'shop', placeholder: 'yourshop.myshopify.com' }],
  woocommerce: [{ name: 'domain', placeholder: 'yourdomain.com' }],
  bigcommerce: [{ name: 'shop', placeholder: 'yourshop.mybigcommerce.com' }],
  ebay:        [],
  shipstation: [{ name: 'key' }, { name: 'secret' }],
};

export class NewShopConnectionModal extends React.Component {
  state = {
    shop:        'shopify',
    credentials: {},
    ...this.props.shopConnection,
  };

  change({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  changeCredentials({ target: { name, value } }) {
    const { credentials } = this.state;
    this.setState({ credentials: { ...credentials, [name]: value } });
  }

  save() {
    const { shop, credentials } = this.state;
    if (shop === 'shipstation')
      return ShopConnectionsResource.create({ shop, credentials });

    const { url } = store.getState().server;
    const qParams = Object.entries(credentials).reduce((prev, current) => `${prev}${current[0]}=${current[1]}&`, '');

    window.location.href = `${url}/auth/${shop}?${qParams}`;
  }

  render() {
    const { shop, id, credentials } = this.state;
    const change                    = this.change.bind(this);
    const isValid                   = credentialList[shop]?.every(({ name }) => credentials[name]);
    return (
      <div className={classList('v-modal-form', 'v-center-column v-center-left')}>
        <Grid>
          <Grid4>
            <Select
              labelLeft="Shop:"
              styleLabelLeft={{ width: '7rem' }}
              name="shop"
              className="v-select-small-no-border v-width-20"
              classOpenOptions="v-fixed v-width-30"
              items={[
                { value: 'shopify', label: 'Shopify' },
                { value: 'woocommerce', label: 'WooCommerce' },
                { value: 'bigcommerce', label: 'BigCommerce' },
                { value: 'ebay', label: 'eBay' },
                { value: 'shipstation', label: 'ShipStation' },
              ]}
              value={shop}
              disabled={!!id}
              onChange={change}
            />
          </Grid4>
        </Grid>
        <br />
        {credentialList[shop]?.map(({ name, placeholder }) => (
          <Grid key={name}>
            <Grid1>
              <Textbox
                labelLeft={titleize(name)}
                styleLabelLeft={{ width: '7rem' }}
                name={name}
                placeholder={placeholder}
                className="v-select-small-no-border"
                value={credentials[name]}
                onChange={this.changeCredentials.bind(this)}
              />
            </Grid1>
          </Grid>
        ))}
        <Hr className="v-hr-2" />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button disabled={!isValid} label={id ? 'Update' : 'Create'} onClick={this.save.bind(this)} />
        </div>
      </div>
    );
  }
}
