import React from 'react';
import { IconDownload } from '../../helpers/icons';
import { formatDate } from '../../helpers/data';

export default function tableConfig() {
  return [
    {
      title: 'username',
      field: 'username',
      style: { minWidth: '16rem' },
      cell:  (value) => value,
    },
    {
      title: 'Date',
      field: 'search_date',
      style: { minWidth: '11rem', maxWidth: '11rem' },
      cell:  (value) => formatDate(value),
    },
    {
      title: 'Status',
      field: 'status',
      style: { minWidth: '12rem', maxWidth: '12rem' },
      cell:  (value) => value,
    },
    {
      title: 'Leads',
      field: 'leads_count',
      cell:  (value) => value,
    },
    {
      title: 'CSV',
      field: 'leads_csv_url',
      style: { minWidth: '5rem', maxWidth: '12rem' },
      cell:  (value, x, y, data) => {
        if (data.error) return `Error: ${data.error}`;
        if (!value) return '';

        return (
          <a href={value}>
            <IconDownload className="v-icon-button" />
          </a>
        );
      },
    },
  ];
}
