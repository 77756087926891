import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { IconExportDoc } from '../../helpers/icons';
import { BalanceAmount } from './BalanceAmount';
import { RechargePanel } from './RechargePanel';
import { Pagination } from '../../components/Pagination';
import { Grid, Grid1, Grid2 } from '../../components/Grids';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { TableFiltersComponent } from '../../components/TableFiltersComponent';
import { Textbox } from '../../components/Textbox';
import { BalanceTransactionResource } from '../../resources';
import tableConfig, { getDetailView } from './config';
import { showErrorMessage, showMessage, showModal } from '../../helpers/modal';
import PaymentLogModal from './PaymentLogModal';
import DisputeModal from './DisputeModal';

import { Title } from '../../components/Title';

class Balances extends React.Component {
  state = { ...BalanceTransactionResource.filters };

  exportCSV() {
    BalanceTransactionResource.exportCSV(this.state.reportsEmail).then(({ success, message }) => {
      if (success) {
        showMessage(<div className="v-body-content">{message}</div>, null, true);
      } else {
        showErrorMessage(message);
      }
    });
  }

  setPage(page) {
    if (page !== this.state.page) {
      this.setState({ page });
      BalanceTransactionResource.setFilter('page', page);
      BalanceTransactionResource.list();
    }
  }

  showPaymentLog(data) {
    showModal({
      title:     `${data.id}`,
      component: PaymentLogModal,
      payload:   { data: getDetailView(data) },
    });
  }

  emailChanged(event) {
    this.setState({ reportsEmail: event.target.value });
  }

  submit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.exportCSV();
    }
  }

  showDisputeModal = (data) => () => {
    showModal({
      title:     'Submit Dispute',
      component: DisputeModal,
      payload:   data,
    });
  };

  render() {
    const { page, per } = this.state;
    const {
      user,
      userSettings,
      balance: { balance },
      transactions: { transactions, total_count, options },
    } = this.props;

    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title title="Balance" />
        <Grid>
          <Grid1 className="v-margin-right-3rem">
            <BalanceAmount balance={balance} />
          </Grid1>
          <Grid2>
            <RechargePanel userSettings={userSettings} />
          </Grid2>
        </Grid>
        <div className="v-hr v-hr-2" />

        <Grid className="v-center-left">
          <Grid1 className="v-center-left">
            <div className="v-title">Payment Logs</div>
            {/* Filter: &nbsp; <Textbox name="searchBox" onKeyPress={e => (e.key === 'Enter') && setSearchFilter(search)} value={search} onChange={onChangeSearch} placeholder='Search by order number, tracking code, or address' className='v-search-box v-paddig-right-1' />
                {search && <IconClose onClick={clearFilter} className='v-clear-search-box-icon v-clickable' />} */}
          </Grid1>
          <Grid1 className="v-center-right">
            <form autoComplete="off">
              <Textbox
                type="email"
                placeholder={user.email}
                className="v-email-override"
                style={{ border: '1px solid #aaa', borderRadius: '4px' }}
                onChange={this.emailChanged.bind(this)}
                onKeyDown={this.submit.bind(this)}
                value={this.state.reportsEmail}
              />
            </form>
            <Button
              disabled={!total_count}
              onClick={this.exportCSV.bind(this)}
              icon={IconExportDoc}
              white
              small
              className="v-padding-left-1"
            >
              Export CSV
            </Button>
          </Grid1>
        </Grid>

        <TableFiltersComponent
          display="filters dates per"
          resource={BalanceTransactionResource}
          onChange={(p) => this.setState({ ...p })}
          filters={[{ name: 'kind', label: 'Type', options: options.kinds }]}
        />
        <div>
          <Table
            noScroll
            data={transactions}
            onClickRow={this.showPaymentLog.bind(this)}
            context={this}
            config={tableConfig.call(this)}
            tableClass="v-table-payment-log"
          />
        </div>
        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage.bind(this)} />
      </div>
    );
  }
}

export default connect(({ balance, transactions, user, userSettings }) => ({
  balance,
  transactions,
  user,
  userSettings,
}))(Balances);
