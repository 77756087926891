import React from 'react';
import { classList } from '../../helpers/classList';

export class StripeAgreement extends React.Component {
  render() {
    return (
      <div
        className={classList('v-modal-form', 'v-center-column v-center-left')}
        style={{
          fontSize:   '1.5rem',
          width:      '50vw',
          fontFamily: 'Camphor, "Open Sans", "Segoe UI", sans-serif',
          lineHeight: '2.3rem',
          padding:    '2.1rem',
        }}
      >
        <span>
          Payment processing services for customers on Vanlo are provided by Stripe and are subject to the&nbsp;
          <a href="https://stripe.com/connect-account/legal" rel="noopener noreferrer" target="_blank">
            Stripe Connected Account Agreement
          </a>
          , which includes the&nbsp;
          <a href="https://stripe.com/legal" rel="noopener noreferrer" target="_blank">
            Stripe Terms of Service
          </a>
          &nbsp;(collectively, the &ldquo;Stripe Services Agreement&rdquo;). By agreeing to these terms or continuing to
          operate as a customer on Vanlo, you agree to be bound by the Stripe Services Agreement, as the same may be
          modified by Stripe from time to time. As a condition of Vanlo enabling payment processing services through
          Stripe, you agree to provide Vanlo accurate and complete information about you and your business, and you
          authorize Vanlo to share it and transaction information related to your use of the payment processing services
          provided by Stripe.
        </span>
      </div>
    );
  }
}
