import { locations } from './locations';
import { paymentMethods } from './paymentMethods';
import { shipments } from './shipments';
import readyShipments from './readyShipments';
import { warehouseAddresses } from './warehouseAddresses';
import { balance } from './balanceValue';
import { userSettings } from './userSettings';
import { userBillingInformation } from './userBillingInformation';
import { externalBalance } from './externalBalance';
import { modal } from './modal';
import { externalTransactions } from './externalTransactions';
import { transactions } from './transactions';
import { webhooks } from './webhooks';
import { carrierAccounts } from './carrierAccounts';
import { apiKeys } from './apiKeys';
import { session } from './session';
import { server } from './server';
import { user } from './user';
import { addresses } from './addresses';
import { businessInformation } from './businessInformation';
import { persons } from './persons';
import invoices from './invoices';
import { adjustments } from './adjustments';
import { reports } from './reports';
import reportSchedules from './reportSchedules';
import { trackingPage } from './trackingPage';
import { packingSlipDesign } from './packingSlipDesign';
import { scanForms } from './scanForms';
import { userSummaries } from './userSummaries';
import { shipmentUploadMappings } from './shipmentUploadMappings';
import { shipmentUploads } from './shipmentUploads';
import { leadSearches } from './leadSearches';
import { shopConnections } from './shopConnections';
import shopOrders from './shopOrders';
import automations from './automations';
import searchPresets from './searchPresets';

export default {
  addresses,
  locations,
  paymentMethods,
  shipments,
  readyShipments,
  transactions,
  externalTransactions,
  warehouseAddresses,
  balance,
  userSettings,
  modal,
  userBillingInformation,
  externalBalance,
  webhooks,
  carrierAccounts,
  businessInformation,
  apiKeys,
  session,
  server,
  user,
  persons,
  invoices,
  adjustments,
  reports,
  reportSchedules,
  trackingPage,
  packingSlipDesign,
  scanForms,
  userSummaries,
  shipmentUploadMappings,
  shipmentUploads,
  leadSearches,
  shopConnections,
  shopOrders,
  automations,
  searchPresets,
};
