import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { IconCreditCard, IconBank, IconAddNew } from '../../helpers/icons';
import { Grid, Grid1 } from '../../components/Grids';
import { BankCard } from './BankCard';
import { BankAccount } from './BankAccount';
import { Hr } from '../../components/Hr';
import { showModal } from '../../helpers/modal';
import { Stripe } from './stripe/Stripe';
import { PlaidCheckoutForm } from './plait/PlaidCheckoutForm';
import { Title } from '../../components/Title';

const addBankCard = () => {
  showModal({
    title:     'Add Card',
    component: Stripe,
  });
};

class PaymentMethods extends React.Component {
  state = {};

  setProcessing() {
    this.setState({ processing: !this.state.processing });
  }

  render() {
    const {
      bank_cards,
      bank_accounts,
      balance: { balance },
    } = this.props;

    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title title="Payment Methods" subtitle="Add/Remove your billing details." />
        <Grid>
          <Grid1 className="v-padding-right-3 v-no-grid">
            <Card title="CREDIT OR DEBIT CARDS" icon={IconCreditCard}>
              {bank_cards.map((card) => (
                <React.Fragment key={card.id}>
                  <BankCard
                    card={card}
                    processing={this.state.processing}
                    setProcessing={this.setProcessing.bind(this)}
                  />
                  <Hr h1 className="v-margin-bottom-2" />
                </React.Fragment>
              ))}
              <div className="v-padding-2 v-center">
                <Button round white label="ADD NEW CARD" icon={IconAddNew} onClick={addBankCard} />
              </div>
            </Card>
          </Grid1>

          <Grid1 className="v-no-grid">
            <Card title="BANK ACCOUNTS" icon={IconBank}>
              {bank_accounts.map((account) => (
                <React.Fragment key={account.id}>
                  <BankAccount
                    processing={this.state.processing}
                    setProcessing={this.setProcessing.bind(this)}
                    account={account}
                  />
                  <Hr h1 className="v-margin-bottom-2" />
                </React.Fragment>
              ))}
              <div className="v-center v-subtitle">
                Credit Limit: {balance.pending_credit} of {balance.credit_limit} used
              </div>
              <PlaidCheckoutForm />
            </Card>
          </Grid1>
        </Grid>
      </div>
    );
  }
}

export default connect(({ paymentMethods: { bank_cards, bank_accounts }, userSettings, balance }) => {
  return { bank_cards, bank_accounts, userSettings, balance };
})(PaymentMethods);
