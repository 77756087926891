import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { classList } from '../helpers/classList';
import { IconCheck } from '../helpers/icons';

const CheckComponent = ({ label, style, labelStyle, checked, disabled, name, onToggleCheckbox, ...rest }) => {
  const isChecked  = checked || (rest.input && rest.input.value);
  const checkName  = name || (rest.input && rest.input.name);
  const formName   = rest.meta && rest.meta.form;
  const onChange   = (rest.input && rest.input.onChange) || rest.onChange;
  const isDisabled = !!((rest.input && rest.input.disabled) || disabled);

  return (
    <div className="v-checkbox" style={style} title={rest.title}>
      <span
        className={classList('v-checkbox-wrapper', isDisabled && 'v-checkbox-disabled')}
        onClick={(e) => {
          e.stopPropagation();
          if (!isDisabled) {
            onChange && onChange({ target: { name: checkName, value: !isChecked } });
            onToggleCheckbox(formName, checkName, !isChecked);
          }
        }}
      >
        <span className="v-checkbox-box" />
        <IconCheck className={classList('v-checkbox-tick', !isChecked && 'v-checked')} />
        {label && (
          <span className="v-checkbox-label" style={labelStyle}>
            {label}
          </span>
        )}
      </span>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  const onToggleCheckbox = (formName, name, value) => dispatch(change(formName, name, !!value));
  return { dispatch, onToggleCheckbox };
};

export const Checkbox = connect(null, mapDispatchToProps)(CheckComponent);
