import React from 'react';
import { classList } from '../helpers/classList';

const Column = ({ cells, vertical, className, children, ...rest }) => (
  <div className={classList(`v-grid-${cells}`, className, vertical && 'v-grid-column')} {...rest}>
    {children}
  </div>
);

const Grid = ({ className, children, ...rest }) => (
  <div className={classList('v-grids', className)} {...rest}>
    {children}
  </div>
);

const Grid1 = (props) => <Column {...props} cells={1} />;
const Grid2 = (props) => <Column {...props} cells={2} />;
const Grid3 = (props) => <Column {...props} cells={3} />;
const Grid4 = (props) => <Column {...props} cells={4} />;
const Grid5 = (props) => <Column {...props} cells={5} />;
const Grid6 = (props) => <Column {...props} cells={6} />;

export { Grid, Grid1, Grid2, Grid3, Grid4, Grid5, Grid6 };
