import React from 'react';
import { classList } from '../../helpers/classList';
import { Card } from '../../components/Card';
import { IconTest } from '../../helpers/icons';
import { PasswordBox } from '../../components/PasswordBox';
import { Button } from '../../components/Button';
import { PasswordResource } from '../../resources';
import { showTextMessage } from '../../helpers/modal';
import { Title } from '../../components/Title';

const validateResetPasswordForm = (values) => {
  const { current_password, password, password_confirmation } = values;
  if ((current_password || '').length < 8) {
    return 'Current password should be at least 8 characters';
  }
  if ((password || '').length < 8) {
    return 'New password should be at least 8 characters';
  }
  if (!(password && password_confirmation) || password !== password_confirmation) {
    return 'Password and repeat password should match';
  }
  return null;
};

export default class ResetPassword extends React.Component {
  state = { values: {} };

  submit() {
    const error = validateResetPasswordForm(this.state.values);
    if (error) return this.setState({ error });
    this.setState({ busy: true });
    PasswordResource.update({ user: this.state.values }).then((r) => {
      showTextMessage(r.message);
      this.setState({ busy: false });
    });
  }

  change({ target: { name, value } }) {
    this.setState({ values: { ...this.state.values, [name]: value } });
  }

  render() {
    const { busy, values, error } = this.state;
    const change                  = this.change.bind(this);
    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title title="Reset Password" subtitle="Reset your password below." />
        <Card className="v-width-50" icon={IconTest}>
          <PasswordBox
            value={values.current_password}
            onChange={change}
            disabled={busy}
            name="current_password"
            type="password"
            label="Current password"
          />
          <PasswordBox
            value={values.password}
            onChange={change}
            disabled={busy}
            name="password"
            type="password"
            label="New password"
          />
          <PasswordBox
            value={values.password_confirmation}
            onChange={change}
            disabled={busy}
            name="password_confirmation"
            type="password"
            label="Repeat new password"
          />
          <ul
            className={classList(
              'v-no-margin v-no-padding v-padding-left-2 vv-padding-top-1',
              error && 'v-padding-bottom-2'
            )}
          >
            {error && (
              <li key={error} className="v-error-message">
                {error}
              </li>
            )}
          </ul>
          <Button label="Save changes" disabled={busy} onClick={this.submit.bind(this)} />
        </Card>
      </div>
    );
  }
}
