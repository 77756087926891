import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../../components/Button';
import { showModal } from '../../helpers/modal';
import { EditWarehouseContainer } from './EditWarehouseContainer';
import { Table } from '../../components/Table';
import { DeleteWarehouseContainer } from './DeleteWarehouseContainer';

const addNewWarehouse = () => {
  showModal({
    title:     'Add warehouse',
    component: EditWarehouseContainer,
  });
};

const onEditWarehouse = (warehouse) => () => {
  showModal({
    title:     'Edit warehouse',
    component: EditWarehouseContainer,
    payload:   { warehouse },
  });
};

const onDeleteWarehouse = (warehouse) => () => {
  showModal({
    title:     'Delete warehouse',
    component: DeleteWarehouseContainer,
    payload:   { warehouse },
  });
};

const tableConfig = [
  {
    title: 'Name',
    field: 'name',
    cell:  (data) => data,
  },
  {
    title: 'Address',
    field: 'fullAddress',
    cell:  (data) => data,
  },
  {
    title: 'Actions',
    field: '',
    cell:  (data, row, col, warehouse) => (
      <div>
        <Button label="Edit" small white onClick={onEditWarehouse(warehouse)} />
        <Button className="v-margin-left-1rem" label="Delete" small white onClick={onDeleteWarehouse(warehouse)} />
      </div>
    ),
  },
];

export const WarehouseComponent = ({ warehouseAddresses }) => (
  <>
    <div className="v-padding-bottom-2">
      <Button label="ADD NEW WAREHOUSE" onClick={addNewWarehouse} />
    </div>
    <Table data={warehouseAddresses} config={tableConfig} />
  </>
);

export const WarehouseContainer = connect(({ warehouseAddresses }) => ({
  warehouseAddresses,
}))(WarehouseComponent);
