import React from 'react';
import { classList } from '../helpers/classList';

export const Table = ({ data, title, noScroll, tableClass, trClass, context, config, onClickRow }) => {
  const headersRow =
    config && config.map((header, key) => <Th {...{ key, ...header, content: header.title }} onClick={() => true} />);
  const hasHeader  = config.find((c) => c.title);
  const rowClick   = onClickRow || (() => true);
  const dataRows   = !data || data.length === 0 ? (
      <EmptyTable />
    ) : (
      data.map((item, row) => {
        const tds = config.map((cellConfig, col) => (
          <Td
            {...{
              key:     col,
              ...cellConfig,
              rowData: { field: cellConfig.field, row, col, original: data },
              title:   cellConfig.flat?.call(context, item[cellConfig.field], row, col, item),
              content: cellConfig.cell?.call(context, item[cellConfig.field], row, col, item) ?? item[cellConfig.field],
              onClick: cellConfig.onClick || (() => rowClick(item)),
            }}
          />
        ));

        return (
          <Tr className={(trClass && trClass(item)) || ''} key={row}>
            {tds}
          </Tr>
        );
      })
    );

  return (
    <div className={classList('v-table', noScroll && 'v-table-auto-height', tableClass)}>
      {title && <div className="v-table-table">{title}</div>}
      <div className={classList('v-table-content')}>
        {hasHeader && <Tr>{headersRow}</Tr>}
        {dataRows}
      </div>
    </div>
  );
};

const Tr = ({ className, children }) => <div className={classList('v-table-tr', className)}>{children}</div>;

// const Th = ({className, headerProps, headerClass, ...rest}) => <Td {...rest} {...headerProps}
//                                                                    className={classList('v-table-th', className, headerClass)}/>

const Th = ({ style, className, noEllipse, content }) => (
  <div className={classList('v-table-th', className)} style={style}>
    <span className={classList(!noEllipse && 'v-ellipsed')}>{content}</span>
  </div>
);

const Td = ({ style, className, noEllipse, rowData, content, title, onClick }) => (
  <div
    onClick={(e) => onClick && onClick(rowData, e)}
    className={classList('v-table-td', className)}
    style={style}
    title={title || (typeof content === 'string' ? content : '')}
  >
    <span className={classList(!noEllipse && 'v-ellipsed')}>{content}</span>
  </div>
);

const EmptyTable = () => <div className="v-table-tr-empty">There is no data to show here!</div>;
