import { resource } from './resource';
import { queryVanlo } from './query';
import { readStorageData } from '../helpers/localStorage';

export const ShopOrdersResource = resource({
  name:      'ShopOrders',
  filters:    {
    per:     readStorageData('per') || 50,
    page:    1,
    shop_id: 'all',
    status:  'pending',
    sort_by: readStorageData('ShopOrders.sort_by') || 'externally_updated_at desc',
  },
});
