import { resource } from './resource';
import { getFormattedDate, getPastDays } from '../components/Calendar';

const route      = '/adjustments';
const storageKey = 'SET_ADJUSTMENTS';
const filters    = {
  q:         null,
  per:       50,
  kind:      'all',
  page:      1,
  date_from: getFormattedDate(getPastDays(7)),
  date_to:   getFormattedDate(getPastDays(-1)),
};

export const AdjustmentsResource = resource({
  route,
  storageKey,
  filters,
});
