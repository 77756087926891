import { resource } from './resource';
import { getFormattedDate, getPastDays } from '../components/Calendar';

const route      = '/analytics';
const storageKey = 'SET_USER_SUMMARIES';
const filters    = {
  per:       50,
  page:      1,
  date_from: getFormattedDate(getPastDays(7)),
  date_to:   getFormattedDate(getPastDays(-1)),
};

export const UserSummariesResource = resource({
  route,
  storageKey,
  filters,
});
