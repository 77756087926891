import React, { useState } from 'react';
import { Textbox } from './Textbox';
import { IconEyeOpen, IconEyeClose } from '../helpers/icons';

const PasswordBox = (props) => {
  const [showPassword, setShowPassword] = useState('');
  const Icon                            = showPassword ? IconEyeOpen : IconEyeClose;
  const name                            = (props.input && props.input.name) || props.name;
  const type                            = !showPassword && 'password';
  const hidePlaceholder                 = true;
  const icon                            = () => <Icon onClick={() => setShowPassword(!showPassword)} className="v-passowrd-icon v-icon-24" />;
  const localProps                      = {
    ...props,
    type,
    icon,
    name,
    hidePlaceholder,
  };
  return <Textbox {...localProps} />;
};

export { PasswordBox };
