import React from 'react';
import { classList } from '../../helpers/classList';
import { IconRefund } from '../../helpers/icons';
import { showModal } from '../../helpers/modal';
import { AddFundModal } from './AddFundModal';
import { formatCurrency } from '../../helpers/data';

import { Card } from '../../components/Card';
import { Button } from '../../components/Button';

const showAddFundModal = () =>
  showModal({
    title:     'Add funds',
    component: AddFundModal,
  });

export const BalanceAmount = ({ balance }) => {
  const formatted = formatCurrency(balance.available_value);
  return (
    <Card className="v-current-balance">
      <div className={classList('v-current-balance-lines')} />
      <span className={classList('v-current-balance-label')}>Current Balance</span>
      <div
        className={classList('v-current-balance-amount')}
        title={`${formatCurrency(balance.value)} + ${formatCurrency(
          balance.available_pending_credit_value
        )} Pending credits`}
      >
        <span className={classList('v-current-balance-amount-sign')}>{formatted[0].replace('$', '')}$</span>
        {formatted.replace(/^[^$]*\$/, '')}
      </div>
      <Button onClick={showAddFundModal} label="Add Funds" icon={IconRefund} round white />
    </Card>
  );
};
