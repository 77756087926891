import { createStore, combineReducers } from 'redux';
import reducers from './reducers';

/* eslint-disable no-underscore-dangle */
export const store = createStore(
  combineReducers(reducers),
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: true, trace: true, traceLimit: 25 })
);
/* eslint-enable */
