import React, { useState } from 'react';
import { Route } from 'react-router-dom';

export const LoadRoute = ({ onLoad, resources, component: Component, activeRoute, children, ...props }) => {
  const [route, setRoute] = useState(null);

  return (
    <Route
      {...props}
      render={() => {
        // This code won't be executed unless the Route is rendered the first time
        if (route !== activeRoute) {
          setTimeout(() => setRoute(activeRoute), 150);
          if (resources) resources.forEach((r) => r.list());
        }
        return <Component />;
      }}
    />
  );
};
