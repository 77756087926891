import React from 'react';
import { minBy } from 'lodash';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';
import { IconShopify, IconWoo, IconBigcommerce, IconEbay, IconShipstation } from '../../helpers/icons';

export const iconKey = {
  Shopify:     IconShopify,
  WooCommerce: IconWoo,
  BigCommerce: IconBigcommerce,
	Ebay:        IconEbay,
	ShipStation: IconShipstation,
};

export function tableConfig() {
  return [
    {
      title:     <Checkbox checked={this.state.selectedShipmentIds.length > 0} onChange={this.onCheckAll} />,
      className: 'v-table-checkbox',
      cell:      (...[, , , shipment]) => (
        <Checkbox
          checked={this.state.selectedShipmentIds.includes(shipment.id)}
          onChange={this.onCheck(shipment.id)}
          disabled={!shipment.editable || !shipment.id || this.isPurchased(shipment)}
        />
      ),
    },
    {
      title: 'Order',
      field: 'order',
      cell:  (o) => {
        const Icon = iconKey[o.shop];
        return (
          <div style={{ display: 'flex', gap: '1rem' }}>
            <div title={o.shop_name}>{Icon && <Icon width="30px" height="30px" />}</div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              {o.name}
              <span style={{ color: '#777777', fontSize: '1.2rem', lineHeight: 1.25 }}>
                ${o.value} on {o.date}
              </span>
            </div>
          </div>
        )
      },
      flat:  (o) => `${o.name} - $${o.value} on ${o.date}`,
      style: { minWidth: '14rem' },
    },
    {
      title:     'To',
      field:     'to_address',
      className: 'to',
      cell:      (to_address, r, c, shipment) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          {shipment.to_name}
          <span style={{ color: '#777777', fontSize: '1.2rem', lineHeight: 1.25 }}>{to_address}</span>
        </div>
      ),
      flat:  (to_address, r, c, shipment) => `${shipment.to_name}\n${to_address}`,
      style: { minWidth: '35rem' },
    },
    {
      title:     'Rate / Status',
      field:     'rates',
      className: 'rates',
      onClick:   () => false,
      style:     { minWidth: '35rem', maxWidth: 'auto' }, // 35rem is FCIPS
      cell:      (...[rates, , , shipment]) => {
        if (this.isPurchased(shipment) || /ready|expired/.test(shipment.label_status)) {
          return <div style={{ color: '#777777' }}>Label is ready</div>;
        }
        if (shipment.label_status === 'error') {
          return <div style={{ color: '#777777' }}>{shipment.label_error}</div>;
        }
        return (
          <Select
            value={this.selectedRatedId(shipment)}
            items={rates.map((rate) => ({
              label: `${rate.service} — ${rate.rate}`,
              value: rate.public_id,
            }))}
            onChange={this.changeRate(shipment.id)}
            style={{ minWidth: '20rem' }}
          />
        );
      },
      flat: (_, __, ___, shipment) => shipment.label_error || '',
    },
    {
      title:     'Actions',
      className: 'actions',
      style:     { minWidth: '15rem', justifyContent: 'center' },
      cell:      (...[, , , shipment]) =>
        shipment.editable && (
          <div className="v-center">
            <Button small white onClick={this.editShipment(shipment)} disabled={this.isPurchased(shipment)}>
              Edit
            </Button>
            &nbsp;
            <Button small white onClick={this.buyLabel(shipment)} disabled={this.isPurchased(shipment)}>
              Buy Label
            </Button>
          </div>
        ),
    },
  ];
}
