import React from 'react';
import { minBy } from 'lodash';

import { connect } from 'react-redux';
import { Title, Table, Button, Pagination, TableFiltersComponent, Grid, Grid1, Grid2 } from '../../components';
import { ShipmentResource, ReadyShipmentsResource } from '../../resources';
import { showErrorMessage } from '../../helpers/modal';
import { tableConfig } from './tableConfig';
import { _Shipments } from '../Shipments';

class BuyLabels extends _Shipments {
  state = {
    selectedShipmentIds:  [],
    purchasedShipmentIds: [],
    selectedRates:        {},
    ...ShipmentResource.filters,
  };

  onCheck = (shipmentId) => () => {
    const { selectedShipmentIds } = this.state;

    if (selectedShipmentIds.includes(shipmentId)) {
      selectedShipmentIds.splice(selectedShipmentIds.indexOf(shipmentId), 1);
    } else {
      selectedShipmentIds.push(shipmentId);
    }
    this.setState({ selectedShipmentIds: [...selectedShipmentIds] });
  };

  onCheckAll = () => {
    if (this.state.selectedShipmentIds.length) {
      this.setState({ selectedShipmentIds: [] });
    } else {
      this.setState({
        selectedShipmentIds: this.props.readyShipments.shipments
          .map((s) => s.id)
          .filter((id) => this.state.purchasedShipmentIds.indexOf(id) === -1),
      });
    }
  };

  changeRate = (shipmentId) => (event) => {
    this.setState({
      selectedRates: {
        ...this.state.selectedRates,
        [shipmentId]: event.target.value,
      },
    });
  };

  buyLabel = (shipment) => () => {
    this.setState({ loadingShipmentId: shipment.id });

    ShipmentResource.buy(shipment.public_id, this.selectedRatedId(shipment)).then((response) => {
      this.setState({ loadingShipmentId: null });
      if (response.tracking_code) {
        this.setState({
          purchasedShipmentIds: [...this.state.purchasedShipmentIds, shipment.id],
          selectedShipmentIds:  [this.state.selectedShipmentIds.filter((s) => s !== shipment.id)],
        });
      } else {
        showErrorMessage(response.message || 'Something went wrong');
      }
    });
  };

  buyLabels = () => {
    this.state.selectedShipmentIds.forEach((id) => {
      const shipment = this.props.readyShipments.shipments.find((shipment) => {
        return shipment.id === id;
      });

      this.buyLabel(shipment)();
    });
  };

  shipmentRowClass = (shipment) => {
    if (shipment.id === this.state.loadingShipmentId) {
      return 'shipment-loading';
    }

    if (this.state.purchasedShipmentIds.indexOf(shipment.id) !== -1) {
      return 'shipment-purchased';
    }
  };

  selectedRatedId = (shipment) => {
    return (
      this.state.selectedRates[shipment.id] ||
      (shipment.service
        ? shipment.rates.find((rate) => rate.service === shipment.service)
        : minBy(shipment.rates, (rate) => Number(rate.rate))
      )?.public_id
    );
  };

  setPage = (page) => {
    if (page !== this.state.page) {
      this.setState({ page });
      ReadyShipmentsResource.setFilter('page', page);
      ReadyShipmentsResource.list();
    }
  };

  render() {
    const { page, per }                       = this.state;
    const { shipments, total_count, options } = this.props.readyShipments;

    return (
      <div className="v-awaiting-shipments v-page">
        <Title title="CSV Upload Labels" />
        <Grid className="v-center-left">
          <Grid2 className="v-center-right">
            <TableFiltersComponent
              display="filters search"
              resource={ReadyShipmentsResource}
              filters={[{ name: 'upload_id', label: 'CSV Upload', options: options.uploads }]}
              onChange={(p) => this.setState({ ...p })}
              searchPlaceholder="Search by shipment ID or reference"
            />
          </Grid2>
          <Grid1 className="v-center-right">
            <Button white onClick={this.buyLabels}>
              Buy Selected Labels
            </Button>
          </Grid1>
        </Grid>

        <Table
          noScroll
          data={shipments}
          context={this}
          config={tableConfig.call(this)}
          onClickRow={this.showShipment.bind(this)}
          tableClass="v-table-buy-labels"
          trClass={this.shipmentRowClass}
        />
        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage} />
      </div>
    );
  }
}

export default connect(({ readyShipments }) => ({ readyShipments }))(BuyLabels);
