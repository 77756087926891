import React from 'react';
import { classList } from '../helpers/classList';

const Card = ({ title, icon: CardIcon, className, bodyClass, children, ...rest }) => (
  <div className={classList('v-card', className)} {...rest}>
    {title && (
      <div className="v-card-title v-center">
        {CardIcon && <CardIcon className="v-icon-22 v-margin-right-1rem" />}
        {title}
      </div>
    )}
    <div className={classList('v-card-body', bodyClass)}>{children}</div>
  </div>
);

export { Card };
