import { camelizeKeys } from 'humps';

export default (state = { rules: [] }, action) => {
  switch (action.type) {
    case 'SET_AUTOMATIONS':
      return camelizeKeys(action.payload);
    default:
      return state;
  }
};
