import React from 'react';
import { Button } from '../../components/Button';
import { Toggle } from '../../components/Toggle';

export function endpointsTableConfig() {
  return [
    {
      title: 'ID',
      field: 'id',
      style: { maxWidth: '6rem' },
    },
    {
      title: 'URL',
      field: 'url',
    },
    {
      title:     'Version',
      field:     'api',
      className: 'v-center',
      style:     { maxWidth: '12rem' },
      cell:      (data) => Api[data],
    },
    {
      title:     'Event Type',
      field:     'event_type',
      className: 'v-center',
      style:     { maxWidth: '16rem' },
    },
    {
      title:     'Status',
      field:     'active',
      className: 'v-center',
      style:     { maxWidth: '10rem' },
      cell:      (data, row, col, all) => (
        <Toggle
          disabled={false}
          name="test-toggle"
          status={data}
          onClick={this.toggleWebhookActivity.bind(this, all.id, !data)}
        />
      ),
    },
    {
      title:     'Actions',
      field:     'actions',
      className: 'v-center',
      style:     { maxWidth: '24rem' },
      cell:      (data, row, col, all) => (
        <>
          <Button label="Delete" small white onClick={this.deleteWebhook.bind(this, all.id)} />
          &nbsp;
          <Button label="Test Me" small white onClick={this.sendSample.bind(this, all.id)} />
        </>
      ),
    },
  ];
}

export function eventsTableConfig() {
  return [
    {
      title: 'ID',
      field: 'public_id',
      style: { maxWidth: '30rem' },
    },
    {
      title: 'Type',
      field: 'name',
      style: { maxWidth: '20rem' },
    },
    {
      title: 'Status',
      field: 'status',
      style: { maxWidth: '10rem' },
    },
    {
      title: 'Event / Push Time (UTC)',
      field: 'created_at',
      style: { maxWidth: '17rem' },
      cell:  (created_at, r, c, event) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          {created_at}
          <span style={{ color: '#777777', fontSize: '1.2rem', lineHeight: 1.25 }}>{event.dispatched_at}</span>
        </div>
      ),
      flat:  (created_at, r, c, event) => `${created_at}\n${event.dispatched_at}`,
    },
    {
      title:     'Responses',
      field:     'responses',
      style:     { height: 'auto', padding: '0 1rem' },
      cell:      (list) => list.map((r) => (
        <div>
          {this.isOnlyUrl(r.url) ? '' : (
            <span style={{ fontWeight: 600 }}>{r.endpoint_id  ? `#${r.endpoint_id}` : r.url}:&nbsp;</span>
          )}
          {r.status}{r.failures.length ? `, failures: ${r.failures.join(', ')}` : ''}
        </div>
      )),
    },
  ];
}

export const getDetailView = (data) => {
  return {
    Id:           data.id,
    Active:       data.active ? 'YES' : 'NO',
    URL:          data.url,
    Version:      data.api,
    'Event Type': data.event_type,
  };
};

const eventsListKeys = {
  batch:               'Batch',
  rates:               'Rates',
  tracking:            'Tracking',
  '*':                 '*',
  transaction_created: 'Transaction Created',
  transaction_updated: 'Transaction Updated',
};

export const Api = {
  ship_engine: 'Ship Engine',
  shippo:      'Shippo',
  v1:          'V1',
};

export const eventsList = {
  ship_engine: [
    { value: 'batch', label: 'Batch' },
    { value: 'rates', label: 'Rates' },
    { value: 'tracking', label: 'Tracking' },
  ],
  shippo: [
    { value: '*', label: '*' },
    { value: 'transaction_created', label: 'Transaction Created' },
    { value: 'transaction_updated', label: 'Transaction Updated' },
  ],
  v1: [],
};
