import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../helpers/classList';
import { IconClose } from '../helpers/icons';
import { Button } from './Button';
import { closeModal } from '../helpers/modal';

const ModalComponent = ({ title, payload, Comp, closeButton, isVisible, extra, callback }) => {
  return (
    <div className={classList('v-modal-container v-center', isVisible && 'v-modal-visible')}>
      <div className={classList('v-modal')}>
        {!(extra && extra.hideTitlebar) && (
          <div className={classList('v-modal-title')}>
            {title || 'Vanlo'}
            {closeButton !== false && <IconClose onClick={closeModal} className="v-modal-icon v-icon-14" />}
          </div>
        )}
        <div className={classList('v-modal-body', extra && extra.className)}>
          {Comp && <Comp {...{ ...payload }} />}
          {!!closeButton && (
            <div className={classList('v-modal-footer')}>
              <Button
                onClick={() => {
                  closeModal();
                  callback && callback();
                }}
                fullwidth
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ modal }) => {
  const { getTitle, getBody }                                      = (modal && modal.content) || {};
  const title                                                      = getTitle && getTitle();
  const { extra, component: Comp, closeButton, payload, callback } = (getBody && getBody({})) || {};
  const isVisible                                                  = title || Comp;
  return { title, payload, Comp, isVisible, closeButton, extra, callback };
};

const Modal = connect(mapStateToProps)(ModalComponent);

export { Modal };
