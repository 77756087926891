const initialState = {
  adjustments: [],
  total_count: 0,
};

export const adjustments = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADJUSTMENTS':
      return { ...action.payload };
    default:
      return state;
  }
};
