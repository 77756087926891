import { resource } from './resource';
import { getFormattedDate, getPastDays } from '../components/Calendar';

const route      = '/invoices';
const storageKey = 'SET_INVOICES';
const filters    = {
  q:    null,
  per:  50,
  kind: 'outstanding',
  page: 1,
};

export const InvoicesResource = resource({
  route,
  storageKey,
  filters,
});
