import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Sidebar, showStatic } from './LeftSidebar';
import { BodyContainer } from './BodyContainer';
import FallbackComponent from './FallbackComponent';
import { UserResource } from '../resources';
import { queryVanlo } from '../resources/query';
import { Checkbox, Button } from '../components';
import '../styles/base.css';

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tosCheckbox: false };
    UserResource.list(false);
  }

  logout() {
    window.localStorage.clear();
    window.location.href = '/';
  }

  TosBox = ({ title, body }) => {
    const checked = this.state.tosCheckbox;
    return (
      <div className="v-modal-container v-center v-modal-visible">
        <div className="v-modal">
          <div className="v-modal-title">{title}</div>
          <div className="v-modal-body">
            <div className="v-iframe-window">
              <div dangerouslySetInnerHTML={{ __html: body }} />
              <div className="v-center">
                <Checkbox
                  onChange={(e) => this.setState({ tosCheckbox: !checked })}
                  checked={checked}
                  label="I have read and accept these Terms of Service"
                />
              </div>
              <div className="v-center">
                <Button onClick={() => UserResource.runAndReload('accept_tos')} disabled={!checked}>
                  Accept
                </Button>
                <Button onClick={() => this.logout()} className="v-margin-left-1rem">
                  Deny
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { location, isTestMode, user } = this.props;
    const tos                            = user.tos_to_accept;
    const activeRoute                    = location.pathname || '/styleguide';
    return (
      <div className="vanlo-app">
        <Sidebar testMode={isTestMode} activeRoute={activeRoute} />
        <Sentry.ErrorBoundary fallback={() => <FallbackComponent />}>
          {user.id && !tos ? <BodyContainer activeRoute={activeRoute + (isTestMode ? 'test' : '')} /> : ''}
          {tos ? <this.TosBox {...tos} /> : ''}
        </Sentry.ErrorBoundary>
      </div>
    );
  }
}

export const UserDashboard = connect(({ user, server: { isTestMode } }) => ({ user, isTestMode }))(
  withRouter((props) => <DashboardContainer {...props} />)
);
