const initialState = {
  billing_enabled: false,
  tos_accepted:    false,
};

export const businessInformation = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BUSINESS_INFORMATION':
      return action.payload;
    default:
      return state;
  }
};
