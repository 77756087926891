const initialState = {
  shipments:   [],
  total_count: 0,
  options:     { uploads: [] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_READY_SHIPMENTS':
      return action.payload;
    default:
      return state;
  }
};
