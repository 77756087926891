import printJS from 'print-js';
import { showErrorMessage } from './modal';

export function downloadURL(url, name) {
  const link = document.createElement('a');
  if (name) link.download = name;
  link.href   = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  setTimeout(() => document.body.removeChild(link), 750);
}

export function printURL(url, ext) {
  url          = unescape(url);
  ext          = ext || (url.match(/\.(\w+)\??/) && url.match(/\.(\w+)\??/)[1]) || '';
  const format = { png: 'image', pdf: 'pdf' }[ext];
  if (!format) return showErrorMessage(`Couldn't print ${ext.toUpperCase()} file from a browser`);

  printJS(url, format);
}
