import { resource } from './resource';

const route      = '/scan_forms';
const storageKey = 'SET_SCAN_FORMS';
const filters    = {
  per:  50,
  page: 1,
};

export const ScanFormsResource = resource({
  route,
  storageKey,
  filters,
});
