const initialState = {
  content: null,
};

const modal = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'MODAL_SHOW':
      return {
        ...state,
        content: payload,
      };
    case 'MODAL_HIDE':
      return {
        ...state,
        content: null,
      };
    default:
      return state;
  }
};

export { modal };
