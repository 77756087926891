import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { ChromePicker } from 'react-color';
import { Button } from '../../components/Button';
import { Grid, Grid1, Grid2 } from '../../components/Grids';
import { Title } from '../../components/Title';
import { Card } from '../../components/Card';
import { Textbox } from '../../components/Textbox';
import { Checkbox } from '../../components/Checkbox';
import { TrackingPageResource } from '../../resources';
import { IconExternalLink } from '../../helpers/icons';

class TrackingPage extends React.Component {
  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
    this.state     = { trackingPageChanges: {}, iframe: 0 };
  }

  clickFileInput = () => {
    this.fileInput.current.click();
  };

  handleColorChange = (color) => {
    this.setState({
      trackingPageChanges: {
        ...this.state.trackingPageChanges,
        color: color.hex,
      },
    });
  };

  renderColorPicker() {
    const popover = {
      position: 'absolute',
      zIndex:   '2',
    };

    const cover = {
      position: 'fixed',
      top:      '0px',
      right:    '0px',
      bottom:   '0px',
      left:     '0px',
    };

    return (
      <div className="container" style={{ position: 'relative' }}>
        <div style={popover}>
          <div style={cover} onClick={this.toggleColorPicker} />
          <ChromePicker
            color={this.props.trackingPage.color || this.state.trackingPageChanges.color}
            onChange={this.handleColorChange}
          />
        </div>
      </div>
    );
  }

  toggleColorPicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  toggleAllowReturns = ({ target: { value } }) => {
    this.setState({
      trackingPageChanges: {
        ...this.state.trackingPageChanges,
        allow_returns: value,
      },
    });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      trackingPageChanges: { ...this.state.trackingPageChanges, [name]: value },
    });
  };

  selectFile = (event) => {
    const file = event.target.files[0];

    this.setState({
      trackingPageChanges: { ...this.state.trackingPageChanges, file },
    });
  };

  save = () => {
    const {
      iframe,
      trackingPageChanges: { color, title, url, allow_returns, file },
    } = this.state;

    const payload = { color, title, url, allow_returns };

    const formData = new FormData();

    if (file) {
      formData.append('tracking_page[file]', file);
    }

    Object.keys(payload).forEach((key) => {
      if (payload[key] !== undefined) {
        formData.append(`tracking_page[${key}]`, payload[key]);
      }
    });

    if (!isEmpty(Object.fromEntries(formData))) {
      TrackingPageResource.create(formData, 'Saving...').then(() => {
        this.setState({ iframe: iframe + 1, trackingPageChanges: {} });
      });
    }
  };

  render() {
    const { trackingPage, user }                              = this.props;
    const { trackingPageChanges, displayColorPicker, iframe } = this.state;

    const values = { ...trackingPage, ...trackingPageChanges };

    const styles = {
      color: {
        width:        '36px',
        height:       '14px',
        borderRadius: '2px',
        background:   `${values.color}`,
      },

      swatch: {
        padding:      '5px',
        background:   '#fff',
        borderRadius: '1px',
        boxShadow:    '0 0 0 1px rgba(0,0,0,.1)',
        display:      'inline-block',
        cursor:       'pointer',
      },
    };

    if (trackingPage.color) {
      return (
        <div className="v-page">
          <Title title="Tracking Page" />

          <Grid>
            <Grid1 className="v-grid-column v-no-grid">
              <Card>
                <div className="v-input-row">
                  <label className="v-text-label">Logo</label>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input type="file" ref={this.fileInput} style={{ display: 'none' }} onChange={this.selectFile} />
                    {trackingPage.logo && (
                      <img alt="Vanlo" width="100" className="v-margin-top-2" src={trackingPage.logo} />
                    )}
                  </div>
                  <div>
                    <Button onClick={this.clickFileInput} small white style={{ width: '100%', marginTop: '2rem' }}>
                      Choose Image
                    </Button>
                  </div>
                </div>
                <div className="v-input-row v-margin-bottom-2 v-margin-top-2">
                  <Textbox
                    name="url"
                    value={values.url}
                    onChange={this.handleChange}
                    className="v-text"
                    label="URL"
                    placeholder="https://vanlo.com"
                  />
                </div>
                <div className="v-input-row v-margin-bottom-2">
                  <Textbox
                    name="title"
                    value={values.title}
                    onChange={this.handleChange}
                    className="v-text"
                    label="Title"
                    placeholder="Tracking"
                  />
                </div>

                <Grid>
                  <Grid1>
                    <div>
                      <div className="v-input-row v-margin-bottom-2">
                        <label className="v-text-label">Text Color</label>
                        <div style={styles.swatch} onClick={this.toggleColorPicker}>
                          <div style={styles.color} />
                        </div>
                        {displayColorPicker ? this.renderColorPicker() : null}
                      </div>
                    </div>
                  </Grid1>

                  <Grid1 className="v-grid-column">
                    <label className="v-text-label">Allow returns?</label>
                    <Checkbox checked={values.allow_returns} onChange={this.toggleAllowReturns} />
                  </Grid1>
                </Grid>
              </Card>
              <Grid1>
                <div className="v-center v-margin-right-3rem">
                  <Button onClick={this.save}>Save</Button>
                </div>

                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`${process.env.REACT_APP_TRACKING_PAGE_URL}/preview/${user.public_id.split('_')[1]}`}
                >
                  <div className="v-sidebar-label-title v-center">
                    <IconExternalLink className="v-item-icon v-icon-22" />
                    Preview in new window
                  </div>
                </a>
              </Grid1>
            </Grid1>
            <Grid2 className="v-margin-left-2rem v-grid-column">
              <Card>
                <div className="brand-preview-container">
                  <iframe
                    title="Preview"
                    key={iframe}
                    className="brand-preview"
                    src={`${process.env.REACT_APP_TRACKING_PAGE_URL}/${user.public_id.split('_')[1]}/preview`}
                    height="1000"
                  />
                </div>
              </Card>
            </Grid2>
          </Grid>
        </div>
      );
    }
    return <></>;
  }
}

export default connect(({ user, trackingPage }) => ({ user, trackingPage }))(TrackingPage);
