import { resource } from './resource';
import { queryVanlo } from './query';
import { closeModal, showBusyMessage, showErrorMessage } from '../helpers/modal';
import { getFormattedDate, getPastDays } from '../components/Calendar';
import { readStorageData } from '../helpers/localStorage';

const route      = '/webhook_endpoints';
const storageKey = 'SET_WEBHOOKS';

function sample(id) {
  const promise = queryVanlo(`${this.namespace}${this.route}/${id}/sample`, 'POST');
  showBusyMessage('Sending sample webhook...', promise);
  promise.then(({ success, message }) => {
    success ? closeModal() : showErrorMessage(message);
  });
  return promise;
}

function getEvents(id) {
  const promise = queryVanlo(`${this.namespace}${this.route}/${id}/webhooks`, 'GET');
  showBusyMessage('Getting events...', promise);
  return promise;
}

export const WebhooksResource = resource({
  route,
  storageKey,
  sample,
  getEvents,
  filters: {
    per:       readStorageData('per') || 50,
    page:      1,
    api:       'v1',
    name:      '*',
    status:    'all',
    date_from:  getFormattedDate(getPastDays(6)),
    date_to:    getFormattedDate(getPastDays(-1)),
  },
});
