import React from 'react';
import { classList } from '../../helpers/classList';
import { formatCurrency } from '../../helpers/data';
import { Card } from '../../components/Card';

export const BalanceAmount = ({ balance }) => {
  const formatted = formatCurrency(balance.external_value);
  return (
    <Card className="v-current-balance">
      <div className={classList('v-current-balance-lines')} />
      <span className={classList('v-current-balance-label')}>Current Balance</span>
      <div className={classList('v-current-balance-amount')} title={`${formatCurrency(balance.external_value)}`}>
        <span className={classList('v-current-balance-amount-sign')}>{formatted[0].replace('$', '')}$</span>
        {formatted.replace(/^[^$]*\$/, '')}
      </div>
    </Card>
  );
};
