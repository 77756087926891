import React from 'react';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { readStorageData } from './helpers/localStorage';
import { history } from './helpers/history';
import { UserDashboard } from './app/UserDashboard';
import { Modal } from './components/Modal';
import Login from './pages/Login';
import { ForgotPassword } from './pages/Login/ForgotPassword';
import './styles/index.css';
import './styles/base.css';

export const AppComponent = ({ session }) => {
  const darkMode = readStorageData('darkMode');
  return (
    <div className={`r-app ${darkMode === 'system' ? 'v-auto-darkmode' : darkMode === 'dark' ? 'v-darkmode' : ''}`}>
      {session ? (
        <Router history={history}>
          <Route path="/" component={UserDashboard} />
          <Modal />
        </Router>
      ) : (
        <Router history={history}>
          <Route path="/" component={Login} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Modal />
        </Router>
      )}
    </div>
  );
};

export const App = connect(({ session }) => ({ session }))(AppComponent);
