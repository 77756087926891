import React from 'react';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Hr } from '../../components/Hr';
import { Select } from '../../components/Select';
import { Textbox } from '../../components/Textbox';
import { closeModal } from '../../helpers/modal';
import { CarrierAccountsResource } from '../../resources';
import { Grid, Grid1, Grid2, Grid4 } from '../../components/Grids';
import { Checkbox } from '../../components/Checkbox';

const capitalize = (string) => {
  if (!string) return '';
  return `${string[0].toUpperCase()}${string.substr(1, string.length)}`;
};

const titleize = (string) => {
  return string
    .replace('_', ' ')
    .split(' ')
    .map((s) => capitalize(s))
    .join(' ');
};

const credentialList = {
  FedEx: ['key', 'meter', 'password', 'account_number'],
  UPS:   ['key', 'user_id', 'password', 'account_number'],
};

export class NewCarrierAccountModal extends React.Component {
  state = {
    carrier:     'FedEx',
    credentials: {},
    smartPost:   !!(this.props.account && this.props.account.credentials.smart_post),
    ...this.props.account,
  };

  change({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  toggleSmartPost() {
    const { smartPost, credentials } = this.state;
    this.setState({
      smartPost:   !smartPost,
      credentials: { ...credentials, smart_post: {} },
    });
  }

  changeCredentials({ target: { name, value } }) {
    const { credentials } = this.state;
    this.setState({ credentials: { ...credentials, [name]: value } });
  }

  changeSPCredentials({ target: { name, value } }) {
    const { credentials } = this.state;
    this.setState({
      credentials: {
        ...credentials,
        smart_post: { ...credentials.smart_post, [name]: value },
      },
    });
  }

  save() {
    const { carrier, id, credentials, description, smartPost } = this.state;
    if (!smartPost) delete credentials.smart_post;
    const carrier_account = { credentials, description };
    if (id) {
      CarrierAccountsResource.update({ id, carrier_account }, 'Updating carrier account...');
    } else {
      carrier_account.carrier = carrier;
      CarrierAccountsResource.create({ carrier_account }, 'Creating new carrier account...');
    }
  }

  render() {
    const { carrier, id, credentials, description, smartPost } = this.state;
    const change                                               = this.change.bind(this);
    const isValid                                              = credentialList[carrier].every((cred) => credentials[cred] && credentials[cred].length > 0);
    return (
      <div className={classList('v-modal-form', 'v-center-column v-center-left')}>
        <Grid>
          <Grid4>
            <Select
              labelLeft="Carrier:"
              styleLabelLeft={{ width: '7rem' }}
              name="carrier"
              className={`v-select-small-no-border v-width-${carrier === 'FedEx' && !smartPost ? '20' : '30'}`}
              classOpenOptions="v-fixed v-width-30"
              items={[
                { value: 'FedEx', label: 'FedEx' },
                { value: 'UPS', label: 'UPS' },
              ]}
              value={carrier}
              disabled={!!id}
              onChange={change}
            />
          </Grid4>
          {carrier === 'FedEx' && (
            <Grid2>
              <Checkbox
                style={{ display: 'flex', margin: '0 0 0 0.75rem' }}
                label="SmartPost"
                name="smartPost"
                onChange={this.toggleSmartPost.bind(this)}
                checked={smartPost}
              />
            </Grid2>
          )}
        </Grid>
        <br />
        {credentialList[carrier].map((cred) => (
          <Grid key={cred}>
            <Grid1>
              <Textbox
                labelLeft={titleize(cred)}
                styleLabelLeft={{ width: '7rem' }}
                name={cred}
                className={`v-select-small-no-border${smartPost ? ' v-margin-right-1rem' : ''}`}
                value={credentials[cred]}
                onChange={this.changeCredentials.bind(this)}
              />
            </Grid1>
            {smartPost && (
              <Grid1>
                <Textbox
                  labelLeft={`SmartPost ${titleize(cred)}`}
                  styleLabelLeft={{ width: '7rem', marginLeft: '1rem' }}
                  name={cred}
                  className="v-select-small-no-border"
                  value={credentials.smart_post[cred]}
                  onChange={this.changeSPCredentials.bind(this)}
                />
              </Grid1>
            )}
          </Grid>
        ))}
        <Textbox
          labelLeft="Description"
          styleLabelLeft={{ width: '7rem' }}
          name="description"
          className="v-select-small-no-border"
          placeholder="Enter description..."
          value={description}
          onChange={change}
        />
        <Hr className="v-hr-2" />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button disabled={!isValid} label={id ? 'Update' : 'Create'} onClick={this.save.bind(this)} />
        </div>
      </div>
    );
  }
}
