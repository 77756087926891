import React from 'react';
import { connect } from 'react-redux';
import { groupBy, sumBy, reduce, sortBy } from 'lodash';
import { BarChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts';

import { UserSummariesResource } from '../../resources';
import { Title } from '../../components/Title';
import { Grid, Grid1 } from '../../components/Grids';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { formatCurrency } from '../../helpers/data';
import { showCalendarModal, createDate, getFormattedDate } from '../../components/Calendar';

const trackingStatuses = {
  unknown:              'Unknown',
  pre_transit:          'Pre-Transit',
  in_transit:           'In Transit',
  out_for_delivery:     'Out For Delivery',
  available_for_pickup: 'Available For Pickup',
  delivered:            'Delivered',
  return_to_sender:     'Return to Sender',
  failure:              'Failure',
  cancelled:            'Cancelled',
  error:                'Error',
};

const domesticServices = ['Express', 'First', 'Priority', 'ParcelSelect', 'LibraryMail', 'MediaMail'];

class Analytics extends React.Component {
  constructor(props) {
    super(props);

    this.state = { filters: UserSummariesResource.filters };
  }

  carrierRows() {
    const {
      userSummaries: { records: userSummaries },
    } = this.props;

    const summariesGroupedByCarrier = groupBy(userSummaries, 'carrier');

    const summariesGroupedByCarrierAndService = Object.fromEntries(
      Object.keys(summariesGroupedByCarrier).map((carrier) => [
        carrier,
        groupBy(summariesGroupedByCarrier[carrier], 'service'),
      ])
    );

    const carriers = Object.keys(summariesGroupedByCarrierAndService);

    if (carriers.length === 0) return <h1>No stats found for the selected date range</h1>;

    return carriers.map((carrier) => {
      return <div>{this.serviceRows(carrier, summariesGroupedByCarrierAndService[carrier])}</div>;
    });
  }

  serviceRows(carrierName, summariesGroupedByService) {
    const serviceNames = Object.keys(summariesGroupedByService);

    const sortedServiceNames = sortBy(serviceNames, (obj) => obj.length);

    return sortedServiceNames.map((serviceName) => {
      const serviceSummaries = summariesGroupedByService[serviceName];

      const shipmentsCount = sumBy(serviceSummaries, 'shipments_count');
      const cost           = sumBy(serviceSummaries, 'cost');
      const averagePrice   = cost / shipmentsCount;
      const incentive      = sumBy(serviceSummaries, 'incentive');

      const serviceStats = {
        Shipments:      shipmentsCount,
        'Total Cost':   formatCurrency(cost),
        'Average Cost': formatCurrency(averagePrice),
      };

      if (incentive > 0) {
        serviceStats.Incentive = formatCurrency(incentive);
      }

      const trackingStatusCounts = reduce(
        Object.keys(trackingStatuses),
        (result, status) => {
          result[status] = sumBy(serviceSummaries, (summary) => {
            return summary.tracking_status_counts[status];
          });

          return result;
        },
        {}
      );

      let transitTimes;
      if (domesticServices.indexOf(serviceName) !== -1) {
        transitTimes = reduce(
          Array(9)
            .fill()
            .map((_, i) => i + 1),
          (result, status) => {
            result[status] =
              sumBy(serviceSummaries, (summary) => {
                try {
                  return summary.time_in_transit.zoned_averages[status];
                } catch (error) {
                  return 0;
                }
              }) / serviceSummaries.length;

            return result;
          },
          {}
        );
      } /* else {
        serviceStats['Average Transit Time'] = (
          sumBy(serviceSummaries, (summary) => {
            return summary.time_in_transit.average;
          }) /
          serviceSummaries.length /
          86400
        ).toFixed(2);
      } */

      return (
        <>
          <div className="v-hr v-hr-2" />
          <div>
            <h2>
              {carrierName} — {serviceName}
            </h2>
            <Grid>{this.serviceColumns(serviceStats)}</Grid>

            <Card className="v-padding-2">
              <Grid>
                <Grid1 className="v-grid-column v-padding-right-3 v-no-grid">
                  <div className="v-margin-bottom-2">{this.trackingChart(trackingStatusCounts)}</div>
                </Grid1>
                <Grid1 className="v-grid-column v-padding-left-3 v-no-grid">
                  <div className="v-margin-bottom-2">
                    {domesticServices.indexOf(serviceName) !== -1 && this.transitTimesChart(transitTimes)}
                  </div>
                </Grid1>
              </Grid>
            </Card>
          </div>
        </>
      );
    });
  }

  serviceColumns(serviceStats) {
    return Object.keys(serviceStats).map((serviceStat) => {
      return (
        <Grid1>
          <Card className="v-analytics-card">
            <div className="v-analytics-lines" />
            <span className="v-analytics-label">{serviceStat}</span>
            <div className="v-analytics-value">{serviceStats[serviceStat]}</div>
          </Card>
        </Grid1>
      );
    });
  }

  dateFilter() {
    const changeFilter = (params) => {
      UserSummariesResource.setFilter('date_from', params[0]);
      UserSummariesResource.setFilter('date_to', params[1]);

      UserSummariesResource.list();

      const newFilters = {
        ...this.state.filters,
        ...UserSummariesResource.filters,
      };

      this.setState({ filters: newFilters });
    };

    const onChangeDates = ([from, to]) => {
      changeFilter([getFormattedDate(from), getFormattedDate(to)]);
    };

    const { date_from, date_to } = this.state.filters;

    const formattedDate = `${date_from} - ${date_to}`;

    return (
      <>
        <span className="v-subtitle v-margin-right-05rem">Date Range:</span>
        <Button
          onClick={showCalendarModal([createDate(date_from), createDate(date_to)], onChangeDates.bind(this))}
          white
          label={formattedDate}
        />
      </>
    );
  }

  trackingChart(trackingStatusCounts) {
    const data = reduce(
      trackingStatusCounts,
      function (result, value, key) {
        if (value) result.push({ status: key, count: value });

        return result;
      },
      []
    );

    if (data.length > 0) {
      return (
        <div>
          <div style={{ height: `${data.length * 20 + 40}px` }}>
            <h3 className="v-margin-top-0">Tracking Status</h3>
            <ResponsiveContainer height="100%" width="80%">
              <BarChart barSize={30} data={data} layout="vertical" margin={{ bottom: 40, right: 40 }}>
                <h4 className="v-margin-top-0">Tracking Status</h4>

                <XAxis type="number" dataKey="count" hide />
                <YAxis
                  type="category"
                  dataKey="status"
                  interval={0}
                  tickLine={false}
                  width={140}
                  axisLine={false}
                  tickFormatter={(trackingStatus) => {
                    return trackingStatuses[trackingStatus];
                  }}
                />
                <Bar dataKey="count" fill="#333" minPointSize={3}>
                  <LabelList position="right" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }
  }

  transitTimesChart(transitTimes) {
    const data = reduce(
      transitTimes,
      function (result, value, key) {
        result.push({
          zone:        key,
          transitTime: value ? (value / 86400).toFixed(2) : 0,
        });

        return result;
      },
      []
    );

    return (
      <div style={{ height: `${data.length * 20 + 40}px` }}>
        <h3 className="v-margin-top-0">Average Transit Times By Zone</h3>
        <ResponsiveContainer height="100%" width="80%">
          <BarChart barSize={30} data={data} layout="vertical" margin={{ bottom: 40, right: 40 }}>
            <XAxis type="number" dataKey="transitTime" hide />
            <YAxis type="category" dataKey="zone" interval={0} tickLine={false} width={20} axisLine={false} />
            <Bar dataKey="transitTime" fill="#333" minPointSize={3}>
              <LabelList position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  render() {
    return (
      <div className="v-page">
        <Title title="Analytics" />
        {this.dateFilter()}
        {this.carrierRows()}
      </div>
    );
  }
}

export default connect(({ userSummaries }) => ({ userSummaries }))(Analytics);
