import React from 'react';
import { camelizeKeys } from 'humps';

import { Button, DataGrid, Hr } from '../../components';
import { closeModal } from '../../helpers/modal';
import { ShipmentModal } from '../Shipments/ShipmentModal';
import { ShopOrdersResource } from '../../resources';

export class OrderModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { order: props.order }
    ShopOrdersResource.get(this.props.order.id, null).then((order) => {
      order.shipments.forEach((s) => (s.order = order))
      this.setState({ order })
    });
  }

  render() {
    const { page }  = this.props;
    const { order } = this.state;
    const { items } = order;
    return (
      <div className="v-modal-form v-width-80 v-center-column v-center-left">
        {order.note && (
          <div style={{ display: 'inline-flex', marginBottom: '1rem' }}>
            <div style={{ lineHeight: 'calc(1em + 2rem)' }}>Note:</div>
            <pre>{order.note}</pre>
          </div>
        )}
        <div className="v-modal-heading v-no-margin">Line Items</div>
        <div style={{ margin: '2rem 0' }}>
          <DataGrid headers={['sku', 'name', 'price', 'weight', 'count']} rows={items} />
        </div>
        <Hr style={{ margin: '1rem 0' }} />

        {order.shipments.map((x) => x.id && <ShipmentModal embed data={x} page={page} key={x.id} />)}
        <div className="v-modal-footer v-center-right">
          <Button
            className="v-padding-right-1"
            label="Add Shipment"
            onClick={page.editShipment({ order })}
            disabled={order.shipments.find((x) => !x.label_status)}
          />
          <Button className="v-padding-right-1" label="Close Info" onClick={closeModal} />
        </div>
      </div>
    );
  }
}
export default OrderModal;
