import React from 'react';
import { classList } from '../../helpers/classList';
import { Button } from '../../components/Button';
import { Hr } from '../../components/Hr';
import { Select } from '../../components/Select';
import { Textbox } from '../../components/Textbox';
import { closeModal } from '../../helpers/modal';
import { Api, eventsList } from './config';
import { WebhooksResource } from '../../resources';

export class NewEndpointModal extends React.Component {
  state = {
    url:        'https://',
    event_type: '*',
    api:        'v1',
  };

  change({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  create() {
    const { url, api, event_type: eventType } = this.state;
    const event_type                          = api === 'v1' ? '*' : eventType;
    WebhooksResource.create({ webhook_endpoint: { url, api, event_type } }, 'Creating new webhook...');
  }

  render() {
    const { url, api, event_type } = this.state;
    const { options }              = this.props
    const change                   = this.change.bind(this);
    return (
      <div className={classList('v-modal-form', 'v-center-column v-center-left')}>
        <Textbox
          labelLeft="URL:"
          styleLabelLeft={{ width: '7rem' }}
          name="url"
          className="v-select-small-no-border"
          value={url}
          onChange={change}
        />
        <br />
        <Select
          labelLeft="API:"
          styleLabelLeft={{ width: '7rem' }}
          name="api"
          className="v-select-small-no-border v-width-30"
          classOpenOptions="v-fixed v-width-30"
          items={options.apis.map(([label, value]) => ({ label, value }))}
          value={api}
          onChange={change}
        />
        <br />
        <Select
          labelLeft="Event Type:"
          styleLabelLeft={{ width: '7rem' }}
          name="event_type"
          className="v-select-small-no-border v-width-30"
          classOpenOptions="v-fixed v-width-30"
          items={options.names[api].map(([label, value]) => ({ label, value }))}
          value={event_type}
          onChange={change}
        />
        <Hr className="v-hr-2" />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button disabled={!url || !api || !event_type} label="Create" onClick={this.create.bind(this)} />
        </div>
      </div>
    );
  }
}
