import {
  IconSettings,
  IconSignOut,
  IconBilling,
  IconLabels,
  IconDeveloper,
  IconExportDoc,
  IconAnalytics,
  IconLeadSearch,
} from '../../helpers/icons';
import { downloadURL } from '../../helpers/misc';

const sidebarMenuItems = [
  {
    label:     'Shipping',
    icon:      IconLabels,
    condition: (user) => !user.person || user.person.permissions.shipment_reporting,
    list:      [
			{
        label: 'Shipments',
        route: '/shipments',
      },
      {
        label:     'CSV Upload',
        route:     '/shipment-uploads',
        condition: (user, server) => !user.person,
      },
      {
        label:     'Shop Orders',
        route:     '/shop-orders',
				condition: (user) => !user.person,
      },
    ],
  },
  {
    label: 'Analytics',
    icon:  IconAnalytics,
    route: '/analytics',
  },
  {
    label:     'Manifests',
    icon:      IconLabels,
    route:     '/scan-forms',
    condition: (user) => !user.person || user.person.permissions.scan_forms,
  },
  {
    label:     'Lead Searches',
    icon:      IconLeadSearch,
    route:     '/lead-searches',
    condition: (user) =>
      (user.email || '').indexOf('vanlo') !== -1 || (user.email || '').indexOf('dripdigitalmedia') !== -1,
  },
  {
    label:     'Reporting',
    icon:      IconExportDoc,
    condition: (user) =>
      !user.person || (user.person.permissions.shipment_reporting && user.person.permissions.billing_reporting),
    list: [
      {
        label: 'Reports',
        route: '/reports',
      },
      {
        label: 'Scheduled Reports',
        route: '/scheduled-reports',
      },
    ],
  },
  {
    label:     'Billing',
    icon:      IconBilling,
    condition: (user) =>
      !user.person || user.person.permissions.billing_reporting || user.person.permissions.billing_settings,
    list: [
      {
        label:     'Invoices',
        route:     '/invoices',
        condition: (user) => user.billing_type === 'invoicing',
      },
      // {
      //   label: 'Adjustments',
      //   route: '/adjustments',
      // },
      {
        label:     'Balance',
        route:     '/balance',
        condition: (user) =>
          (!user.person && user.billing_type === 'wallet') ||
          (user.person && user.person.permissions.billing_reporting),
      },
      {
        label:     'External Incentives',
        route:     '/external-incentives',
        condition: (user) => user.externally_billed && (!user.person || user.person.permissions.billing_reporting),
      },
      {
        label:     'Payment Methods',
        route:     '/payment-methods',
        condition: (user) => !user.person || user.person.permissions.billing_settings,
      },
      {
        label:     'Billing Information',
        route:     '/billing-information',
        condition: (user) => !user.person || user.person.permissions.billing_settings,
      },
    ],
  },
  {
    label: 'Developers',
    icon:  IconDeveloper,
    list:  [
      {
        label:     'Webhooks',
        route:     '/webhooks',
        condition: (user) => !user.person || user.person.permissions.api_keys,
      },
      {
        label:     'API Keys',
        route:     '/apikey',
        condition: (user) => !user.person || user.person.permissions.api_keys,
      },
      {
        label:   'Documentation',
        onClick: () => downloadURL('https://api-docs.vanlo.com/'),
      },
    ],
  },
  {
    label: 'Settings',
    icon:  IconSettings,
    list:  [
      {
        label:     'Tracking Page',
        route:     '/tracking-page',
        condition: (user) => !user.person,
      },
      {
        label:     'Carrier Accounts',
        route:     '/carrier-accounts',
        condition: (user) => !user.person,
      },
      {
        label:     'Shop Connections',
        route:     '/shop-connections',
				condition: (user) => !user.person,
      },
      {
        label:     'Packing Slip',
        route:     '/packing-slip',
        condition: (user) => !user.person,
      },
      {
        label:     'CSV Mappings',
        route:     '/shipment-upload-mappings',
        condition: (user) => !user.person,
      },
      {
        label:     'Automations',
        route:     '/automations',
        condition: (user) => !user.person,
      },
      {
        label:     'Search Presets',
        route:     '/search-presets',
        condition: (user) => !user.person,
      },
      {
        label:     'Permissions',
        route:     '/permissions',
        condition: (user) => !user.person,
      },
      {
        label:     'Locations',
        route:     '/locations',
        condition: (user) => !user.person || user.person.permissions.add_locations,
      },
      {
        label:     'Business Information',
        route:     '/business-information',
        condition: (user) => !user.person && user.externally_billed,
      },
      {
        label: 'Reset Password',
        route: '/reset-password',
      },
    ],
  },
  {
    label:   'Sign out',
    icon:    IconSignOut,
    onClick: () => {
      window.localStorage.clear();
      window.location.href = '/';
    },
  },
];

const detectOpen = (active, user, server) => {
  let index = -1;
  sidebarMenuItems
    .filter((item) => !item.condition || item.condition(user, server))
    .forEach((item, i) => {
      if (item.list && item.list.find((y) => y.route === active)) index = i;
    });
  return index;
};

export { sidebarMenuItems, detectOpen };
