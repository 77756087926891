import React from 'react';
import { Link } from 'react-router-dom';
import { performLogin } from './login';
import { Card } from '../../components/Card';
import { classList } from '../../helpers/classList';
import { Textbox } from '../../components/Textbox';
import { PasswordBox } from '../../components/PasswordBox';
import { Button } from '../../components/Button';
import ServerSelect from '../../components/ServerSelect';

const handleKeyPress = (event, callback) => {
  if (event.key === 'Enter') callback && callback();
};

export default class Login extends React.Component {
  state = {};

  async submit() {
    this.setState({ isBusy: true });
    const error = Object.values(this.validateLoginForm()).join();
    if (error) {
      this.setState({ error });
      this.setState({ isBusy: false });
    } else {
      const { email, password } = this.state;
      performLogin({ email, password }, (error) => this.setState({ error })).finally(() => {
        this.setState({ isBusy: false });
      });
    }
  }

  validateLoginForm() {
    const { email, password } = this.state;
    const error               = {};
    if (email === '') error.email = 'Email cannot be empty!';
    if (password.length < 8) error.password = 'Password should be at least 8 characters';
    return error;
  }

  change({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  render() {
    const onSubmit                           = this.submit.bind(this);
    const change                             = this.change.bind(this);
    const { email, password, isBusy, error } = this.state;
    return (
      <div className="v-login-page v-center v-dark-card">
        <div className="v-login-title">VANLO</div>
        <Card className="v-login-card">
          <div
            className={classList(
              'v-no-margin v-no-padding v-padding-left-2 vv-padding-top-1 v-center',
              error && 'v-padding-bottom-2'
            )}
          >
            <div className="v-error-message">{error}</div>
          </div>
          <Textbox
            value={email}
            onChange={change}
            disabled={isBusy}
            className="v-text-black"
            name="email"
            label="Email"
            hidePlaceholder
          />
          <div className="v-padding-top-3" />
          <PasswordBox
            value={password}
            onChange={change}
            onKeyPress={(e) => handleKeyPress(e, onSubmit)}
            disabled={isBusy}
            className="v-text-black"
            name="password"
            label={
              <div className="v-title-bar">
                Password
                <Link
                  to="/forgot-password"
                  style={{
                    color:          'rgb(153, 153, 153)',
                    textTransform:  'none',
                    fontSize:       '115%',
                    textDecoration: 'underline',
                  }}
                >
                  Forgot Password
                </Link>
              </div>
            }
          />
          <div className="v-padding-top-2" />
          <Button disabled={isBusy} className="v-padding-1" white fullwidth label="Login" onClick={onSubmit} />
        </Card>
        {window.DASHBOARD_ENVIRONMENT !== 'PROD' && <ServerSelect />}
      </div>
    );
  }
}
