import { resource } from './resource';
import { getFormattedDate, getPastDays } from '../components/Calendar';
import { queryVanlo } from './query';
import { downloadURL } from '../helpers/misc';
import { showBusyMessage, showErrorMessage } from '../helpers/modal';
import { readStorageData } from '../helpers/localStorage';

const route      = '/shipments';
const storageKey = 'SET_SHIPMENTS';
const filters    = {
  q:          null,
  per:        readStorageData('per') || 50,
  downloaded: 'all',
  page:       1,
  date_from:  getFormattedDate(getPastDays(7)),
  date_to:    getFormattedDate(getPastDays(-1)),
};

const buyLabels = ({ ids }) => {
  if (ids.length === 0) return showErrorMessage('Select at least one shipment');

  const promise = queryVanlo(`/dashboard/shipments/buy`, 'POST', { ids });
  showBusyMessage(`Buying ${ids.length} label(s)...`, promise);
  return promise;
};

const compile = ({ format, ids }) => {
  if (ids.length === 0) return showErrorMessage('Select at least one shipment');

  const promise = queryVanlo(`/dashboard/shipments/compile`, 'POST', { format, ids });
  showBusyMessage(`Compiling ${ids.length} label(s)...`, promise);
  return promise;
};

const print = ({ format, ids }) => {
  if (ids.length === 0) return showErrorMessage('Select at least one shipment');

  const promise = queryVanlo(`/dashboard/shipments/print`, 'POST', { format, ids });
  showBusyMessage(`Printing ${ids.length} label(s)...`, promise);
  return promise;
};

const refund = (ids) => {
  if (ids.length === 0) return showErrorMessage('Select at least one shipment');

  const promise = queryVanlo(`/dashboard/shipments/refund`, 'POST', { ids });
  showBusyMessage(`Refunding ${ids.length} label(s)...`, promise);
  return promise;
};

const execute = (ids, rule) => {
  if (ids.length === 0) return showErrorMessage('Select at least one shipment');

  const payload = { shipment_ids: ids, automation_ids: rule.id === 'all' ? null : [rule.id] };
  const promise = queryVanlo(`/dashboard/shipments/execute`, 'POST', payload);
  showBusyMessage(`Executing "${rule.name}" against ${ids.length} shipment(s)...`, promise);
  return promise;
};

function exportCSV(email) {
  const { page, per, ...rest } = this.filters;

  return queryVanlo(`/dashboard/shipments/report`, 'POST', {
    ...rest,
    email,
  });
}

function getOrderInfo(id) {
  let resolve;
  const promise = new Promise((res) => (resolve = res));
  queryVanlo(`/dashboard/shipments/${id}/order`).then(({ blocks }) => {
    if (!blocks) return;
    const { main, additional } = blocks;
    const {
      items: { headers, rows, footer },
    } = additional || { items: {} };
    resolve({
      main,
      headers,
      rows,
      footer,
    });
  });
  return promise;
}

function buy(shipmentId, rateId) {
  const promise = queryVanlo(`/dashboard/shipments/${shipmentId}/buy`, 'POST', {
    rate_id: rateId,
  });

  showBusyMessage('Buying...', promise);

  return promise;
}

export const ShipmentResource = resource({
  route,
  storageKey,
  filters,
  buyLabels,
  compile,
  print,
  refund,
  execute,
  exportCSV,
  getOrderInfo,
  buy,
});
