const initialState = {
  balance: {
    available_value:                '$0.00',
    value:                          '$0.00',
    available_pending_credit_value: '$0.00',
  },
  topup: {
    payment_method: {
      options: [],
      value:   null,
    },
    amount: {
      options: [],
      value:   0,
    },
  },
};

export const balance = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_BALANCE': {
      return payload;
    }
    default:
      return state;
  }
};
