const initialState = {
  bank_cards:          [],
  bank_accounts:       [],
  payout_bank_account: null,
};

export const paymentMethods = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAYMENT_METHODS': {
      return action.payload;
    }
    default:
      return state;
  }
};
