import React from 'react';
import { Card } from '../../components/Card';
import { Textbox } from '../../components/Textbox';
import { Button } from '../../components/Button';
import { queryVanlo } from '../../resources/query';
import { classList } from '../../helpers/classList';
import { showBusyMessage, showTextMessage, showErrorMessage } from '../../helpers/modal';

export class ForgotPassword extends React.Component {
  state = {};

  setEmail({ target: { value } }) {
    this.setState({ email: value });
  }

  submit() {
    const promise = queryVanlo('/users/password', 'POST', {
      user: { email: this.state.email },
    });
    promise.then((r) => {
      if (r.success) {
        setTimeout(() => showTextMessage('Reset password email sent.'), 200);
      } else {
        setTimeout(() => showErrorMessage(r.message), 200);
      }
    });
    showBusyMessage('Submitting...', promise);
  }

  render() {
    const { email, isBusy, error } = this.state;
    return (
      <div className="v-login-page v-center v-dark-card">
        <div className="v-login-title">PASSWORD RECOVERY</div>
        <Card className="v-login-card">
          <div
            className={classList(
              'v-no-margin v-no-padding v-padding-left-2 vv-padding-top-1 v-center',
              error && 'v-padding-bottom-2'
            )}
          >
            <div className="v-error-message">{error}</div>
          </div>
          <Textbox
            value={email}
            disabled={isBusy}
            onChange={this.setEmail.bind(this)}
            className="v-text-black"
            name="email"
            label="Email"
            placeholder="hi@example.com"
          />
          <Button
            disabled={!email || isBusy}
            className="v-padding-1"
            white
            fullwidth
            label="SEND INSTRUCTIONS"
            onClick={this.submit.bind(this)}
          />
        </Card>
      </div>
    );
  }
}
