import React from 'react';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { useLocation, useHistory, withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { closeModal } from '../../helpers/modal';
import { Title, Button } from '../../components';
import ShipmentForm from './ShipmentForm';
import RateSelect from './RateSelect';
import Summary from './Summary';
import { ShipmentResource, ShopOrdersResource } from '../../resources';

class NewShipment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipment: {},
      id:       props.location.pathname.split('/').slice(-1)[0],
      ...props.location.state,
    };
    closeModal();
    if (this.state.id === 'new') {
      const id = this.state.order?.shipments[0]?.id;
      if (id)
        ShipmentResource.get(id).then((shipment) => {
          const { from_address, to_address } = shipment;
          this.setState({ shipment: { from_address, to_address } });
        });
    } else
      ShipmentResource.get(this.state.id).then((shipment) => this.setState({ shipment }));
  }

  edit = () => {
    this.setState({ rateSelection: false });
  };

  update = (shipment) => {
    this.setState({ shipment, rateSelection: !!shipment.rates?.length });
  };

  back = () => {
    this.props.history.push('/shop-orders');
    ShopOrdersResource.list();
  };

  render() {
    const { shipment, id, order, back, rateSelection } = this.state;
    const { user: { settings } }                       = this.props;

    const labelPurchased = shipment.tracking_code && !shipment.refund_status;

    let page;
    if (labelPurchased) {
      page = <Summary data={shipment} />;
    } else if (rateSelection) {
      page = <RateSelect shipment={shipment} page={this} />;
    } else {
      page = <ShipmentForm page={this} shipment={camelizeKeys(shipment)} order={order} settings={settings} />;
    }

    const subtitle = (
      <>
        {id === 'new' ? `Define new Shipment` : `Edit Shipment: ${id}`}&nbsp;
        {order && `for Order ${order.name} from ${order.shop}`}&nbsp;
        {id === 'new' && order && order.shop === 'Shopify' ? "(it won't be reflected in Shopify Admin)" : ''}&nbsp;
        {back === 'ShopOrders' && <Button white small onClick={this.back} label="Back to Orders" />}
      </>
    );

    return (
      <div className="v-awaiting-shipments v-page">
        <Title title="Purchase a Label" subtitle={subtitle} />
        {page}
      </div>
    );
  }
}

export default connect(({ user }) => ({ user }))(withRouter(NewShipment));
