import React from 'react';
import { startCase } from 'lodash';
import { Button } from '../../components';
import { formatDate } from '../../helpers/data';

export default function tableConfig() {
  return [
    {
      title: 'Сreated',
      field: 'created_at',
      cell:  (data) => formatDate(data),
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Period',
      field: 'period',
      cell:  (data) => startCase(data),
    },
    {
      title: 'Type',
      field: 'human_type',
    },
    {
      title: 'Actions',
      cell:  (data, _row, _col, object) => (
        <>
          <Button label="Delete" small white onClick={this.delete.bind(this, object.id)} />
          &nbsp;
          <Button label="Test Me" small white onClick={this.test.bind(this, object.id)} />
        </>
      ),
    },
  ];
}
