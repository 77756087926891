import { queryVanlo } from '../../resources/query';
import { store } from '../../store';

export async function performLogin(credentials, loginFailed) {
  const result                     = await queryVanlo('/users/sign_in', 'POST', {
    user: credentials,
  });
  const { success, message, data } = result || {};
  if (success) {
    store.dispatch({ type: 'SET_SESSION', payload: data.api_token });
  } else {
    loginFailed(message || 'Login error!');
  }
}
