const registeredSelects = new Map();

export const registerSelect = (c) => {
  registeredSelects.set(c, true);
};
export const deregisterSelect = (c) => {
  registeredSelects.delete(c);
};
export const setupEscAndKeyListener = () => {
  const closeAllOpenItems = () => {
    registeredSelects.forEach((v, select) => select.close.call(select));
  };

  const closeAllOpenItemsWithEsc = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      closeAllOpenItems();
    }
  };
  window.addEventListener('mouseup', closeAllOpenItems);
  window.addEventListener('keydown', closeAllOpenItemsWithEsc);
};
