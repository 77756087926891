import React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import 'react-widgets/styles.css';

const flexbox = ({ p, g }) => css`
  display: flex;
  flex-wrap: wrap;
  gap: ${g}rem;
  padding: ${p}rem;
`;

const RuleList           = styled.div`
  ${flexbox({ g: 1 })}
  flex-direction: column;
`;
const RulePreviewCard    = styled.div`
  padding: 1rem;
  background: #eee;
`;
const RuleExplanation    = styled.div`
  padding: 1rem;
  background: #ddd;
  font-family: monospace;
  margin: 0.5rem 0 1rem 0;
  width: fit-content;
`;
const RuleControls       = styled.div`
  ${flexbox({ g: 0.5 })}
  align-content: flex-start;
  .rw-widget {
    width: 12rem;
    display: inline-flex;
    font-size: 90%;
    .rw-widget-picker {
      min-height: 2.5rem;
    }
  }
`;
const RuleEditorForm     = styled.div`
  padding: 1rem;
  background: #eee;
`;
const LogicGroupsWrapper = styled.div`
  ${flexbox({ g: 1 })}
  padding: 1rem 0;
`;
const LogicGroupBox      = styled.div`
  ${flexbox({ p: 1, g: 1 })}
  align-content: flex-end;

  width: ${(p) => (p.group === 'conditions' ? '58rem' : '79rem')};
  background: #ddd;
  border: 1px solid #aaa;
`;
const LogicUnitRow       = styled.div`
  display: inline-flex;
  gap: 1rem;
  height: fit-content;
  & > b,
  & .v-icon-button {
    height: 3.8rem;
    line-height: 5.8rem;
  }
  & > b {
    width: 5rem;
    text-align: right;
  }
  & .v-icon-button {
    display: inline-block;
    width: 3.8rem;
    padding: 1rem;
  }
`;
const LogicUnitCard      = styled.div`
  display: inline-flex;
  width: ${(p) => (p.type === 'condition' ? '50rem' : '71rem')};
  padding: 1rem;
  background: #ccc;
  & .rw-widget-textbox:after {
    content: ${(p) => (p.actionType === 'add_weight' ? "'OZ'" : '')};
    position: absolute;
    right: 1em;
    top: 1rem;
    color: #495057;
  }
`;

export {
  RuleList,
  RulePreviewCard,
  RuleExplanation,
  RuleControls,
  RuleEditorForm,
  LogicGroupsWrapper,
  LogicGroupBox,
  LogicUnitRow,
  LogicUnitCard,
};
