import { resource } from './resource';
import { queryVanlo } from './query';

const route      = '/persons';
const storageKey = 'SET_PERSONS';

function togglePermission(id, permissionName) {
  const promise = queryVanlo(`/dashboard/persons/${id}/toggle_permission`, 'PATCH', { permission: permissionName });

  return promise;
}

function toggleState(id) {
  const promise = queryVanlo(`/dashboard/persons/${id}/toggle_state`, 'PATCH');

  return promise;
}

export const PersonsResource = resource({
  route,
  togglePermission,
  toggleState,
  storageKey,
});
