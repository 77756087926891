import { sortById, getWithAddressCalculated } from '../helpers/data';

const getWithFullAddress = (list) => list.map(getWithAddressCalculated);

const fake_json = {
  user: {
    name:               'Benjamin Gershbein',
    email:              'bg@vanlo.com',
    phone:              '4158460920',
    billing_eq_contact: false,
  },
  contact_address: {
    street1: '133 W. Sixth St.',
    city:    'Tempe',
    state:   'Arizona',
    zip:     '85281',
    phone:   '',
  },
  warehouse_addresses: getWithFullAddress([
    {
      id:      34,
      street1: '722 Montgomery St, Ste. 305',
      city:    'San Francisco',
      state:   'CA',
      zip:     '94112',
      phone:   '4158460920',
    },
    {
      id:      101,
      street1: '722 Montgomery St  Ste. 305',
      city:    'San Francisco',
      state:   'CA',
      zip:     '94111',
      phone:   '4158460920',
    },
  ]),
};

const initialState = {
  user:                {},
  contact_address:     {},
  warehouse_addresses: [],
  ...fake_json,
};

export const locations = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'UPDATE_WAREHOUSE_ADDRESSES': {
      const whWithoutExistingOne = state.warehouse_addresses.filter((wh) => wh.id !== payload.id);
      const list                 = [...whWithoutExistingOne, payload].sort(sortById);
      const warehouse_addresses  = getWithFullAddress(list);
      return {
        ...state,
        warehouse_addresses,
      };
    }
    case 'ADD_WAREHOUSE_ADDRESSES': {
      const list                = [...state.warehouse_addresses, payload].sort(sortById);
      const warehouse_addresses = getWithFullAddress(list);
      return {
        ...state,
        warehouse_addresses,
      };
    }
    default:
      return state;
  }
};
