const initialState = {
  records: [],
  options: { report_types: [], periods: [], 'params.kinds': [] },
};

export default (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_REPORT_SCHEDULES':
      return { ...state, ...payload };
    default:
      return state;
  }
};
