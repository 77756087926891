import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { getTableConfig, iconKey } from './config';
import { showModal } from '../../helpers/modal';
import { Grid } from '../../components/Grids';
import { NewCarrierAccountModal } from './NewCarrierAccountModal';
import { CarrierAccountsResource } from '../../resources';
import { Title } from '../../components/Title';

class CarrierAccounts extends React.Component {
  state = {};

  newAccount() {
    showModal({
      title:     `Create New Carrier Account`,
      component: NewCarrierAccountModal,
    });
  }

  editCarrierAccount(account) {
    showModal({
      title:     `Edit Carrier Account`,
      component: NewCarrierAccountModal,
      payload:   { account },
    });
  }

  deleteCarrierAccount(id) {
    CarrierAccountsResource.delete(id, 'Deleting carrier account...');
  }

  render() {
    const { customAccounts, defaultAccounts } = this.props;
    return (
      <div className="v-page v-carrier-accounts">
        <Title title="Carrier Accounts" />
        <div className="v-text" style={{ background: 'none' }}>
          Vanlo Managed Accounts
        </div>
        <Grid>
          {defaultAccounts.map((a) => {
            const Icon = iconKey[a.carrier];
            return (
              <Grid
                key={a.id}
                cells={defaultAccounts.length}
                style={{
                  alignItems:    'center',
                  flexDirection: 'column',
                  fontSize:      '2.7rem',
                  marginBottom:  '1.8rem',
                }}
              >
                <Icon width={200} height={40} />
                {/*
                  <div>{a.carrier}</div>
                  <div style={{ fontSize: '50%' }}>{a.public_id}</div>
                */}
              </Grid>
            );
          })}
        </Grid>
        <div className="v-text" style={{ background: 'none' }}>
          My Custom Accounts
        </div>
        <div className="v-padding-bottom-2">
          <Button onClick={this.newAccount.bind(this)}>Create new Carrier Account</Button>
        </div>
        <Table noScroll data={customAccounts} config={getTableConfig.call(this)} tableClass="v-table-payment-log" />
      </div>
    );
  }
}

export default connect(({ carrierAccounts }) => {
  const customAccounts  = [];
  const defaultAccounts = [];
  carrierAccounts.forEach((ca) => (ca.custom ? customAccounts.push(ca) : defaultAccounts.push(ca)));
  return { customAccounts, defaultAccounts };
})(CarrierAccounts);
