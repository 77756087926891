import React from 'react';
import { Button } from '../../components/Button';
import { formatCurrency, formatDate } from '../../helpers/data';
import { IconDownload } from '../../helpers/icons';

export default function tableConfig() {
  return [
    {
      style:     { minWidth: '7rem' },
      title:     '#',
      field:     'id',
      noEllipse: true,
      cell:      (value) => value.toString().padStart(6, 0),
    },
    {
      style:     { minWidth: '9rem' },
      title:     'FROM',
      field:     'from',
      noEllipse: true,
      cell:      (value) => formatDate(value),
    },
    {
      style:     { minWidth: '9rem' },
      title:     'TO',
      field:     'to',
      noEllipse: true,
      cell:      (value) => formatDate(value),
    },
    {
      style:     { minWidth: '9rem' },
      title:     'DUE',
      field:     'due',
      noEllipse: true,
      cell:      (value) => formatDate(value),
    },
    {
      style:     { minWidth: '10rem' },
      title:     'AMOUNT',
      field:     'amount',
      noEllipse: true,
      cell:      (value) => formatCurrency(value),
    },
    {
      style:     { minWidth: '10rem' },
      title:     'PAID',
      field:     'amount_paid',
      noEllipse: true,
      cell:      (value) => formatCurrency(value),
    },
    {
      style:     { minWidth: '10rem' },
      title:     'OUTSTANDING',
      field:     'amount_due',
      className: 'due',
      noEllipse: true,
      cell:      (value) => formatCurrency(value),
    },
    {
      style:     { minWidth: '11rem' },
      title:     'STATUS',
      field:     'status',
      className: 'status',
      noEllipse: true,
      cell:      (value) => value,
    },
    {
      title: 'ACTIONS',
      style: { minWidth: '18rem' },
      cell:  (data, row, col, object) => (
        <div className="v-center">
          <Button
            label="Pay"
            disabled={object.amount_due <= 0}
            small
            onClick={this.showPayInvoiceModal.bind(this, object.id, object.amount_due)}
          />
          <a href={object.report_download_url} className="v-margin-left-1rem">
            <IconDownload className="v-icon-button v-invoices-download" />
          </a>
        </div>
      ),
    },
  ];
}
