import React from 'react';
import { classList } from '../helpers/classList';

const Button = ({
  label,
  fullwidth,
  children,
  white,
  className,
  round,
  small,
  icon: Icon,
  onClick,
  disabled,
  propagate,
  ...rest
}) => (
  <span
    className={classList(
      'v-button',
      'v-center',
      'v-ellipsed',
      white && 'v-button-white',
      round && 'v-button-round',
      small && 'v-button-small',
      fullwidth && 'v-full-width',
      disabled && 'v-button-disabled',
      className
    )}
    onClick={(e) => {
      !propagate && e.stopPropagation();
      !disabled && onClick && onClick(e);
    }}
    {...rest}
  >
    {Icon && (
      <Icon
        className={classList(small ? 'v-icon-14' : 'v-icon-16', small ? 'v-margin-right-05rem' : 'v-margin-right-1rem')}
      />
    )}
    {!Icon && <span className={classList(small ? 'v-icon-14' : 'v-icon-16')} />}
    {label}
    {children}
  </span>
);

export { Button };
