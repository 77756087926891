import React from 'react';
import { Button } from '../../components/Button';
import { IconFedEx, IconUSPS, IconUPS } from '../../helpers/icons';

export const iconKey = {
  FedEx: IconFedEx,
  USPS:  IconUSPS,
  UPS:   IconUPS,
};

export function getTableConfig() {
  return [
    {
      title: 'Carrier',
      field: 'carrier',
      style: {
        maxWidth:       '14rem',
        textAlign:      'center',
        justifyContent: 'center',
      },
      cell: (data) => {
        const Icon = iconKey[data];
        return <Icon width={100} height={20} />;
      },
    },
    {
      title: 'Description',
      field: 'description',
      cell:  (data) => data,
    },
    {
      title: 'ID',
      field: 'public_id',
      cell:  (data) => data,
    },
    {
      title:     'Action',
      className: 'v-center',
      style:     { maxWidth: '14rem' },
      cell:      (data, row, col, all) => (
        <div className="v-center">
          {all.custom && <Button label="Edit" small white onClick={this.editCarrierAccount.bind(this, all)} />}
          &nbsp;
          {all.custom && <Button label="Delete" small white onClick={this.deleteCarrierAccount.bind(this, all.id)} />}
        </div>
      ),
    },
  ];
}

export const getDetailView = (data) => {
  return {
    Id:          data.id,
    Carrier:     data.carrier,
    Description: data.description,
    Custom:      data.custom ? 'YES' : '-',
    Default:     data.default ? 'YES' : 'NO',
  };
};
