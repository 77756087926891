import React from 'react';
import { classList } from '../../helpers/classList';
import { Hr } from '../../components/Hr';
import { Button } from '../../components/Button';
import { closeModal } from '../../helpers/modal';
import { Grid, Grid1 } from '../../components/Grids';
import { getVanloURL } from '../../resources/query';

export class ShipmentModal extends React.Component {
  render() {
    const { data, page, embed } = this.props;
    return (
      <div className={classList('v-modal-form v-width-80', 'v-center-column v-center-left')}>
        <div className="v-modal-heading v-no-margin">
          {data.tracking_code ? `${data.service} – ${data.tracking_code}` : 'Pending Shipment'}
        </div>
        <Grid className="v-margin-bottom-3">
          <Grid1>
            <div className="v-full-width">
              <div className="v-modal-heading">From</div>
              <div dangerouslySetInnerHTML={{ __html: data.from }} />
              <div className="v-modal-heading">To</div>
              <div dangerouslySetInnerHTML={{ __html: data.to }} />

              <div className="v-modal-heading">Parcel</div>

              <div>{data.lwh === 'N/A' ? 'Dimensions not specified' : data.lwh}</div>
              <div>{data.weight}</div>
              <div>{data.package}</div>
            </div>
          </Grid1>
          <Grid1>
            <div className="v-full-width v-margin-left-2rem">
              <div className="v-modal-heading">Status</div>
              <div>Rate: {data.price}</div>
              <div>Status: {data.status}</div>
              <div>Tracking: {data.tracking_code}</div>
              <div>Reference: {data.reference}</div>
              <div>{data.zone}</div>

              {data.label_url && (
                <>
                  <a
                    href={`${process.env.REACT_APP_TRACKING_PAGE_URL}/${data.tracking_code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="v-margin-top-2" fullwidth label="Tracking Page" />
                  </a>
                  <a href={data.status !== 'Refund Accepted' ? data.label_url : null} target="_blank" rel="noreferrer">
                    <Button
                      className="v-margin-top-2"
                      fullwidth
                      disabled={data.status === 'Refund Accepted'}
                      onClick={() => {}}
                      label="Open Label"
                    />
                  </a>
                  <Button
                    className="v-margin-top-2"
                    fullwidth
                    disabled={!data.refundable}
                    onClick={(e) => page.refund([data.id], e, true)}
                    label={data.refundable ? 'Request Refund' : 'Not Refundable'}
                  />
                </>
              )}

              {data.editable && page.editShipment && (
                <Button
                  className="v-margin-top-2"
                  fullwidth
                  label="Edit"
                  onClick={() => { page.editShipment(data)(); closeModal() }} />
              )}
            </div>
          </Grid1>
        </Grid>

        <Hr style={{ margin: '1rem 0' }} />
        {!embed && (
          <div className="v-modal-footer v-center-right">
            <Button className="v-padding-right-1" label="Close" onClick={closeModal} />
          </div>
        )}
      </div>
    );
  }
}
