import React from 'react';

class FallbackComponent extends React.Component {
  render() {
    return (
      <div className="v-page">
        <div className="card-header">
          <p>
            There was an error in loading this page.{' '}
            <span
              style={{ cursor: 'pointer', color: '#0077FF' }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload this page
            </span>{' '}
          </p>
        </div>
      </div>
    );
  }
}

export default FallbackComponent;
