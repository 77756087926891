import React from 'react';
import { Button } from '../../components/Button';

const DisputeButton = (props) => {
  if (props.transaction.kind !== 'adjustment') {
    return null;
  }

  return (
    // <Button small onClick={props.context.showDisputeModal(props.transaction)}>
    <Button small>Dispute</Button>
  );
};

export default function () {
  return [
    {
      title: 'Date (UTC)',
      field: 'created_at',
      style: { width: '14rem', flex: 'unset' },
      cell:  (data) => data,
    },
    {
      title: 'Amount',
      field: 'amount',
      cell:  (data) => (data === '0.00' ? '-' : data),
      style: { width: '10rem', flex: 'unset' },
    },
    {
      title: 'Description',
      field: 'description',
      cell:  (data, row, col, all) => data,
      style: { width: '20rem', flex: 'unset', fontSize: '1.2rem' },
    },
    {
      title:     'Actions',
      noEllipse: true,
      cell:      (data, row, col, all) => <DisputeButton context={this} transaction={all} />,
      //
    },
  ];
}
