const initialState = {
  transactions: [],
  total_count:  0,
};

export const externalTransactions = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EXTERNAL_TRANSACTIONS': {
      return action.payload;
    }
    default:
      return state;
  }
};
