const initialState = {
  lead_searches: [],
  filters:       {},
};

export const leadSearches = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_LEAD_SEARCHES':
      return { ...state, ...payload };
    default:
      return state;
  }
};
