import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { writeStorageData, readStorageData } from '../helpers/localStorage';
import { Button } from '.';

const DynamicButton = styled(Button)``;

const DarkModeSwitch = ({ server }) => {
  if (!readStorageData('darkMode')) writeStorageData('darkMode', 'system');

  const systemDark              = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [darkMode, setDarkMode] = useState(readStorageData('darkMode'));

  let seq;
  if (systemDark)
    seq = {
      system: ['light', 'Use Light Mode'],
      light:  ['dark', 'Use Dark Mode'],
      dark:   ['system', 'Use System Default'],
    };
  else
    seq = {
      system: ['dark', 'Use Dark Mode'],
      dark:   ['light', 'Use Light Mode'],
      light:  ['system', 'Use System Default'],
    };
  const [nextKey, nextLabel] = seq[darkMode];
  const whiteButton          = darkMode === 'light' || (darkMode === 'system' && !systemDark);

  const switchMode = () => {
    setDarkMode(nextKey);
    writeStorageData('darkMode', nextKey);
    if (systemDark && darkMode === 'dark') return;
    if (!systemDark && darkMode === 'light') return;
    window.location.reload();
  };

  return (
    <div style={{ paddingLeft: 'var(--sidebar-item-padding)', opacity: 0.8 }}>
      <Button small label={nextLabel} onClick={switchMode} white={whiteButton} style={{ width: '13rem' }} />
    </div>
  );
};

export { DarkModeSwitch };
