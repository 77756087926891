import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button } from '../../../components/Button';
import { closeModal } from '../../../helpers/modal';
import { Hr } from '../../../components/Hr';
import { PaymentMethodResource } from '../../../resources';

class CheckoutFormComponent extends React.Component {
  state = {};

  setBusy() {
    this.setState({ isBusy: !this.state.isBusy });
  }

  async submit() {
    this.setBusy();
    const response = await this.props.stripe.createToken({ name: 'add-card' });
    const data     = {
      payment_method: {
        type:             'BankCard',
        stripe_source_id: response.token.id,
      },
    };
    PaymentMethodResource.create(data).then(() => {
      this.setBusy();
      closeModal();
    });
  }

  render() {
    const { isBusy, error } = this.state;
    return (
      <div className="v-width-50">
        Please add your card information bellow.
        <br />
        <div className="v-stripe-comfort-message">
          We use <span className="v-stripe-highlight">Stripe</span> to work with your card information and never store
          it on our servers.
        </div>
        <div className="v-stripe-holder">
          <CardElement />
        </div>
        {error && (
          <div className="v-error-message">
            <br />
            {error}
          </div>
        )}
        <Hr className="v-hr-2" />
        <div className="v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
          <Button className="v-stripe-button" disabled={isBusy} label="Submit" onClick={this.submit.bind(this)} />
        </div>
      </div>
    );
  }
}

const CheckoutForm = injectStripe(CheckoutFormComponent);

export { CheckoutForm };
