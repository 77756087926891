import React from 'react';
import { classList } from '../../helpers/classList';
import { Hr } from '../../components/Hr';
import { Button } from '../../components/Button';
import { closeModal } from '../../helpers/modal';

const DataRow = ({ label, data }) => (
  <div className="v-table-data-row">
    <span className="v-table-data-row-label">{label}:</span>
    <b className="v-table-data-row-info">{data || `-`}</b>
  </div>
);

export default class PaymentLogModal extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className={classList('v-modal-form v-width-60', 'v-center-column v-center-left')}>
        {Object.keys(data)
          .map((key) => ({
            key,
            label: key,
            data:  data[key],
          }))
          .map((x) => (
            <DataRow {...x} />
          ))}
        <Hr />
        <div className="v-modal-footer v-center-right">
          <Button className="v-padding-right-1" label="Close" white onClick={closeModal} />
        </div>
      </div>
    );
  }
}
