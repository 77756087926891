import { find, isEqual, isEmpty, cloneDeep } from 'lodash';

export const StateManagementMixin = {
  // When the reducer or parent touches this component props (without re-adding the component)
  // setState must be called
  componentDidUpdate: function(prevProps, prevState) {
    if (!this.propsWillUpdate)
      throw new Error(`${this.constructor.name} must set propsWillUpdate`);
    if (find(this.propsWillUpdate, (prop) => !isEqual(prevProps[prop], this.props[prop])))
      this.refreshState();
  },

  // When the parent first adds to the DOM or restores this component
  // state must be assigned
  freshState: function() {
    throw new Error(`${this.constructor.name} must implement freshState`);
  },

  // When the reducer or parent touches this component props (without re-adding the component)
  // setState must be called
  refreshState: function() {
    this.setState(cloneDeep(this.freshState()));
  },

  // `itemPath` is an array of keys, indices, and/or find-functions applied to `state` in order, to locate an element.
  // It may also be a dot-join string of the above.
  // `valueOrMutation` is a value to set or a function supposed to mutate (not replace) the located element.
  updateStateElement: function(itemPath, valueOrMutation) {
    let item;
    const findItem = (path) => path.reduce((obj, locator) => {
      return (typeof locator === 'function' ? find(obj, locator) : obj[locator])
    }, this.state);

    if (typeof itemPath === 'string')
      itemPath = itemPath.split('.');

    if (typeof valueOrMutation === 'function') {
      item = findItem(itemPath);
      if (item) valueOrMutation(item);
    } else {
      item = findItem(itemPath.slice(0, -1));
      if (item) item[itemPath.slice(-1)[0]] = valueOrMutation;
    }

    this.setState({ [itemPath[0]]: this.state[itemPath[0]] });
  },

  updateStateElementProps: function(itemPath, props) {
    this.updateStateElement(itemPath, (item) => Object.assign(item, props));
  },

  addStateElement: function(parentPath, newElement) {
    this.updateStateElement(parentPath, (parent) => parent.push(newElement));
  },

  onInputChange: function({ target: { name, value } }) {
    this.updateStateElement(name, value);
  },

  saveRecord: function(item, busyMsg) {
    if (!this.resource) throw new Error(`${this.constructor.name} must set resource to use saveRecord`);

    if (item.id && item.id !== 'new') {
      return this.resource.update(item, busyMsg || 'Updating...');
    } else {
      return this.resource.create(item, busyMsg || 'Creating...');
    }
  }
}

// Mixin handler to be called in the constructor of the extended class
export const useStateManagement = (component) => {
  Object.entries(StateManagementMixin).forEach(([name, fn]) => {
    component[name] = component[name] || fn.bind(component);
  });
  component.state = component.freshState();
}
