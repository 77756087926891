import { resource } from './resource';
import { getFormattedDate, getPastDays } from '../components/Calendar';
import { queryVanlo } from './query';
import { readStorageData } from '../helpers/localStorage';

const route      = '/balance/transactions';
const storageKey = 'SET_BALANCE_TRANSACTIONS';
const filters    = {
  q:         null,
  per:       readStorageData('per') || 50,
  kind:      'all',
  page:      1,
  date_from: getFormattedDate(getPastDays(7)),
  date_to:   getFormattedDate(getPastDays(-1)),
};

function exportCSV(email) {
  const { page, per, ...rest } = this.filters;

  return queryVanlo(`/dashboard/balance/report`, 'POST', {
    ...rest,
    email,
  });
}

export const BalanceTransactionResource = resource({
  route,
  storageKey,
  filters,
  exportCSV,
});
