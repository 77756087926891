import React from 'react';
import { IconDownload } from '../../helpers/icons';
import { formatDate } from '../../helpers/data';

export default function tableConfig() {
  return [
    {
      title: 'id',
      field: 'public_id',
      style: { minWidth: '32rem' },
      cell:  (value) => value,
    },
    {
      title: 'carrier',
      field: 'carrier',
      style: { minWidth: '9rem' },
    },
    {
      title: 'Date',
      field: 'created_at',
      style: { minWidth: '12rem' },
      cell:  (value) => formatDate(value),
    },
    {
      title: 'Status',
      field: 'status',
      style: { minWidth: '12rem' },
      cell:  (value) => value,
    },
    {
      title: 'Shipments',
      field: 'shipments_count',
      style: { minWidth: '9rem', maxWidth: '9rem' },
      cell:  (value) => value,
    },
    {
      title: 'Warehouse Address',
      field: 'address',
      cell:  (value) => value,
    },
    {
      title: 'Form',
      field: 'form_url',
      style: { minWidth: '6rem', maxWidth: '6rem' },
      cell:  (value) => {
        if (!value) return '';

        return (
          <a href={value}>
            <IconDownload className="v-icon-button" />
          </a>
        );
      },
    },
  ];
}
