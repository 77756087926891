import React from 'react';
import { camelize, decamelize, pascalize } from 'humps';
import { map, find, escape } from 'lodash';
import { DropdownList, Combobox, NumberPicker } from 'react-widgets';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Title, Button, Select, Toggle, Grid, Grid1 } from '../../components';
import { IconAdd, IconDelete, IconClose } from '../../helpers/icons';

import {
  PresetList,
  PresetPreviewCard,
  PresetExplanation,
  PresetControls,
  PresetEditorForm,
} from './styles';
import {
  LogicGroupsWrapper,
  LogicGroupBox,
  LogicUnitRow,
  LogicUnitCard,
} from '../Automations/styles';

export default PresetManager;

function PresetManager({ presets, page }) {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 100, tolerance: 50 } }));
  return (
    <PresetList>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={page.reorderPresets}>
        <SortableContext items={presets} strategy={verticalListSortingStrategy}>
          {presets.map((preset) =>
            preset.editor ? (
              <PresetEditor {...preset} page={page} key={preset.id} />
            ) : (
              <PresetPreview {...preset} page={page} key={preset.id} />
            )
          )}
        </SortableContext>
      </DndContext>
    </PresetList>
  );
}

function PresetPreview({ id, name, description, page, ...data }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const maybeListeners = find(page.state.presets, 'editor') ? {} : listeners;
  const style          = { transition, transform: CSS.Transform.toString(transform), touchAction: 'none' };

  return (
    <PresetPreviewCard ref={setNodeRef} style={style} {...attributes} {...maybeListeners}>
      <div>{name}</div>
      <PresetExplanation dangerouslySetInnerHTML={{ __html: escape(description).replace('\n', '<br>') }} />
      <PresetControls>
        <Button small white label="Edit" onClick={(e) => page.editPreset(id)} />
        <span className='toggle-label'>Apply by default</span>
        <Toggle
          status={data.default}
          onClick={() => page.saveRecord({ id, default: !data.default })}
          disabled={!!find(page.state.presets, 'editor')}
        />
      </PresetControls>
    </PresetPreviewCard>
  );
}

function PresetEditor({ id, name, conditions, page }) {
  return (
    <PresetEditorForm>
      <Textbox
        placeholder="Name"
        value={name}
        onChange={(e) => page.updatePresetState(id, (x) => x.name = e.target.value)}
        style={{ width: '30rem' }}
      />
      <LogicGroup {...{ id, conditions, page }} />
      <PresetControls>
        <Button small white label="Save" onClick={() => page.savePreset(id)} />
        <Button small white label="Cancel" onClick={() => page.refreshState()} />
      </PresetControls>
    </PresetEditorForm>
  );
}

function LogicGroup({ id, conditions, page }) {
  return (
    <LogicGroupBox group='conditions' style={{ width: 'auto' }}>
      {conditions.map((unit, n) => <Condition {...{ id, n, page, ...unit }} />)}
      <Button white small label='Add Condition' onClick={() => page.addCondition(id)} />
    </LogicGroupBox>
  );
}

function Condition({ id, n, attribute, operator, reference, page }) {
  const update      = (name, value) => page.updateCondition(id, n, name, value.attr || value);
  const dict        = page.state.filters;
  const properties  = attribute && dict[attribute];
  const numberRange = (typeof properties?.options === 'string' && properties.options.split('..')) || [];

  return (
    <LogicUnitRow>
      {n > 0 ? <b>AND</b> : <b>WHERE</b>}
      <LogicUnitCard type="condition" style={{ width: 'auto' }}>
        <DropdownList
          data={map(dict, (props, attr) => ({ attr, ...props }))}
          dataKey="attr"
          textField="name"
          value={attribute}
          onChange={(value) => update('attribute', value)}
          style={{ width: '25rem' }}
        />
        {attribute && (
          <DropdownList
            data={Object.keys(properties.operators)}
            value={operator}
            onChange={(value) => update('operator', value)}
            style={{ width: '11rem' }}
          />
        )}
        {properties?.type === 'enum' && (
          <DropdownList
            data={properties.options}
            value={reference}
            onChange={(value) => update('reference', value)}
            style={{ width: '15rem' }}
          />
        )}
        {properties?.type === 'number' && (
          <NumberPicker
            min={numberRange[0] ? parseInt(numberRange[0], 10) : null}
            max={numberRange[1] ? parseInt(numberRange[1], 10) : null}
            value={reference}
            onChange={(value) => update('reference', value)}
            style={{ width: '15rem' }}
          />
        )}
        {properties?.type?.match(/string/) && (
          <Textbox value={reference} onChange={(e) => update('reference', e.target.value)} style={{ width: '25rem' }} />
        )}
        <IconClose onClick={() => page.deleteCondition(id, n)} className="v-icon-button" />
      </LogicUnitCard>
    </LogicUnitRow>
  );
}

// React-widgets doesn't have a public basic input. This is how it would look like.
// This way it matches all the other inputs' look.
function Textbox(params) {
  return (
    <div className="rw-widget rw-widget-textbox" style={params.style}>
      <div className="rw-widget-picker" style={{ gridTemplate: 'none' }}>
        <input type="text" autoComplete="off" className="rw-widget-input rw-input" {...params} />
      </div>
    </div>
  );
}
