import React from 'react';
import { connect } from 'react-redux';

const Component = (props) => (
  <div className="v-fullscreen v-center noselect v-welcome-screen">
    <span className="v-welcome-username">{props.name}</span>
    <span className="v-welcome-logo">V</span>
    <span className="v-welcome-text">Welcome to Vanlo</span>
  </div>
);

const mapStateToProps = (state) => ({ name: state.user.name || 'Loading...' });

export default connect(mapStateToProps)(Component);
