import { camelize, decamelize, camelizeKeys, decamelizeKeys } from 'humps';

import { queryVanlo } from './query';
import { store } from '../store';
import { closeModal, showBusyMessage, showErrorMessage, showTextMessage } from '../helpers/modal';

class Resource {
  namespace = '/dashboard';

  route = '/';

  storageKey = '';

  filters = null;

  constructor(props) {
    Object.entries(props).forEach(([key, value]) => this.setVal(key, value));
    if (props.name) {
      this.route      = `/${decamelize(props.name)}`;
      this.storageKey = `SET_${decamelize(props.name).toUpperCase()}`;
      this.paramName  = decamelize(props.name).replace(/s$/, '');
    }
  }

  list(busyMsg = 'Fetching data...') {
    const promise = queryVanlo(this.namespace + this.route, 'GET', null, this.filters);
    if (busyMsg) setTimeout(() => showBusyMessage(busyMsg, promise), 0);

    promise.then((result) => {
      if (!result || result.success === false) {
        setTimeout(() => showErrorMessage(result.message || 'Something went wrong'), 250);
      } else {
        result && store.dispatch({ type: this.storageKey, payload: result });
      }
    });
    return promise;
  }

  get(id, busyMsg = 'Fetching data...') {
    const promise = queryVanlo(`${this.namespace}${this.route}/${id}`, 'GET');
    if (busyMsg) setTimeout(() => showBusyMessage(busyMsg, promise), 0);

    promise.then((result) => {
      if (!result || result.success === false || result.error) {
        setTimeout(() => showErrorMessage(result.message), 250);
      }
    });
    return promise;
  }

  post(action, busyMsg = 'Sending request...') {
    const promise = queryVanlo(`${this.namespace}${this.route}/${action}`, 'POST');
    if (busyMsg) setTimeout(() => showBusyMessage(busyMsg, promise), 0);

    promise.then((result) => {
      if (!result || result.success === false || result.error) {
        setTimeout(() => showErrorMessage(result.message), 250);
      }
    });
    return promise;
  }

  create(data, busyMsg = 'Creating...', callbacks, runAndReload = true) {
    if (!(data instanceof FormData) && this.paramName)
      data = { [this.paramName]: data, ...data }; // wrap when possible

    const promise = queryVanlo(`${this.namespace}${this.route}`, 'POST', data);
    if (runAndReload) return this.runAndReload(promise, busyMsg, callbacks);

    if (busyMsg) setTimeout(() => showBusyMessage(busyMsg, promise), 0);
    return promise;
  }

  update({ id, ...data }, busyMessage = 'Updating...', callbacks) {
    if (!(data instanceof FormData) && this.paramName)
      data = { [this.paramName]: data, ...data }; // wrap when possible

    let retVal;
    if (id) {
      retVal = queryVanlo(`${this.namespace}${this.route}/${id}`, 'PATCH', data);
    } else {
      retVal = queryVanlo(`${this.namespace}${this.route}`, 'PATCH', data);
    }
    return this.runAndReload(retVal, busyMessage, callbacks);
  }

  delete(id, busyMessage = 'Deleting...') {
    return this.runAndReload(queryVanlo(`${this.namespace}${this.route}/${id}`, 'DELETE'), busyMessage);
  }

  runAndReload(actionOrCall, busyMessage = 'Processing...', callbacks = {}) {
    let resolve;
    const promise = new Promise((r) => (resolve = r));
    if (busyMessage) showBusyMessage(busyMessage, promise);

    if (!actionOrCall.then) actionOrCall = this.post(actionOrCall, busyMessage);

    const reload = ({ success, message }) => {
      if (success) {
        closeModal();
        if (message) {
          showTextMessage(message);
        }
        this.list(false).then(resolve);
      } else (callbacks.onError || showErrorMessage)(message);
    };

    actionOrCall.then(reload);
    promise.then(callbacks.onSuccess);
    return actionOrCall;
  }

  setFilter(name, value) {
    if (!this.filterStash) this.filterStash = { ...this.filters };
    this.filters[name] = value;
  }

  resetFilters() {
    if (this.filterStash) this.filters = { ...this.filterStash };
  }

  setVal(key, val) {
    this[key] = val;
  }
}

export const resource = (params) => new Resource(params);
