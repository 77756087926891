import React from 'react';

export const sortById = (w1, w2, field) => {
  const id   = field || 'id';
  const comp = Number(w1[id]) > Number(w2[id]);
  return comp ? 1 : -1;
};

export const getFullAddress = (wh) => [wh.street1, wh.city, wh.state, wh.zip].filter((x) => x).join(', ');
export const getWithAddressCalculated = (data) => ({
  ...data,
  fullAddress: getFullAddress(data),
});

export const flattenObject = (object, prefix) => {
  const result = {};
  Object.keys(object).forEach((key) => {
    result[prefix ? `${prefix}--${key}` : key] = object[key];
  });
  return result;
};

const formatter = new Intl.NumberFormat('en-US', {
  style:    'currency',
  currency: 'USD',
});

export const getNumber = (value) => Number(`${value}`.replace(/[^\d.-]/g, '') || 0);
export const formatCurrency = (input, isAddDecoration, keepSuffix) => {
  const parts = `${input} `.split(' ');
  const str   = parts.shift();
  const res   = formatter.format(getNumber(str || 0));
  if (isAddDecoration) {
    return (
      <span>
        {res.substring(0, res.length - 3)}
        <span style={{ opacity: 0.4 }}>{res.substring(res.length - 3)}</span>
      </span>
    );
  }
  return keepSuffix ? `${res} ${parts.join(' ')}` : res;
};

export const formatNumber = (input) => {
  return Number(input).toFixed(0);
};

export const isValidEmail = (email) => /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const formatDate = (date) =>
  new Date(date).toLocaleDateString('en-US', {
    year:  'numeric',
    month: '2-digit',
    day:   '2-digit',
  });
