import React from 'react';
import { resource } from './resource';
import { queryVanlo } from './query';
import { showBusyMessage, showErrorMessage, showMessage } from '../helpers/modal';

const route      = '/payment_methods';
const storageKey = 'SET_PAYMENT_METHODS';

function verify(id, amounts) {
  const promise = queryVanlo(`/dashboard/payment_methods/${id}/verify`, 'PUT', {
    amounts,
  });
  showBusyMessage('Verifying account...', promise);
  promise.then(({ success, message }) => {
    if (success) {
      this.list();
      showMessage(<div className="v-body-content">Account was verified successfully.</div>, null, true);
    } else {
      showErrorMessage(message);
    }
  });
  return promise;
}

function linkToken() {
  const promise = queryVanlo(`/dashboard/payment_methods/link_token`, 'POST');

  return promise;
}

export const PaymentMethodResource = resource({
  route,
  storageKey,
  verify,
  linkToken,
});
