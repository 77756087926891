import axios from 'axios';
import { store } from '../store';

export const tryTokenLogin = (host, nextHosts, token) => {
  let resolve,
   reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject  = rej;
  });
  if (!host) {
    setTimeout(reject, 100);
    return promise;
  }
  axios({
    method:  'GET',
    url:     `${host[1]}/dashboard/api_keys/${token}`,
    headers: {
      Accept:         'application/json',
      'Content-Type': 'application/json',
    },
  }).then((r) => {
    if (r.data.key && r.data.key !== 'expired') {
      store.dispatch({ type: 'SET_SESSION', payload: r.data.key });
      setTimeout(() => {
        store.dispatch({ type: 'SET_SERVER', payload: host[0] });
      }, 100);
      resolve();
    } else {
      tryTokenLogin(nextHosts[0], nextHosts.slice(1), token);
    }
  });
  return promise;
};
