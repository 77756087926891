import { resource } from './resource';

const route      = '/lead_searches';
const storageKey = 'SET_LEAD_SEARCHES';
const filters    = {
  per:  50,
  page: 1,
};

export const LeadSearchesResource = resource({
  route,
  storageKey,
  filters,
});
