import React from 'react';
import { connect } from 'react-redux';
import { Title } from '../../components/Title';
import { classList } from '../../helpers/classList';
import { Card } from '../../components/Card';
import { Grid, Grid1 } from '../../components/Grids';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { showModal } from '../../helpers/modal';
import { StripeAgreement } from './StripeAgreement';
import { BusinessInformationResource } from '../../resources';

const openAgreement = () => {
  showModal({
    title:     'Stripe Services Agreement',
    component: StripeAgreement,
  });
};

class BusinessInformation extends React.Component {
  state = {};

  update() {
    BusinessInformationResource.create({ tos_acceptance: this.state.tosAccepted ? '1' : false }, 'Submitting...').then(
      (r) => {
        if (r.location) window.location.href = r.location;
      }
    );
  }

  toggleTosAccepted() {
    this.setState({ tosAccepted: !this.state.tosAccepted });
  }

  render() {
    const {
      businessInformation: { billing_enabled, tos_accepted },
    } = this.props;
    const { tosAccepted } = this.state;
    const subtitle        = billing_enabled
      ? 'You can update your business account details'
      : 'We need you to update your business account details';
    return (
      <div className={classList('v-awaiting-shipments', 'v-page')}>
        <Title id="business-information" title="Business Information" subtitle={subtitle} />
        <Grid>
          <Grid1>
            <Card>
              <p className="v-margin-bottom-3">You will be redirected to Stripe</p>
              {!tos_accepted && (
                <div className="v-hr-3">
                  <Checkbox
                    checked={tosAccepted}
                    onChange={this.toggleTosAccepted.bind(this)}
                    label={
                      <div>
                        Accept the{' '}
                        <a href="#business-information" onClick={openAgreement}>
                          Stripe Services Agreement
                        </a>
                      </div>
                    }
                  />
                </div>
              )}
              <Button
                disabled={!tos_accepted && !tosAccepted}
                fullwidth
                label="Update"
                onClick={this.update.bind(this)}
              />
            </Card>
          </Grid1>
          <Grid1 />
          <Grid1 />
        </Grid>
      </div>
    );
  }
}

export default connect(({ businessInformation }) => ({ businessInformation }))(BusinessInformation);
