import React from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty, isMatch } from 'lodash';
import { Title, Grid, Grid1, Grid2, Select, Button, Textbox, Pagination, Card, Checkbox } from '../../components';
import { ShipmentUploadsResource, ShipmentUploadMappingsResource } from '../../resources';
import { getVanloURL } from '../../resources/query';
import { showTextMessage } from '../../helpers/modal';

const MappingControls = styled(Grid)`
  margin-top: 0.75rem;
  width: 50%;
  min-width: 70rem;
`;
const UploadHandler   = styled(Grid2)`
  position: relative;
  top: 2.7rem;
`;
const MappingAction   = (whatever) => <Button white className="v-margin-left-2rem" {...whatever} />;

const MappingContainer = styled(Card)`
  margin-top: 2rem;
  margin-right: 0;
  min-width: 70rem;
`;
const CustomValue      = styled(Textbox)`
  padding-left: 1rem;
  background: #f7f7f7;
  margin-bottom: 0;
  margin-top: 10px;
`;

const SaveButtonContainer = styled.div`
  position: sticky;
  height: 10rem;
  top: 96%;
  margin-left: 2rem;
`;
const SaveButtonSubtitle  = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  color: #888888;
  margin-left: 1.35rem;
`;

class ShipmentUploadMappings extends React.Component {
  fileInput = React.createRef();

  state = {
    selectedMapping: {
      dictionary:        {},
      dictionaryChanges: {},
      name:              '',
    },
  };

  componentDidUpdate(prevProps, prevState) {
    const reducer = this.props.shipmentUploadMappings;
    if (reducer?.mappings?.length && isEmpty(prevState.selectedMapping?.dictionary)) {
      this.selectMapping({ target: { value: reducer.mappings[0].id } });
    }
  }

  // Commands

  change = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      selectedMapping: {
        ...prevState.selectedMapping,
        dictionaryChanges: {
          ...prevState.selectedMapping.dictionaryChanges,
          [name]: value,
        },
      },
    }));
  };

  changeName = (event) => {
    const { value } = event.target;

    this.setState((prevState) => ({
      selectedMapping: {
        ...prevState.selectedMapping,
        name: value,
      },
    }));
  };

  selectMapping = (event) => {
    const { value } = event.target;
    const {
      shipmentUploadMappings: { mappings },
    } = this.props;

    this.setState({
      selectedMapping: mappings.find((mapping) => mapping.id === value) || mappings[0],
    });
  };

  // Queries

  currentMappingName = () => {
    const { name } = this.state.selectedMapping;
    return name === 'New Mapping' ? '' : name;
  };

  isMappingUnchanged = () => {
    const sm = this.state.selectedMapping;
    return isMatch(sm.dictionary, { ...sm.dictionary, ...sm.dictionaryChanges });
  };

  // User Actions

  saveMapping = () => {
    const { id, name, dictionary, dictionaryChanges } = this.state.selectedMapping;
    const params                                      = { name, dictionary: { ...dictionary, ...dictionaryChanges } };
    this.setState({ selectedMapping: { id, ...params } });

    if (id) {
      ShipmentUploadMappingsResource.update({ id, shipment_upload_mapping: params });
    } else {
      ShipmentUploadMappingsResource.create({
        shipment_upload_mapping: params,
      }).then((r) =>
        this.setState((prevState) => ({
          selectedMapping: {
            ...prevState.selectedMapping,
            id: r.mapping.id,
          },
        }))
      );
    }
  };

  copyMapping = () => {
    const newMapping = { name: 'New Mapping', dictionary: this.state.selectedMapping.dictionary };
    this.props.shipmentUploadMappings.mappings.unshift(newMapping);
    this.setState({ selectedMapping: newMapping });
    document.getElementsByClassName('v-page')[0].scrollTo(0, 0, true);
    document.getElementsByName('name')[0].focus();
  };

  getTemplate = (mapping) => {
    !!mapping.id && ShipmentUploadMappingsResource.download(mapping.id, `${mapping.name} template.csv`);
  };

  // Render

  renderFields = () => {
    const mapping = this.state.selectedMapping;
    if (!mapping) return;

    const dictionary = { ...mapping.dictionary, ...mapping.dictionaryChanges };

    return Object.keys(dictionary).map((field) => (
      <Grid key={field}>
        <Grid1>
          <h4>{field}</h4>
        </Grid1>
        <Grid1>
          <CustomValue
            name={field}
            placeholder={field}
            value={dictionary[field]}
            onChange={this.change}
            disabled={mapping.default}
          />
        </Grid1>
      </Grid>
    ));
  };

  render() {
    const { mappings }                 = this.props.shipmentUploadMappings;
    const { selectedMapping: mapping } = this.state;

    return (
      <div className={`v-page ${this.props.className}`}>
        <Title title="Shipment Upload Mappings" />

        <MappingControls>
          <Grid1>
            <Select
              label="Mapping"
              items={mappings.map((mapping) => ({ label: mapping.name, value: mapping.id }))}
              value={mapping.id}
              onChange={this.selectMapping}
            />
          </Grid1>
          <UploadHandler>
            <MappingAction onClick={this.copyMapping} disabled={!mapping.id} title="Create a new mapping based on this">
              Copy
            </MappingAction>
            <MappingAction disabled={!mapping.id} onClick={() => this.getTemplate(mapping)}>
              Get template
            </MappingAction>
          </UploadHandler>
        </MappingControls>

        <Grid>
          <Grid1>
            <MappingContainer title="Mapping">
              <Textbox
                name="name"
                label="Name"
                value={this.currentMappingName()}
                onChange={this.changeName}
                disabled={mapping.default}
              />
              {this.renderFields()}
            </MappingContainer>
          </Grid1>
          <Grid1>
            <SaveButtonContainer>
              <Button white onClick={this.saveMapping} disabled={mapping.default || !this.currentMappingName()}>
                Save
              </Button>
            </SaveButtonContainer>
          </Grid1>
        </Grid>
      </div>
    );
  }
}

export default connect(({ shipmentUploadMappings }) => ({ shipmentUploadMappings }))(ShipmentUploadMappings);
