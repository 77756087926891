import React from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty, isMatch } from 'lodash';
import {
  Title,
  Grid,
  Grid1,
  Grid2,
  Select,
  Button,
  Textbox,
  Pagination,
  Card,
  Table,
  TableFiltersComponent,
  Checkbox,
} from '../../components';
import {
  ShipmentUploadsResource,
  ShipmentUploadMappingsResource,
  ReadyShipmentsResource,
  ShipmentResource,
} from '../../resources';
import { showTextMessage } from '../../helpers/modal';

import tableConfig from './tableConfig';

const MappingControls = styled(Grid)`
  margin-top: 0.75rem;
  width: 50%;
  min-width: 70rem;
`;
const UploadHandler   = styled(Grid2)`
  position: relative;
  top: 2.7rem;
`;
const HiddenInput     = styled.input`
  display: none;
`;

class ShipmentUploads extends React.Component {
  fileInput = React.createRef();

  state = {
    selectedMapping: {
      dictionary:        {},
      dictionaryChanges: {},
      name:              '',
    },
    ...ShipmentUploadsResource.filters,
  };

  // Mapping

  componentDidUpdate(prevProps, prevState) {
    const reducer = this.props.shipmentUploadMappings;
    if (reducer?.mappings?.length && isEmpty(prevState.selectedMapping?.dictionary)) {
      this.selectMapping({ target: { value: reducer.mappings[0].id } });
    }
  }

  selectMapping = (event) => {
    const { value } = event.target;
    const {
      shipmentUploadMappings: { mappings },
    } = this.props;

    this.setState({
      selectedMapping: mappings.find((mapping) => mapping.id === value) || mappings[0],
    });
  };

  upload = (event) => {
    const file     = event.target.files[0];
    const formData = new FormData();

    const { id } = this.state.selectedMapping;

    if (file) {
      formData.append('file', file);
      formData.append('id', id);
      formData.append('buy', this.state.buy);

      ShipmentUploadsResource.create(formData);
    }
  };

  clickFileInput = () => {
    this.fileInput.current.click();
  };

  // Table

  setPage = (page) => {
    if (page !== this.state.page) {
      this.setState({ page });
      ShipmentUploadsResource.setFilter('page', page);
      ShipmentUploadsResource.list();
    }
  };

  rateShop = (id) => {
    ReadyShipmentsResource.setFilter('upload_id', id);
    ReadyShipmentsResource.list();
    this.props.history.push('/shipments/buy-labels');
  };

  viewShipments = (search) => {
    ShipmentResource.setFilter('q', search);
    ShipmentResource.list();
    this.props.history.push('/shipments');
  };

  delete = (id) => {
    ShipmentUploadsResource.delete(id);
  };

  render() {
    const { mappings }                            = this.props.shipmentUploadMappings;
    const { total_count, shipment_uploads }       = this.props.shipmentUploads;
    const { page, per, selectedMapping: mapping } = this.state;

    return (
      <div className={`v-page ${this.props.className}`}>
        <Title title="Shipment Uploads" />

        <MappingControls>
          <Grid1>
            <Select
              label="Mapping"
              items={mappings.map((mapping) => ({ label: mapping.name, value: mapping.id }))}
              value={mapping.id}
              onChange={this.selectMapping}
            />
          </Grid1>
          <UploadHandler className="v-margin-_-2rem">
            <HiddenInput type="file" ref={this.fileInput} onChange={this.upload} />
            <Checkbox
              checked={this.state.buy}
              onChange={(e) => this.setState({ buy: e.target.value })}
              label="Auto-Buy Labels"
              title="for shipments with service filled in"
            />
            <Button onClick={this.clickFileInput}>Upload Using Mapping</Button>
          </UploadHandler>
        </MappingControls>

        <TableFiltersComponent
          resource={ShipmentUploadsResource}
          display={['dates']}
          onChange={(p) => this.setState({ ...p })}
        />
        <Table data={shipment_uploads} config={tableConfig.call(this)} context={this} />
        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage} />
      </div>
    );
  }
}

export default withRouter(
  connect(({ shipmentUploads, shipmentUploadMappings }) => ({ shipmentUploads, shipmentUploadMappings }))(
    ShipmentUploads
  )
);
