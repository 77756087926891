import React from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import 'react-widgets/styles.css';

const flexbox = ({ p, g }) => css`
  display: flex;
  flex-wrap: wrap;
  gap: ${g}rem;
  padding: ${p}rem;
`;

const PresetList        = styled.div`
  ${flexbox({ g: 1 })}
  flex-direction: column;
`;
const PresetPreviewCard = styled.div`
  padding: 1rem;
  background: #eee;
`;
const PresetExplanation = styled.div`
  padding: 1rem;
  background: #ddd;
  font-family: monospace;
  margin: 0.5rem 0 1rem 0;
  width: fit-content;
`;
const PresetControls    = styled.div`
  ${flexbox({ g: 0.5 })}
  align-content: flex-start;
  .toggle-label {
    line-height: 2.4rem;
    color: #555555;
  }
  .rw-widget {
    width: 12rem;
    display: inline-flex;
    font-size: 90%;
    .rw-widget-picker {
      min-height: 2.5rem;
    }
  }
`;
const PresetEditorForm  = styled.div`
  ${flexbox({ g: 1 })}
  flex-direction: column;
  padding: 1rem;
  background: #eee;
  b {
    text-align: center
  }
`;

export {
  PresetList,
  PresetPreviewCard,
  PresetExplanation,
  PresetControls,
  PresetEditorForm,
};
