import React from 'react';
import { store } from '../store';
import { DataGrid } from '../components';

let modalLayers = 0;

export function showModal({ title, payload, component, extra, closeButton, callback }) {
  modalLayers++;
  // console.log('++', modalLayers)
  const params = {
    getTitle: () => title,
    getBody:  (args) => ({
      extra,
      component,
      payload: { ...args, ...payload },
      closeButton,
      callback,
    }),
  };
  store.dispatch({ type: 'MODAL_SHOW', payload: params });
}

export function showErrorMessage(message, callback) {
  if (typeof message === 'object' && !message.$$typeof) message = <DataGrid rows={[message]} />;
  showMessage(null, <div style={{ maxWidth: '40rem' }}>{message}</div>, false, callback, {
    className: 'v-error-message',
  });
}

export function showTextMessage(message, callback) {
  if (typeof message === 'object' && !message.$$typeof) message = <DataGrid rows={[message]} />;
  showMessage(<div style={{ maxWidth: '40rem' }}>{message}</div>, null, true, callback);
}

export function showBusyMessage(message, promise) {
  promise.then(() => {
    modalLayers--;
    // console.log('--', modalLayers)
    if (modalLayers <= 0) {
      modalLayers = 0;
      store.dispatch({ type: 'MODAL_HIDE' });
    }
  });
  showModal({
    extra:       { hideTitlebar: true },
    title:       null,
    payload:     null,
    component:   () => <div style={{ padding: '5rem', textAlign: 'center' }}>{message}</div>,
    closeButton: false,
  });
}

export function showMessage(successText, failureText, isSuccess, callback, extra) {
  const message = isSuccess ? successText || `Operation was successful!` : failureText || 'Operation failed!';
  showModal({
    extra,
    title:       isSuccess ? (extra && extra.successTitle) || 'Success' : 'Failure',
    payload:     null,
    component:   () => message,
    closeButton: true,
    callback,
  });
}

export function closeModal() {
  modalLayers--;
  store.dispatch({ type: 'MODAL_HIDE' });
}
