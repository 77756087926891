const initialState = {
  mappings: [],
  first:    {},
};

export const shipmentUploadMappings = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHIPMENT_UPLOAD_MAPPINGS':
      return action.payload;
    default:
      return state;
  }
};
