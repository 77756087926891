import { flattenObject } from '../helpers/data';

const address = {
  street_name: '',
  city:        '',
  state:       '',
  zip:         '',
};

const user = {
  name:               '',
  email:              '',
  phone:              '',
  billing_eq_contact: true,
};

export const userBillingInformationFields = {
  ...flattenObject(user, 'user'),
  ...flattenObject(address, 'billing_address'),
  ...flattenObject(address, 'contact_address'),
};

export const userBillingInformation = (state = userBillingInformationFields, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'STORE_USER_BILLING_INFORMATION': {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
