import { resource } from './resource';
import { queryVanlo } from './query';
import { readStorageData } from '../helpers/localStorage';
import { showBusyMessage, showErrorMessage } from '../helpers/modal';

export const ReadyShipmentsResource = resource({
  route:      '/shipments/ready',
  storageKey: 'SET_READY_SHIPMENTS',
  filters:    { per: readStorageData('per') || 50, page: 1, upload_id: 'all' },
});
