import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Title, Select, Button, Textbox } from '../../components';
import { ReportSchedulesResource } from '../../resources';

import { showErrorMessage } from '../../helpers/modal';

class NewReportSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  change = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  submit = () => {
    const { email, reportType, period, transactionType } = this.state;
    const { history }                                    = this.props;

    ReportSchedulesResource.create(
      {
        email,
        report_type: reportType,
        period,
        params:      { kind: transactionType },
      },
      'Creating...',
      {
        onSuccess: () => {
          history.push('/scheduled-reports');
        },
        onError: (r) => {
          showErrorMessage(r.message);
        },
      }
    ).then((r) => {
      if (!r.success) {
        showErrorMessage(r.message);
      }
    });
  };

  render() {
    const { email = this.props.user.email, reportType, period, transactionType } = this.state;
    const { options }                                                            = this.props.reportSchedules;

    return (
      <div className="v-page">
        <Title title="New Scheduled Report" />
        <h3>Recipient Email</h3>
        <Textbox
          onChange={this.change}
          value={email}
          name="email"
          placeholder="hi@example.com"
          className="v-width-225"
        />
        <h3>Report Type</h3>
        <Select
          name="reportType"
          onChange={this.change}
          value={reportType}
          items={options.report_types.slice(1).map(([label, value]) => ({ label, value }))}
          className="v-padding-right-3 v-select-small-no-border v-width-225"
        />
        <h3>
          Period&nbsp;
          <span className="v-hint">
            (Daily Reports are sent noon UTC (end of day). Weekly reports – Monday noon UTC. Monthly reports – 1st day
            of each month)
          </span>
        </h3>
        <Select
          name="period"
          onChange={this.change}
          value={period}
          items={options.periods.slice(1).map(([label, value]) => ({
            label,
            value,
          }))}
          className="v-padding-right-3 v-select-small-no-border v-width-225"
        />
        {reportType === 'dashboard_balances' && (
          <>
            <h3>Transaction Type</h3>
            <Select
              items={options['params.kinds'].slice(1).map(([label, value]) => ({ label, value }))}
              className="v-padding-right-3 v-select-small-no-border v-width-225"
              onChange={this.change}
              value={transactionType}
              name="transactionType"
            />
          </>
        )}
        <div>
          <Button className="v-margin-top-2" onClick={this.submit} disabled={!reportType || !period || !email}>
            Create
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(({ reportSchedules, user }) => ({ reportSchedules, user }))(NewReportSchedule));
