import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Textbox, Select, Grid, Grid1, Title, Table, Pagination } from '../../components';
import { ScanFormsResource } from '../../resources';
import tableConfig from './tableConfig';

class ScanForms extends React.Component {
  constructor(props) {
    super(props);

    this.state = { carrier: 'USPS', upToTime: this.nowInEST(), ...ScanFormsResource.filters };
  }

  setPage = (page) => {
    if (page !== this.state.page) {
      this.setState({ page });
      ScanFormsResource.setFilter('page', page);
      ScanFormsResource.list();
    }
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  createManifest = () => {
    const { carrier, reference, upToTime } = this.state;

    if (window.confirm(`Are you sure you want to manifest ${this.scopeDescription()}?`)) {
      ScanFormsResource.create({ carrier, reference, up_to_time: upToTime }).then((r) => {
        this.setState({ reference: '', upToTime: this.nowInEST() });

        if (r.success) {
          ScanFormsResource.list();
        }
      });
    }
  };

  nowInEST = () => moment().utcOffset(-4).format();

  scopeDescription = () => {
    return this.state.carrier === 'USPS'
      ? 'All available, un-manifested shipments with the provided reference created before the provided time'
      : 'All available, un-manifested Ground shipments created before the provided time';
  };

  render() {
    const {
      scanForms: { scan_forms, total_count },
    } = this.props;
    const { page, per, carrier, reference, upToTime } = this.state;

    return (
      <div className="v-page">
        <Title title="Scan Forms" />
        <Grid className="v-left" style={{ maxWidth: '128rem' }}>
          <Grid1 className="manifest-form">
            <Select
              name="carrier"
              inline
              onChange={this.handleChange}
              value={carrier}
              items={[
                { label: 'USPS', value: 'USPS' },
                { label: 'FedEx', value: 'FedEx' },
              ]}
              className="v-padding-right-3 v-select-small-no-border"
            />
            <Textbox
              placeholder="Reference"
              name="reference"
              value={reference}
              disabled={carrier === 'FedEx'}
              onChange={this.handleChange}
              style={{ border: '1px solid #aaa', borderRadius: '4px', width: '20rem', marginLeft: '1rem' }}
            />
            <Textbox
              placeholder={this.nowInEST()}
              name="upToTime"
              value={upToTime}
              onChange={this.handleChange}
              style={{
                fontWeight:   400,
                border:       '1px solid #aaa',
                borderRadius: '4px',
                width:        '22rem',
                marginLeft:   '1rem',
              }}
              title="USPS operates in EST (UTC-4). If you don't pass a timezone, it's considered UTC"
            />
            <Button onClick={this.createManifest}>Create Manifest</Button>
            <div>
              <h5 className="manifest-subtitle">{`${this.scopeDescription()} will be included.`}</h5>
            </div>
          </Grid1>
        </Grid>
        <Table
          noScroll
          data={scan_forms}
          context={this}
          config={tableConfig.call(this)}
          tableClass="v-table-invoices"
          trClass={this.trClass}
        />

        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage} />
      </div>
    );
  }
}

export default connect(({ scanForms }) => ({ scanForms }))(ScanForms);
