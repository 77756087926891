import { saveAs } from 'file-saver';
import { resource } from './resource';

const route      = '/shipment_upload_mappings';
const storageKey = 'SET_SHIPMENT_UPLOAD_MAPPINGS';
function download(id, name) {
  this.get(id).then((result) => result && !result.error && saveAs(new Blob([result]), name));
}

export const ShipmentUploadMappingsResource = resource({
  route,
  storageKey,
  download,
});
