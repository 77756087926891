import React from 'react';
import { classList } from '../helpers/classList';

const Toggle = ({ status, className, onClick, disabled, ...rest }) => (
  <span
    className={classList('v-toggle', !status && 'v-toggle-off', disabled && 'v-disabled', className)}
    onClick={(e) => {
      e.stopPropagation();
      !disabled && onClick && onClick();
    }}
    {...rest}
  >
    <span className="v-toggle-circle" />
  </span>
);

export { Toggle };
