import React from 'react';
import { connect } from 'react-redux';
import { ApiKeyResource } from '../resources';
import { store } from '../store';
import { Toggle } from './Toggle';

const labelKey = {
  prod: 'View test data',
  test: 'Viewing test data',
  beta: window.DASHBOARD_ENVIRONMENT === 'BETA' ? 'Switch to localhost' : 'BETA Server',
  dev:  'Localhost',
};

const toggleServer = () => {
  const { server, session } = store.getState();
  const serverName          = Object.keys(server.serverOptions).find((name) => name !== server.name);
  if (!session) return store.dispatch({ type: 'SET_SERVER', payload: serverName });
  ApiKeyResource.switchServers(serverName); // .then(window.location.reload)
};

const serverSelect = ({ server }) => {
  return (
    <div
      className="v-sidebar-label-title v-pointer v-sidebar-items-label"
      onClick={toggleServer}
      style={{ justifyContent: 'initial' }}
    >
      <Toggle disabled={false} status={server.isTestMode} onClick={toggleServer} />
      <div className="v-margin-left-1rem">{labelKey[server.name]}</div>
    </div>
  );
};

export default connect(({ server }) => ({ server }))(serverSelect);
