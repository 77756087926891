import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import { classList } from '../../helpers/classList';
import { Textbox } from '../../components/Textbox';
import { Card } from '../../components/Card';
import { Hr } from '../../components/Hr';
import { Grid, Grid1, Grid2 } from '../../components/Grids';
import { Button } from '../../components/Button';
import { formatCurrency } from '../../helpers/data';
import { closeModal } from '../../helpers/modal';
import { AdjustmentsResource } from '../../resources';

class DisputeModal extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  change = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  update() {
    const { expected, details }           = this.state;
    const { adjustment_id: adjustmentId } = this.props;
    const dispute                         = {
      id:          adjustmentId,
      detail:      details,
      expectation: expected,
    };
    this.setState({ busy: true });
    const onError = (m) => this.setState({ busy: false, error: m });
    AdjustmentsResource.update(dispute, null, {
      onError,
      onSuccess: closeModal,
    });
  }

  render() {
    const currencyMask = createNumberMask({
      prefix:       '$',
      allowDecimal: true,
    });

    const { busy, error, expected, details } = this.state;
    const {
      adjustment_quoted: quotedAmount,
      adjustment_actual: actualAmount,
      // adjustment_amount: adjustmentAmount,
      captured: {
        service: capturedService,
        parcel: {
          length: capturedLength,
          width: capturedWidth,
          height: capturedHeight,
          weight: capturedWeight,
          predefined_package: capturedPackage,
        },
      },
      claimed: {
        service: claimedService,
        parcel: {
          length: claimedLength,
          width: claimedWidth,
          height: claimedHeight,
          weight: claimedWeight,
          predefined_package: claimedPackage,
        },
      },
    } = this.props;

    return (
      <div className={classList('v-modal-form', 'v-center-column v-center-left', 'v-dispute-modal')}>
        <Grid>
          <Grid2>
            <Grid1>
              <Card className="v-dispute-card v-margin-right-1rem">
                <h2>Claimed</h2>
                <Hr />
                <p>
                  Service:
                  <strong className="v-dispute-value">{claimedService}</strong>
                </p>
                <p>
                  Package:
                  <strong className="v-dispute-value">{claimedPackage}</strong>
                </p>
                <p>
                  Dimensions (L x W x H):
                  <strong className="v-dispute-value">
                    {claimedLength} x {claimedWidth} x {claimedHeight} in
                  </strong>
                </p>
                <p>
                  Weight:
                  <strong className="v-dispute-value">{claimedWeight} oz</strong>
                </p>
                <Hr />
                <p>
                  Amount:
                  <strong className="v-dispute-value">{formatCurrency(quotedAmount)}</strong>
                </p>
              </Card>
            </Grid1>
            <Grid1>
              <Card className="v-dispute-card v-margin-left-1rem">
                <h2>Captured</h2>
                <Hr />
                <p>
                  Service:
                  <strong className="v-dispute-value">{capturedService}</strong>
                </p>
                <p>
                  Package:
                  <strong className="v-dispute-value">{capturedPackage}</strong>
                </p>
                <p>
                  Dimensions (L x W x H):
                  <strong className="v-dispute-value">
                    {capturedLength} x {capturedWidth} x {capturedHeight} in
                  </strong>
                </p>
                <p>
                  Weight:
                  <strong className="v-dispute-value">{capturedWeight} oz</strong>
                </p>
                <Hr />
                <p>
                  Amount:
                  <strong className="v-dispute-value">{formatCurrency(actualAmount)}</strong>
                </p>
              </Card>
            </Grid1>
          </Grid2>
        </Grid>

        <Card className="v-dispute-form">
          <h2>Dispute</h2>

          <div className="v-input v-dispute-expected">
            <div className="v-input-row v-center">
              <MaskedInput
                mask={currencyMask}
                inputMode="numeric"
                className="v-text"
                placeholder="Expected Charge"
                name="expected"
                value={expected}
                onChange={this.change}
              />
            </div>
          </div>
          <div className="v-input v-dispute-details">
            <div className="v-input-row v-center">
              <Textbox name="details" placeholder="Reason" rows="5" value={details} onChange={this.change} />
            </div>
          </div>
          <div className="v-center">
            <Button label="Submit" disabled={busy} onClick={this.update.bind(this)} />
          </div>
          {error && (
            <div className="v-error-message v-padding-top-2 v-padding-bottom-1 v-text-red v-center">{error}</div>
          )}
        </Card>
      </div>
    );
  }
}

export default DisputeModal;
