import React from 'react';
import { classList } from '../../helpers/classList';
import { Textbox } from '../../components/Textbox';
import { Button } from '../../components/Button';
import { closeModal } from '../../helpers/modal';
import { PaymentMethodResource } from '../../resources';

export class BankAccountModal extends React.Component {
  state = { routing_number: '' };

  change({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  createBankAccount() {
    closeModal();
    const params = {
      ...this.state,
      type: this.props.external ? 'PayoutBankAccount' : 'BankAccount',
    };
    PaymentMethodResource.create({ payment_method: params }, 'Adding bank account...');
  }

  render() {
    const { account_number, routing_number } = this.state;
    return (
      <div className={classList('v-modal-form v-width-50', 'v-center-column v-center-left')}>
        <Textbox
          labelLeft="Account Number"
          styleLabelLeft={{ width: '10rem' }}
          name="account_number"
          className="v-select-small-no-border"
          placeholder="Account Number"
          value={account_number}
          onChange={this.change.bind(this)}
        />
        <br />
        <Textbox
          labelLeft="Routing Number"
          styleLabelLeft={{ width: '10rem' }}
          name="routing_number"
          className="v-select-small-no-border"
          placeholder="Routing Number"
          value={routing_number}
          onChange={this.change.bind(this)}
        />
        <div className="v-hr v-hr-2" />
        <div className="v-center-right">
          <Button label="Close" onClick={closeModal} />
          <Button
            disabled={!account_number || !routing_number || routing_number.length !== 9}
            label="Add Bank Account"
            className="v-margin-left-2rem"
            onClick={this.createBankAccount.bind(this)}
          />
        </div>
      </div>
    );
  }
}
