import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../helpers/classList';
import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { getTableConfig, iconKey } from './config';
import { showModal, showTextMessage } from '../../helpers/modal';
import { Grid } from '../../components/Grids';
import { NewShopConnectionModal } from './NewShopConnectionModal';
import { ShopConnectionsResource } from '../../resources';
import { Title } from '../../components/Title';

class ShopConnections extends React.Component {
  state = {};

  newShopConnection() {
    showModal({
      title:     `Create New Shop Connection`,
      component: NewShopConnectionModal,
    });
  }

  editShopConnection(shopConnection) {
    showModal({
      title:     `Edit Shop Connection`,
      component: NewShopConnectionModal,
      payload:   { shopConnection },
    });
  }

  deleteShopConnection(id) {
    if (!window.confirm("Are you sure you want to disconnect the shop? The related orders and shipments will be left in place although you won't be able to associate them with another connection.")) return;
    ShopConnectionsResource.delete(id, 'Deleting shop connection...');
  }

  resync(id) {
    ShopConnectionsResource.post(`${id}/resync`).then(({ message }) => showTextMessage(message));
  }

  render() {
    const { shopConnections } = this.props;
    return (
      <div className="v-page v-shop-connections">
        <Title title="Shop Connections" />

        <div className="v-padding-bottom-2">
          <Button onClick={this.newShopConnection.bind(this)}>Create new Shop Connection</Button>
        </div>
        <Table noScroll data={shopConnections} config={getTableConfig.call(this)} tableClass="v-table-payment-log" />
      </div>
    );
  }
}

export default connect(({ shopConnections }) => {
  return { shopConnections };
})(ShopConnections);
