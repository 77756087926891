import React from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';
import { classList } from '../../helpers/classList';
import { getNumber } from '../../helpers/data';
import { showTextMessage, showErrorMessage } from '../../helpers/modal';
import { BalanceResource, InvoicesResource } from '../../resources';

class PayInvoiceModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethod: props.topup.payment_method.value,
      amount:        props.amountDue,
    };
  }

  change = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  update = () => {
    const { paymentMethod, amount } = this.state;
    const { id }                    = this.props;

    const data = {
      amount:       getNumber(amount),
      topup_method: paymentMethod,
      invoice_id:   id,
    };

    BalanceResource.update(data, 'Paying Invoice...', {
      onSuccess: () => {
        InvoicesResource.list(false);

        showTextMessage('Invoice paid successfully!');
      },
      onError: (r) => {
        showErrorMessage(r.message);
      },
    }).then((r) => {
      if (!r.success) {
        showErrorMessage(r.message);
      }
    });
  };

  render() {
    const { amount, paymentMethod } = this.state;
    const { topup, due }            = this.props;

    const currencyMask = createNumberMask({
      prefix:       '$',
      allowDecimal: true,
    });

    return (
      <div className={classList('v-modal-form', 'v-center-column v-center-left', 'v-pay-invoice-modal')}>
        <Select
          labelLeft="Payment:"
          value={paymentMethod}
          styleLabelLeft={{ width: '7rem' }}
          name="paymentMethod"
          className="v-select-small-no-border v-width-25"
          classOpenOptions="v-fixed v-width-25"
          items={topup.payment_method.options.map((m) => ({
            label: m[0],
            value: m[1],
          }))}
          onChange={this.change}
        />
        <br />
        <div className="v-input">
          <div className="v-input-row v-center v-select-inline">
            <label className="v-text-label-left" style={{ width: '7rem' }}>
              Amount:
            </label>
            <MaskedInput
              mask={currencyMask}
              inputMode="numeric"
              className="v-text"
              placeholder="$0.00"
              name="amount"
              value={amount || due}
              onChange={this.change}
            />
          </div>
        </div>
        <div className="v-center-right">
          <Button disabled={!topup.payment_method && !topup.payment_method} label="Pay" onClick={this.update} />
        </div>
      </div>
    );
  }
}

export default connect(({ userSettings, balance }) => ({
  userSettings,
  topup: balance.topup,
}))(PayInvoiceModalComponent);
