const initialState = {
  transactions: [],
  total_count:  0,
  options:      { kinds: [] },
};

export const transactions = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BALANCE_TRANSACTIONS':
      return { ...action.payload };
    default:
      return state;
  }
};
