import { resource } from './resource';

const route      = '/user/password';
const storageKey = '';

function runAndReload(apiCall) {
  return apiCall;
}

export const PasswordResource = resource({ route, storageKey, runAndReload });
