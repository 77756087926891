const initialState = {
  shipments:     [],
  has_printer:   false,
  no_warehouses: false,
  options:       { downloaded: [] },
  presets:       [],
};

export const shipments = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHIPMENTS':
      return action.payload;
    default:
      return state;
  }
};
