import { writeStorageData, readStorageData } from '../helpers/localStorage';

const subdomain = window.location.hostname.split('.')[0];
let servers,
 defaultServer;

switch (subdomain) {
  case 'dashboard':
    servers                      = {
      prod: 'https://vanlo.com',
      test: 'https://test.vanlo.com',
    };
    defaultServer                = 'prod';
    window.DASHBOARD_ENVIRONMENT = 'PROD';
    break;
  case 'beta-dashboard':
    servers                      = {
      beta: 'https://beta.vanlo.com',
      dev:  process.env.REACT_APP_BACKEND_HOST,
    };
    defaultServer                = 'beta';
    window.DASHBOARD_ENVIRONMENT = 'BETA';
    break;
  default:
    servers                      = {
      dev:  process.env.REACT_APP_BACKEND_HOST,
      beta: 'https://beta.vanlo.com',
    };
    defaultServer                = 'dev';
    window.DASHBOARD_ENVIRONMENT = 'DEV';
}

const serverName   = readStorageData('VANLO_SERVER') || defaultServer;
const initialState = {
  isTestMode:    serverName !== defaultServer,
  name:          serverName,
  url:           servers[serverName],
  serverOptions: servers,
};

export const server = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SERVER': {
      if (state.name !== action.payload) {
        writeStorageData('VANLO_SERVER', action.payload);
      }
      return {
        isTestMode:    action.payload !== defaultServer,
        name:          action.payload,
        url:           servers[action.payload],
        serverOptions: servers,
      };
    }
    default:
      return state;
  }
};
