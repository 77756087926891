import React from 'react';
import { classList } from '../../helpers/classList';
import { WarehouseContainer } from './WarehouseContainer';
import { Title } from '../../components/Title';

export default () => (
  <div className={classList('v-awaiting-shipments', 'v-page')}>
    <Title title="Locations" subtitle="Add your warehouse locations." />
    <WarehouseContainer />
  </div>
);
