import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
// import { DropdownList } from 'react-widgets';

import { ChromePicker } from 'react-color';
import { useStateManagement } from '../../helpers/StateManagement';
import { Button } from '../../components/Button';
import { Grid, Grid1, Grid2 } from '../../components/Grids';
import { Title } from '../../components/Title';
import { Card } from '../../components/Card';
import { Textbox } from '../../components/Textbox';
import { Checkbox } from '../../components/Checkbox';
import { ThinDropdownList } from '../ShopOrders';
import { PackingSlipDesignResource } from '../../resources';

class PackingSlipEditor extends React.Component {
  propsWillUpdate = ['packingSlipDesign']

  resource = PackingSlipDesignResource

  constructor(props) {
    super(props);
    useStateManagement(this);

    this.fileInput = React.createRef();
  }

  freshState = () => ({
    design: this.props.packingSlipDesign || { prefs: {} },
    layout: this.state?.layout || '8.5X11'
  });

  clickFileInput = () => {
    this.fileInput.current.click();
  };

  saveLogo = (event) => {
    const logo     = event.target.files[0];
    const formData = new FormData();
    // this.updateStateElement('design.logo', logo);
    formData.append('packing_slip_design[logo]', logo);
    this.resource.create(formData, 'Saving...');
  }

  savePrefs = () => {
    this.resource.create({ prefs: this.state.design.prefs }, 'Saving...');
  };

  render() {
    const { user }           = this.props;
    const { design, layout } = this.state;

    if (!design) return <></>;

    return (
      <div className="v-page">
        <Title title="Packing Slip" />

        <Grid>
          <Grid1 className="v-grid-column v-no-grid">
            <Card>
              <div className="v-input-row">
                <label className="v-text-label">Logo</label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <input type="file" ref={this.fileInput} style={{ display: 'none' }} onChange={this.saveLogo} />
                  {design.logo_url && (
                    <img alt="Uploaded" style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '2rem' }} src={design.logo_url} />
                  )}
                </div>
                <div>
                  <Button onClick={this.clickFileInput} small white style={{ width: '100%', marginTop: '2rem' }}>
                    Choose Image
                  </Button>
                </div>
              </div>
              <div className="v-input-row v-margin-bottom-2 v-margin-top-2">
                <Textbox
                  name="design.prefs.header"
                  value={design.prefs.header}
                  onChange={this.onInputChange}
                  label="Header Text"
                  rows={5}
                />
              </div>
              <div className="v-input-row v-margin-bottom-2">
                <Textbox
                  name="design.prefs.footer"
                  value={design.prefs.footer}
                  onChange={this.onInputChange}
                  label="Footer Text"
                  rows={5}
                />
              </div>
              <div className="v-center v-margin-right-3rem">
                <Button onClick={this.savePrefs}>Save</Button>
              </div>
            </Card>
          </Grid1>

          <Grid2 className="v-margin-left-2rem v-grid-column">
            <Card>
              Preview for layout: &nbsp;
              <ThinDropdownList
                data={['4X6', '8.5X11']}
                value={layout}
                onChange={(v) => this.setState({ layout: v })}
              />
              <main
                className={`${layout === '4X6' ? 'LW' : 'letter'} label-with-slip`}
                dangerouslySetInnerHTML={{ __html: design.preview }}
              />
            </Card>
          </Grid2>
        </Grid>
      </div>
    );
  }
}

export default connect(({ user, packingSlipDesign }) => ({ user, packingSlipDesign }))(PackingSlipEditor);
