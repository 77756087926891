import React from 'react';
import { connect } from 'react-redux';
import AnimatedNumber from 'animated-number-react';

import { showModal } from '../../helpers/modal';
import { formatCurrency } from '../../helpers/data';

import { Title } from '../../components/Title';
import { Table } from '../../components/Table';
import { Select } from '../../components/Select';
import { Grid, Grid1 } from '../../components/Grids';
import { Card } from '../../components/Card';
import { Pagination } from '../../components/Pagination';
import { TableFiltersComponent } from '../../components/TableFiltersComponent';
import { UserResource, InvoicesResource } from '../../resources';
import tableConfig from './tableConfig';
import PayInvoiceModal from './PayInvoiceModal';

class Invoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...InvoicesResource.filters };
  }

  showPayInvoiceModal = (id, amountDue) =>
    showModal({
      title:     'Pay Invoice',
      component: PayInvoiceModal,
      payload:   { id, amountDue },
    });

  trClass(invoice) {
    if (invoice.amount_due <= 0) return 'paid';
    if (new Date(invoice.due) < new Date()) return 'overdue';
  }

  setPage(page) {
    if (page !== this.state.page) {
      this.setState({ page });
      InvoicesResource.setFilter('page', page);
      InvoicesResource.list();
    }
  }

  setAutoPayAccount = (event) => {
    const {
      target: { value: id },
    } = event;

    if (!id) {
      return this.unsetAutoPayAccount();
    }

    if (window.confirm('Are you sure you want to use this account for Invoice Auto-Pay?')) {
      UserResource.settings({ settings: { invoice_autopay_method_id: id } });
    }
  };

  unsetAutoPayAccount = () => {
    if (window.confirm('Are you sure you want to disable Invoice Auto-Pay?')) {
      UserResource.settings({ settings: { invoice_autopay_method_id: null } });
    }
  };

  render() {
    const {
      invoices: { invoices, total_count, totals, filters },
      paymentMethods: { bank_accounts },
      userSettings: { invoice_autopay_method_id },
    } = this.props;

    const { page, per } = this.state;

    return (
      <div className="v-page">
        <Title title="Invoices" />
        {/* <Grid>
          <Grid1 className="v-margin-right-3rem">
            <Card className="v-invoices-total">
              <div className="v-invoices-total-lines" />
              <span className="v-invoices-total-label">Total Outstanding</span>
              <div className="v-invoices-total-amount">
                <AnimatedNumber
                  duration="500"
                  value={totals.outstanding}
                  formatValue={formatCurrency}
                />
              </div>
            </Card>
          </Grid1>
          <Grid1 className="v-margin-right-3rem">
            <Card className="v-invoices-total v-invoices-total-paid">
              <div className="v-invoices-total-lines" />
              <span className="v-invoices-total-label">Total Paid</span>
              <div className="v-invoices-total-amount">
                <AnimatedNumber
                  duration="500"
                  value={totals.paid}
                  formatValue={formatCurrency}
                />
              </div>
            </Card>
          </Grid1>
          <Grid1 className="v-margin-right-3rem">
            <Card className="v-invoices-total v-invoices-total-overdue">
              <div className="v-invoices-total-lines" />
              <span className="v-invoices-total-label">Total Overdue</span>
              <div className="v-invoices-total-amount">
                <AnimatedNumber
                  duration="500"
                  value={totals.overdue}
                  formatValue={formatCurrency}
                />
              </div>
            </Card>
          </Grid1>
        </Grid> */}

        {bank_accounts.length > 0 && (
          <Grid className="v-margin-bottom-3 v-margin-top-1">
            <Grid1>
              <Select
                items={bank_accounts.map((paymentMethod) => ({
                  label: `${paymentMethod.issuer} *${paymentMethod.last4}`,
                  value: paymentMethod.id,
                }))}
                label="Auto-Pay When Due"
                firstOption="Select Payment Method"
                nullOption="Disable Auto-Pay"
                className="v-width-30"
                onChange={this.setAutoPayAccount}
                value={invoice_autopay_method_id.value}
              />
            </Grid1>
            <Grid1 />
          </Grid>
        )}

        <TableFiltersComponent
          display="types per"
          resource={InvoicesResource}
          type="kind"
          onChange={(p) => this.setState({ ...p })}
          filterTypesList={filters.kind}
        />
        <Table
          noScroll
          data={invoices}
          context={this}
          config={tableConfig.call(this)}
          tableClass="v-table-invoices"
          trClass={this.trClass}
        />
        <Pagination total_count={total_count} currentPage={page} perPage={per} onChange={this.setPage.bind(this)} />
      </div>
    );
  }
}

export default connect(({ user, invoices, balance, paymentMethods, userSettings }) => ({
  user,
  invoices,
  balance,
  paymentMethods,
  userSettings,
}))(Invoices);
