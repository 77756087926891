const initialState = {
  endpoints:   [],
  events:      [],
  total_count: 0,
  options:     { apis: [], names: {}, statuses: [] },
};

export const webhooks = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_WEBHOOKS':
      return action.payload;
    default:
      return state;
  }
};
