import { getWithAddressCalculated } from '../helpers/data';

export const warehouseAddresses = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case 'SET_WAREHOUSE_ADDRESSES':
      return payload.warehouse_addresses.map(getWithAddressCalculated);
    default:
      return state;
  }
};
