import React from 'react';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { CheckoutForm } from './CheckoutForm';

const StripeComponent = ({ stripe }) => {
  return (
    <StripeProvider apiKey={stripe}>
      <Elements>
        <CheckoutForm />
      </Elements>
    </StripeProvider>
  );
};

export const Stripe = connect(({ user }) => ({ stripe: user.keys.stripe.pk }))(StripeComponent);
