import React from 'react';
import { ShipmentResource } from '../../resources';
import { closeModal, showErrorMessage } from '../../helpers/modal';

import { Card } from '../../components/Card';

import { Grid1, Grid2 } from '../../components/Grids';
import { Select } from '../../components/Select';
import { Button } from '../../components/Button';

class RateSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  buy = () => {
    const { rateId } = this.state;

    const { shipment, page } = this.props;

    ShipmentResource.buy(shipment.id, rateId).then((response) => {
      if (response.tracking_code) {
        page.update(response);
        closeModal();
      } else {
        showErrorMessage(response.message);
      }
    });
  };

  setRateId = (event) => {
    const {
      target: { value: rateId },
    } = event;

    this.setState({ rateId });
  };

  render() {
    const {
      shipment: { rates },
      page,
    } = this.props;
    const { rateId } = this.state;

    return (
      <div>
        <Card className="v-padding-2 v-width-60 v-margin-bottom-2">
          <div className="v-headline-label-text v-margin-bottom-2">Label</div>

          <Grid2>
            <Grid1>
              <Select
                label="Select Rate"
                value={rateId}
                name="service"
                className="v-select-small-no-border v-full-width v-margin-bottom-2"
                items={rates.map((m) => ({
                  label: `${m.carrier} ${m.service} - $${m.rate}`,
                  value: m.id,
                }))}
                onChange={this.setRateId}
              />
            </Grid1>
          </Grid2>
        </Card>
        <Button onClick={this.buy} disabled={!rateId}>
          Buy
        </Button>
        <Button className="v-padding-left-1 v-button-white" onClick={page.edit}>
          Edit
        </Button>
      </div>
    );
  }
}

export default RateSelect;
