import React from 'react';
import { Link } from 'react-router-dom';
import { classList } from '../helpers/classList';
import { IconArrow, IconAddNew } from '../helpers/icons';
import { registerSelect, deregisterSelect } from '../helpers/escape';
import { Button } from './Button';

export class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    registerSelect(this);
  }

  componentWillUnmount() {
    deregisterSelect(this);
  }

  close() {
    this.setState({ open: false });
  }

  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { open } = this.state;
    const {
      onAllowChange,
      classOpenOptions,
      label,
      labelLeft,
      styleLabelLeft,
      value,
      disabled,
      items,
      name,
      currentOpenList,
      toggleOpenClose,
      onChange,
      dispatch,
      className,
      allowAddPaymentOption,
      firstOption,
      nullOption,
      input,
      style,
      inline,
    } = this.props;

    const selectName                                       = name || input?.name;
    const selection                                        = input?.value || value;
    const { value: selectedValue, label: selectedCaption } = (items &&
      items.find((x) => `${x.value}` === `${selection}`)) || {
      value: null,
      label: firstOption || 'Select...',
    };

    const selectOption = (payload, label) => {
      const { isAllowed } = onAllowChange ? onAllowChange(payload) : { isAllowed: true };
      if (isAllowed && onChange) onChange({ target: { name: selectName, value: payload } });
      this.toggleOpen();
    };
    const list =
      !disabled &&
      items &&
      (nullOption ? [{ value: null, label: nullOption }, ...items] : items).map((item, key) => {
        const isSelected = item && selectedValue === item.value;
        const localProps = {
          key,
          selectOption,
          isSelected,
          ...item,
        };
        return <Option {...localProps} />;
      });
    return (
      <div
        className={classList(
          'v-select-wrapper',
          disabled && 'v-select-disabled',
          labelLeft || inline ? 'v-select-inline' : 'v-full-width'
        )}
      >
        {labelLeft && (
          <label className="v-text-label-left" style={styleLabelLeft}>
            {labelLeft}
          </label>
        )}
        <div
          onMouseUp={(e) => e.stopPropagation()}
          className={classList('v-select', className, labelLeft && 'v-select-inline', open && 'v-open')}
          style={style}
        >
          {label && <label className="v-text-label">{label}</label>}
          <div className={classList('v-select-caption', 'v-center')} onClick={this.toggleOpen.bind(this)}>
            <span className="v-center-left">{selectedCaption}</span>
            <IconArrow className={classList('v-arrow-icon', 'v-icon-10')} />
          </div>
          <div className="v-select-list-container">
            <div className={classList(classOpenOptions, 'v-select-list')}>
              {list}
              {allowAddPaymentOption && (
                <div>
                  <Link to="/payment-methods">
                    <Button
                      className="v-add-payment-method"
                      white
                      fullwidth
                      label="Add Payment Method"
                      icon={IconAddNew}
                    />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Option = ({ label, value, isSelected, selectOption }) => (
  <div className={classList('v-select-option', isSelected && 'v-selected')} onClick={() => selectOption(value, label)}>
    {label}
  </div>
);
