import { resource } from './resource';
import { getFormattedDate, getPastDays } from '../components/Calendar';

const route      = '/reports';
const storageKey = 'SET_REPORTS';
const filters    = {
  q:           null,
  per:         50,
  report_type: 'all',
  page:        1,
  date_from:   getFormattedDate(getPastDays(7)),
  date_to:     getFormattedDate(getPastDays(-1)),
};

export const ReportsResource = resource({
  route,
  storageKey,
  filters,
});
