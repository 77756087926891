const initialState = {
  records: [],
  filters: { carrier: [], service: [] },
};

export const userSummaries = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_USER_SUMMARIES':
      return { ...state, ...payload };
    default:
      return state;
  }
};
