const initialState = {
  scan_forms: [],
  filters:    {},
};

export const scanForms = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case 'SET_SCAN_FORMS':
      return { ...state, ...payload };
    default:
      return state;
  }
};
